import {call, put, all, takeLatest} from "redux-saga/effects";
import {
    addInternalStreamRoutine,
    deleteInternalStreamRoutine, getInternalStreamsRoutine,
    updateInternalStreamRoutine
} from "../../actions/internal-streams";
import {
    addInternalStreamRequest,
    deleteInternalStreamRequest,
    getInternalStreamsRequest, updateInternalStreamRequest
} from "../../../services/internalStreams.service";
import {getScreensListRoutine} from "../../actions/screens";
import {toastr} from "react-redux-toastr";

function* getStreams (action: ReturnType<typeof getInternalStreamsRoutine.trigger>) {
    try {
        const response = yield call(getInternalStreamsRequest);
        yield put(getInternalStreamsRoutine.success(response));
        yield put(getInternalStreamsRoutine.failure());
    } catch(error) {
        yield put(getInternalStreamsRoutine.failure(error.message));
    }
}

function* addStream (action: ReturnType<typeof addInternalStreamRoutine.trigger>) {
    try {
        const response = yield call(addInternalStreamRequest, action.payload);
        if (response) {
            toastr.message("Success!", "Internal stream has been created!")
            yield put(addInternalStreamRoutine.success(response));
        } else {
            yield put(addInternalStreamRoutine.failure());
        }
    } catch(error) {
        yield put(addInternalStreamRoutine.failure(error.message));
    }
}

function* updateStream (action: ReturnType<typeof updateInternalStreamRoutine.trigger>) {
    try {
        const response = yield call(updateInternalStreamRequest, action.payload);
        if (response) {
            toastr.message("Success!", "Internal stream has been updated!")
            yield put(updateInternalStreamRoutine.success(action.payload));
        } else {
            yield put(updateInternalStreamRoutine.failure());
        }
    } catch(error) {
        yield put(updateInternalStreamRoutine.failure(error.message));
    }
}

function* deleteStream (action: ReturnType<typeof deleteInternalStreamRoutine.trigger>) {
    try {
        const response = yield call(deleteInternalStreamRequest, action.payload);
        if (response.status === 200) {
            toastr.message("Success!", "Internal stream has been removed!")
            yield put(deleteInternalStreamRoutine.success(action.payload));
            yield put(getScreensListRoutine.trigger());
        } else {
            yield put(deleteInternalStreamRoutine.failure());
        }
    } catch(error) {
        yield put(deleteInternalStreamRoutine.failure(error.message));
    }
}

export default function* internalStreamsSaga() {
    yield all([
        takeLatest(addInternalStreamRoutine, addStream),
        takeLatest(deleteInternalStreamRoutine, deleteStream),
        takeLatest(updateInternalStreamRoutine, updateStream),
        takeLatest(getInternalStreamsRoutine, getStreams)
    ]);
}
