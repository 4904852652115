import Bowser from 'bowser';
import * as R from 'ramda';
import env from "../env";

export const getBrowserType = () => {
    const info = Bowser.parse(window.navigator.userAgent);
    return `${info.browser.name} ${info.browser.version}`;
}

export const getDeviceType = ():string => {
    const info = Bowser.parse(window.navigator.userAgent);
    var type=info.platform.type;
    if(typeof type ==='undefined') return 'undefined';
    return type;
}

export const getOperatingSystem = () => {
    const info = Bowser.parse(window.navigator.userAgent);
    return `${info.os.name} ${info.os.versionName}`;
}
export const replaceById = (replace, index) => collection => collection.map((item, i) => i === index ? replace : item);

export const generateImageLink = (eventCodeId: number) => {
    switch (eventCodeId) {
        case 1070:
        case 2094:
        case 1227:
        case 2056:
            return './rb-icons/red-card-png-3.png';
        case 1068:
        case 2092:
        case 1034:
        case 2058:
            return './rb-icons/yellow-card-png-4.png';
        case 1025:
        case 2049:
            return './rb-icons/corner.png';
        case 1065:
        case 2089:
        case 1029:
        case 2053:
            return './rb-icons/goal.png';
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 20:
            return './rb-icons/kickoff_finalwhistle.png';
        case 1043:
        case 2067:
            return './rb-icons/offside.png';
        case 1031:
        case 2055:
            return './rb-icons/penalty.png';
    }
}

export const firstOrDefault = (property: string, value: any, collection: any[] | null) => {
    return R.find(R.propEq(property, value), collection);
}

export const getTimeDiffInMilliseconds = (date1, date2) => {
    return new Date(date1).getTime() - new Date(date2).getTime();
}

export const secureOrigin = () => { //Check redirect domain name to prevent open redirect attacks
    const initPath = new URLSearchParams(window.location.search).get("redirectUrl");

    if (initPath && initPath.includes("http")) {
        const initPathUrl = new URL(initPath);
        return initPathUrl.origin === env.cmsUrl;
    } else {
        return true;
    }
}

