import {all, call, put, takeLatest} from "redux-saga/effects";
import {confirmAnnouncementRequest} from "../../../services/announcements.service";
import {confirmAnnouncementRoutine} from "../../actions/announcements/actions";

function* confirmAnnouncement(action: any) {
    try {
        const response = yield call(confirmAnnouncementRequest, action.payload.id);
        if(response && response.status === 200) {
            yield put(confirmAnnouncementRoutine.success(action.payload));
        } else {
            yield put(confirmAnnouncementRoutine.failure());        }
    } catch (err) {
        yield put(confirmAnnouncementRoutine.failure(err));
    }
}

export default function* announcementsSaga() {
    yield all([
        takeLatest(confirmAnnouncementRoutine, confirmAnnouncement),
    ]);
}