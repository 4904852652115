import { IFiltersForm } from "../schedule/header/filters/interfaces/filters-form.interface";

export const defaultFilters: IFiltersForm = {
    sports: [] as string[],
    countries: []  as string[],
    services: [] as string[],
    onlyNew: false,
    showLive: false,
    showVOD: false,
    isApplied: false
};
