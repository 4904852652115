import React from 'react';
import { history } from './history';
import { Provider } from 'react-redux';
import AppRouter from './router';
import { Router } from 'react-router-dom';

class App extends React.Component<any, any> {
    render() {
        return (
            <Provider store={this.props.store}>
                <Router history={history}>
                    <AppRouter />
                </Router>
            </Provider>
        );
    }
}

export default App;
