import { all, takeLatest, put, call } from "redux-saga/effects";
import { loadEventsByFiltersRoutine, saveFiltersRoutine } from "../../actions/events/filtered-events";
import { ScheduleFilters } from "../../../schedule/models/schedule-filters";
import SportEvent from "../../../schedule/models/sport-event";
import {getEvents, saveFiltersRequest} from "../../../services/api.service";
import { EventsWithPaging } from "../../../common/types/state.type";
import { applyFilters } from "../../actions/filters/routines";
import { Restriction } from "../../../common/types/restriction.type";

function* getEventsByFilter(action: { payload: { filters: ScheduleFilters, restriction: Restriction }}) {
    try {
        const { filters } = action.payload;
        let events: SportEvent[] = yield call(getEvents, filters,  new Date().getTimezoneOffset());
        const res: EventsWithPaging = {source: events};
        yield put(loadEventsByFiltersRoutine.success(res)); 
        yield put(checkIfFiltersApplied(filters) ? applyFilters.success() : applyFilters.failure())
    } catch (err) {
        console.log(err.message);
    }
}

function* saveFilters(action) {
    try {
        const { filters } = action.payload;
        const res = yield call(saveFiltersRequest, filters);
        yield put(saveFiltersRoutine.success(res));
    } catch (err) {
        console.log(err.message);
    }
}

const checkIfFiltersApplied = (filters: ScheduleFilters): boolean => {
    return !!filters.searchWord.length
    || !!filters.servicesCriteria.length || !!filters.sportsCriteria.length
    || !!filters.countriesCriteria.length || filters.onlyNew || filters.showVOD || filters.showLive
}

export default function* filteredEventsSaga() {
    yield all([
        takeLatest(loadEventsByFiltersRoutine, getEventsByFilter),
        takeLatest(saveFiltersRoutine, saveFilters)
    ]);
}
