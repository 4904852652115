import React from "react";

import "./index.scss";

const PaywallPopup = ({ paywallPopupVisibility, popupPositionClass, popupRef }) => {

    return (
        <div className={`paywall-popup ${paywallPopupVisibility} ${popupPositionClass}`}
             ref={popupRef}>
            <div className="paywall-popup__main">
                Need access to this content?<br/>please contact<br/>
                <a href="mailto:sales@igamemedia.com">sales@igamemedia.com</a>
            </div>
            <div className={`paywall-popup__pointer ${popupPositionClass}`}></div>
        </div>
    )
}

export default PaywallPopup;