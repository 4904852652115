import React from 'react';
import './index.scss';
import chevron from '../../assets/skybox-icons/chevron.png';

interface IMarkerProps {
    activeMarker: 'start' | 'end' | null;
    marker: 'start' | 'end';
    movingMarkerForward: boolean;
    onMouseDown: (e: React.MouseEvent) => void;
    time: string;
    onLeftArrowClick: (marker: string) => void;
    onRightArrowClick: (marker: string) => void;
}

const Marker: React.FunctionComponent<IMarkerProps> = ({
    activeMarker,
    marker,
    movingMarkerForward,
    onMouseDown,
    time,
    onLeftArrowClick,
    onRightArrowClick
}) => {
    const isActive = activeMarker === marker;

    return (
        <div className={ `selection-window__marker ${marker} ${ isActive ?  movingMarkerForward ? "active-left" : "active-right" : ""}` }
             onMouseDown={onMouseDown}
        >
        <span className="selection-window__time-mark"
              style={isActive ? { transform: "scale(1.2)"} : {} }
        >{time}
        </span>
            <div className="arrow-block">
                <img src={chevron} alt="left" onClick={() => onLeftArrowClick(marker)}/>
                <img src={chevron} alt="right" onClick={() => onRightArrowClick(marker)}/>
            </div>
        </div>
    );
};

export default Marker;
