import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { RunningBallAction } from '../../../common/types/running-ball-action.type';
import SportEvent from '../../../schedule/models/sport-event';
import { eventCodes, tickerStateCodes, times } from '../../../utils/constants';
import { generateImageLink, getTimeDiffInMilliseconds } from '../../../utils/helpers';
import { State } from '../../../common/types/state.type';
import { connect } from 'react-redux';
import { addRunningBallActions } from '../../../redux/actions/logs/actions';

export interface ProgressBarProps {
    event: SportEvent;
    actions?: RunningBallAction[];
    setCurrentTime: (currentTime: number) => void;
    addRunningBallActions: (payload: { field: string, value: number, id: string }) => void;
    videoId: string;
    logs: any;
    videoLength: number;
    isLive: boolean;
}

const EventsProgressBar: React.FC<ProgressBarProps> = ({
    event,
    actions,
    setCurrentTime,
    addRunningBallActions,
    videoId,
    logs,
    videoLength,
    isLive
}) => {
    const [actionsList, setActionsList] = useState([] as any);
    const [startTimeMilliseconds, setStartTime] = useState(0);
    const [startSecondHalfMilliseconds, setStartSecondHalf] = useState(0);
    const [startExtraTimeMilliseconds, setStartExtraTime] = useState(0);
    const [startSecondHalfETMilliseconds, setStartSecondHalfET] = useState(0);
    const [runningBallInteractions, setRunningBallInteractions] = useState({} as any);

    const getActionWithDefaultValue = (eventCodes, defaultCurrentPlaytime, defaultMinute) => {
        let result = actionsList.find((item) => eventCodes.includes(item.eventCodeId));

        if (!result) {
            result = {
                currentPlaytime: defaultCurrentPlaytime,
                minute: defaultMinute
            };
        }

        return result;
    }

    const getAction = (eventCodes: number[], eventActions: RunningBallAction[]) => {
        return eventActions!.find((item) => eventCodes.includes(item.eventCodeId)) as RunningBallAction
    }

    const stopFirstHalf = getActionWithDefaultValue(eventCodes.STOP_FIRST_HALF, times._45min, 45);
    const endSecondHalf = getActionWithDefaultValue(eventCodes.END_SECOND_HALF, times._90min, 91);
    const startSecondHalf = getActionWithDefaultValue(eventCodes.START_SECOND_HALF, times._45min, 45);
    const startFirstHalfETEvent = getAction(eventCodes.START_ET, actionsList);
    const stopFirstHalfETEvent = getAction(eventCodes.STOP_FIRST_HALF_ET, actionsList);
    const startSecondHalfETEvent = getAction(eventCodes.START_SECOND_HALF_ET, actionsList);
    const stopGameEvent = getAction(eventCodes.END_GAME, actionsList);

    useEffect(() => {

        if (!actions || !actions.length) {
            return;
        }

        setActionsList(actions!.filter(x => x.gameId === event.rbGameId));
        calculateEventAndVideoTimeDiff(actions!.filter(x => x.gameId === event.rbGameId));
    }, [actions])

    useEffect(() => {
        if (logs.length) {
            return;
        }

        const index = logs.findIndex(x => x.id === videoId);

        if (logs[index]) {
            setRunningBallInteractions(logs[index].interactions.runningBallInteractions);
        }

    }, [logs])

    const getRandomHeight = (code: number) => {
        return code % 2 === 0 ? 15 : 75;
    }

    const getLatencyForFirstHalfMilliseconds = () => {
        return (videoLength * 1000 - times.vodLength)
    }

    const setEventStartTime = (startEvent) => {
        const startTimeMilliseconds = getTimeDiffInMilliseconds(startEvent.date, event.startTime);
        setStartTime(startTimeMilliseconds);
    }

    const setEventStartExtraTime = (endSecondHalfEvent) => {
        const firstHalfETDiff = getTimeDiffInMilliseconds(startFirstHalfETEvent.date, endSecondHalfEvent.date);
        const currentTimeDiff = endSecondHalfEvent.currentPlaytime - startFirstHalfETEvent.currentPlaytime;
        setStartExtraTime(firstHalfETDiff + currentTimeDiff);
    }

    const setEventStartSecondHalfExtraTime = () => {
        const secondHalfETDiff = getTimeDiffInMilliseconds(startSecondHalfETEvent.date, stopFirstHalfETEvent.date);
        const currentTimeDiff = stopFirstHalfETEvent.currentPlaytime - startSecondHalfETEvent.currentPlaytime;
        setStartSecondHalfET(secondHalfETDiff + currentTimeDiff);
    }

    const setEventStartSecondHalf = (stopFirstHalfEvent, secondHalfEvent) => {
        const secondHalfMilliseconds = getTimeDiffInMilliseconds(secondHalfEvent.date, stopFirstHalfEvent.date);
        const currentTimeDiff = stopFirstHalfEvent.currentPlaytime - secondHalfEvent.currentPlaytime;
        setStartSecondHalf(secondHalfMilliseconds + currentTimeDiff);
    }

    const calculateEventAndVideoTimeDiff = (eventActions: RunningBallAction[]) => {

        if (!actions || !actions.length) {
            return;
        }

        const startEvent = getAction(eventCodes.START_GAME, eventActions);
        const stopFirstHalfEvent = getAction(eventCodes.STOP_FIRST_HALF, eventActions);
        const secondHalfEvent = getAction(eventCodes.START_SECOND_HALF, eventActions);
        const endSecondHalfEvent = getAction(eventCodes.END_SECOND_HALF, eventActions);
        const startFirstHalfETEvent = getAction(eventCodes.START_ET, eventActions);
        const stopFirstHalfETEvent = getAction(eventCodes.STOP_FIRST_HALF_ET, eventActions);
        const startSecondHalfETEvent = getAction(eventCodes.START_SECOND_HALF_ET, eventActions);

        if (secondHalfEvent && stopFirstHalfEvent) {
            setEventStartSecondHalf(stopFirstHalfEvent, secondHalfEvent);
        }
        if (startEvent) {
            setEventStartTime(startEvent);
        }
        if (startFirstHalfETEvent && endSecondHalfEvent) {
            setEventStartExtraTime(endSecondHalfEvent);
        }
        if (startSecondHalfETEvent && stopFirstHalfETEvent) {
            setEventStartSecondHalfExtraTime();
        }
    }

    const statisticsInteractionHandler = (el: RunningBallAction) => {
        if (runningBallInteractions[el.eventCode] === null || runningBallInteractions[el.eventCode] === undefined) {
            runningBallInteractions[el.eventCode] = 0;
        }

        setRunningBallInteractions({
            ...runningBallInteractions,
            [el.eventCode]: ++runningBallInteractions[el.eventCode]
        })

        addRunningBallActions({
            field: el.eventCode,
            value: runningBallInteractions[el.eventCode],
            id: videoId
        });
    }

    const itemClickHandler = (el: RunningBallAction) => {
        const actionLatency = isLive ? getActionLatency(el) + times._15min : getActionLatency(el)
        setCurrentTime(actionLatency);
        statisticsInteractionHandler(el);
    }

    const getActionLatency = (el: RunningBallAction) => {
        const basicLatency = getLatencyForFirstHalfMilliseconds();
        let latency = basicLatency + startTimeMilliseconds;

        switch (el.tickerStateId) {
            case tickerStateCodes.firstHalf:
            case tickerStateCodes.pause:
                return el.currentPlaytime + latency
            case tickerStateCodes.secondHalf:
            case tickerStateCodes.regularTimeFinished:
                latency += startSecondHalfMilliseconds;
                return el.currentPlaytime + latency;
            case tickerStateCodes.firstHalfOvertime:
            case tickerStateCodes.pauseOvertime:
                latency += startExtraTimeMilliseconds;
                return el.currentPlaytime += latency;
            case tickerStateCodes.secondHalfOvertime:
            case tickerStateCodes.finished:
                latency += startSecondHalfETMilliseconds;
                return el.currentPlaytime + latency;
            default:
                return el.currentPlaytime + basicLatency;
        }
    }

    const renderTimesLines = (length: number, time: number) => {
        return [...Array.from({ length: length }, (v, k) => k + time)].map((el, i) => {
            return [0, 15, 30, 45, 60, 75, 90, 105, 120].includes(el)
                ? (
                    <div key={el} style={{ position: 'relative', }}>
                        <p className={`time${el}`}>{el}</p>
                        <div className="line long" />
                    </div>
                )
                : (
                    <div key={el} className="line" />
                )
        })
    }

    const renderFirstHalf = () => {
        return stopFirstHalf &&
            renderTimesLines(stopFirstHalf.minute + 1, 0);
    }

    const renderFirstHalfET = () => {
        return startFirstHalfETEvent &&
            renderTimesLines(getStopFirstHalfETEventTime().minute - 90, 90);
    }

    const renderSecondHalfET = () => {
        return startFirstHalfETEvent &&
            renderTimesLines(getStopGameTimeForET().minute + 1 - 105, 105);
    }

    const renderSecondHalf = () => {
        //TODO Refactor
        return startSecondHalf
            ? renderTimesLines((endSecondHalf 
                ? endSecondHalf.minute 
                : stopGameEvent 
                    ? stopGameEvent.minute 
                    : 91) 
                - startSecondHalf.minute + 1, 45)
            : renderTimesLines(47, 45);
    }

    const calculatePositionOnTimeline = (currentPlaytime: number, endEventCurrentPlaytime: number, defaultValue: number) => {
        let eventTimeMilliseconds = currentPlaytime;

        if (isLive && currentPlaytime > defaultValue) {
            eventTimeMilliseconds = defaultValue;
        }

        return `${(eventTimeMilliseconds / endEventCurrentPlaytime) * 100}%`
    }

    const getEndGameTime = () => {
        return endSecondHalf ? endSecondHalf.currentPlaytime : stopGameEvent.currentPlaytime;
    }

    const getStopFirstHalfETEventTime = () => {
        return stopFirstHalfETEvent ? {
            currentPlaytime: stopFirstHalfETEvent.currentPlaytime,
            minute: stopFirstHalfETEvent.minute
        }
        : {
            currentPlaytime: times._90min + times._15min,
            minute: 105
        }
    }

    const getStopGameTimeForET = () => {
        return stopGameEvent ? {
            currentPlaytime: stopGameEvent.currentPlaytime,
            minute: stopGameEvent.minute
        } : {
            currentPlaytime: times._90min + times._30min,
            minute: 120
        }
    }

    const calculateLeftAlignment = (el: RunningBallAction) => {
        switch (el.tickerStateId) {
            case tickerStateCodes.firstHalf:
            case tickerStateCodes.pause:
                return calculatePositionOnTimeline(el.currentPlaytime, stopFirstHalf.currentPlaytime, times._45min)
            case tickerStateCodes.secondHalf:
            case tickerStateCodes.regularTimeFinished:
            case tickerStateCodes.finished:
                return calculatePositionOnTimeline(
                    el.currentPlaytime - startSecondHalf.currentPlaytime,
                    getEndGameTime() - startSecondHalf.currentPlaytime,
                    times._45min
                )
            case tickerStateCodes.firstHalfOvertime:
            case tickerStateCodes.pauseOvertime:
                return calculatePositionOnTimeline(
                    el.currentPlaytime - startFirstHalfETEvent.currentPlaytime,
                    getStopFirstHalfETEventTime().currentPlaytime - startFirstHalfETEvent.currentPlaytime,
                    times._15min
                )
            case tickerStateCodes.secondHalfOvertime:
            case tickerStateCodes.finished:
                return calculatePositionOnTimeline(
                    el.currentPlaytime - startSecondHalfETEvent.currentPlaytime,
                    getStopGameTimeForET().currentPlaytime - startSecondHalfETEvent.currentPlaytime,
                    times._15min
                )
            default:
                return `${(el.currentPlaytime / stopFirstHalf.currentPlaytime) * 100}%`;
        }
    }

    const renderIcons = (array: RunningBallAction[]) => {
        return array.map(el => {
            return (
                <div
                    key={el.eventNumber}
                    onClick={() => itemClickHandler(el)}
                    className="icon"
                    style={{
                        left: calculateLeftAlignment(el),
                        top: getRandomHeight(el.currentPlaytime)
                    }}>
                    <img src={generateImageLink(el.eventCodeId)} />
                </div>
            )
        })
    }

    const renderPause = (text: string) => {
        return (
            <div className="timeline-section" style={{ width: '100px' }}>
                <div className="pause" >
                    <span>{text}</span>
                </div>
            </div>
        )
    }

    const renderIconsFirstHalf = () => {
        return actionsList && actionsList.length &&
            renderIcons(actionsList.filter(item => [tickerStateCodes.firstHalf, tickerStateCodes.pause]
                .includes(item.tickerStateId)));
    }

    const renderIconsSecondHalf = () => {
        const tickerStates = [tickerStateCodes.secondHalf, tickerStateCodes.regularTimeFinished]
        if (!startFirstHalfETEvent) {
            tickerStates.push(tickerStateCodes.finished);
        }
        return actionsList && actionsList.length &&
            renderIcons(actionsList.filter(item => tickerStates
                .includes(item.tickerStateId)));
    }

    const renderIconsFirstHalfET = () => {
        return actionsList && actionsList.length &&
            renderIcons(actionsList.filter(item => [tickerStateCodes.firstHalfOvertime, tickerStateCodes.pauseOvertime]
                .includes(item.tickerStateId)))
    }

    const renderIconsSecondHalfET = () => {
        return actionsList && actionsList.length &&
            renderIcons(actionsList.filter(item => [tickerStateCodes.secondHalfOvertime, tickerStateCodes.finished]
                .includes(item.tickerStateId)));
    }

    if (!stopFirstHalf) {
        return null;
    }

    const isHaveRunningBallData = () => {
        return event.sport === 'Soccer' && actionsList && actionsList.length;
    }

    return isHaveRunningBallData()
        ? (
            <div className="events-progress-bar-container">
                <div className="timeline">
                    <div className="timeline-section">
                        {
                            renderFirstHalf()

                        }
                        {
                            renderIconsFirstHalf()
                        }
                    </div>
                    {
                        renderPause("Half time")
                    }
                    <div className="timeline-section">
                        {
                            renderSecondHalf()
                        }
                        {
                            renderIconsSecondHalf()
                        }
                    </div>
                    {
                        startFirstHalfETEvent &&
                        <Fragment>
                            {
                                renderPause("Extra time")
                            }
                            <div className="timeline-section" style={{ width: '50%' }}>
                                {
                                    renderFirstHalfET()
                                }
                                {
                                    renderIconsFirstHalfET()
                                }
                            </div>
                            {
                                renderPause("Half time (extra time)")
                            }
                            <div className="timeline-section" style={{ width: '50%' }}>
                                {
                                    renderSecondHalfET()
                                }
                                {
                                    renderIconsSecondHalfET()
                                }
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        )
        : (<div />);
}

const mapStateToProps = (state: State) => {
    return {
        logs: state.logs
    }
}


export const mapDispatchToProps = {
    addRunningBallActions
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsProgressBar);
