import StreamSetting from "../../types/stream-setting.type";
import SportEvent from "../../models/sport-event";
import { Moment } from "moment";
import { IFiltersForm } from "../../header/filters/interfaces/filters-form.interface";
import { User } from "../../../common/types/user.type";
import { addRtmpLinkRoutine } from "../../../redux/actions/rtmp";
import { getStreamAliasesRoutine } from "../../../redux/actions/alises/actions";
import { getEventsForInfiniteScrollRoutine, getEventsForTopInfiniteScrollRoutine } from "../../../redux/actions/events/actions";
import { loadEventsByFiltersRoutine } from "../../../redux/actions/events/filtered-events";
import { State } from "../../../common/types/state.type";

export interface ScheduleTableBodyProps {
    isExpanded: boolean;
    windowHeight: number;
    streamSettings: StreamSetting[];
    isOpenSkyBoxes:boolean;
    skyBoxesHeight:number
    events: {
        source: SportEvent[] | null,
        isLoading: boolean,
        isTopScrollLoading: boolean,
        isBottomScrollLoading: boolean,
        selectedDate?: Date | Moment,
        dontHaveEventsAnymore?: boolean
    };
    filteredEvents: SportEvent[];
    firstLiveEvent: SportEvent | null;
    refs: any;
    user: User | null;
    filtersApplied: boolean;
    filters: IFiltersForm;
    streamAliases: string[];
    selectedDate: Date;
    selectVod: (id: string, event: SportEvent, isHd: boolean) => void;
    selectStream: (link: string, playerNumber: number, isRealtime: boolean, isRtmp: boolean, event: SportEvent) => void;
    selectHd: (id: string, event: SportEvent) => Promise<void>;
    addRtmpLink: (link: string) => void;
    getAcceptedServerNames: () => void;
    getSchedulesDueToInfiniteScroll: ({selectedDate: Moment, restriction: Resctriction}) => void;
    getPrevSchedulesDueToInfiniteScroll: ({selectedDate: Moment, restriction: Resctriction}) => void;
    loadEventsByFilters: ({filters: ScheduleFilters, restriction: Resctriction}) => void;
    boxPanelHeight: number;
}

export interface ScheduleTableBodyState {
    scrollY: number,
    prevY: number,
    selectedDateBottom: Moment,
    selectedDateTop: Moment,
    topLoading: boolean,
    bottomLoading: boolean,
    selectedDate: Moment,
    topElementRefLabel: string,
    popupRef: any,
    infoCellWidth: number
}

export const mapStateToProps = (state: State) => {
    const { events, user, streamAliases, filters } = state;
    return {
        events,
        user,
        streamAliases,
        selectedDate: state.events.selectedDate,
        filtersApplied: filters.isApplied,
        filters,
    };
}

export const mapDispatchToProps = {
    addRtmpLink: addRtmpLinkRoutine,
    getAcceptedServerNames: getStreamAliasesRoutine,
    getSchedulesDueToInfiniteScroll: getEventsForInfiniteScrollRoutine,
    getPrevSchedulesDueToInfiniteScroll: getEventsForTopInfiniteScrollRoutine,
    loadEventsByFilters: loadEventsByFiltersRoutine
}
