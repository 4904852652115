import {  fetchApiVersionRoutine } from '../../actions/auth/actions'

const appReducer = (state = {} as any, action: any) => {
    switch (action.type) {        
        case fetchApiVersionRoutine.SUCCESS: {
            return {
              ...state,
              apiVersion: action.payload
            };
          } 
        default: {
            return state;
        }
    }
}

export default appReducer;