import defaultState from "../../defaultState";
import { getEventsTableRoutine, getEventsForInfiniteScrollRoutine, getEventsForTopInfiniteScrollRoutine, refreshEventsRoutine } from "../../actions/events/actions";
import SportEvent from "../../../schedule/models/sport-event";
import { distinct } from "../../../utils/query.utils";
import { loadEventsByFiltersRoutine } from "../../actions/events/filtered-events";

export const eventsReducer = (state = defaultState.events, action: any) => {
    switch (action.type) {
        case getEventsTableRoutine.SUCCESS: {
            return {
                ...state,
                source: action.payload.events as SportEvent[],
                dontHaveEventsAnymore: action.payload.events.length === 0,
                selectedDate: action.payload.date,
                isLoading: false,
            }
        }
        case getEventsForInfiniteScrollRoutine.TRIGGER: {
            return {
                ...state,
                isBottomScrollLoading: true
            }
        }
        case getEventsForInfiniteScrollRoutine.SUCCESS: {
            return {
                ...state,
                source: distinct(x => y => x.wtScheduledEventId === y.wtScheduledEventId)([...state.source as SportEvent[], ...action.payload.events]),
                selectedDate: action.payload.events.length ? action.payload.date : state.selectedDate,
                dontHaveEventsAnymore: action.payload.events.length === 0,
                isBottomScrollLoading: false
            }
        }
        case getEventsForTopInfiniteScrollRoutine.TRIGGER: {
            return {
                ...state,
                isTopScrollLoading: true
            }
        }
        case loadEventsByFiltersRoutine.TRIGGER: {
            return {
                ...state,
                isLoading: true
            }
        }
        case loadEventsByFiltersRoutine.SUCCESS: {
            return {
                ...state,
                selectedDate: action.payload.date,
                isLoading: false
            }
        }
        case getEventsForTopInfiniteScrollRoutine.SUCCESS: {
            return {
                ...state,
                isTopScrollLoading: false,
                selectedDate: action.payload.date,
                source: distinct(x => y => x.wtScheduledEventId === y.wtScheduledEventId)([...action.payload.events, ...state.source as SportEvent[]]),
            }
        }
        case refreshEventsRoutine.SUCCESS: {
            const oldSource = state.source!.filter(i => !action.payload.events.map(x => x.wtScheduledEventId).includes(i.wtScheduledEventId));
            return {
                ...state,
                selectedDate: action.payload.date,
                source: [...action.payload.events, ...oldSource].sort((a, b) => {
                    return new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
                }),
                isLoading: false,
            }
        }
        case getEventsTableRoutine.FAILURE: {
            return {
                ...state,
                source: [],
                isLoading: false
            };
        }
        case getEventsTableRoutine.TRIGGER: {
            return {
                ...state,
                isLoading: true,
            }
        }
        default: {
            return state;
        }
    }
}