import { setIsConnected } from "../../actions/is-connected";
import defaultState from "../../defaultState";

const isConnectedReducer = (state = defaultState.isConnected, action: any) => {
    switch (action.type) {
        case setIsConnected.SUCCESS:
            return true
        case setIsConnected.FAILURE:
            return false
        default: {
            return state;
        }
    }
}

export default isConnectedReducer;