import { call, put, all, takeLatest } from "redux-saga/effects";
import { deleteDevice } from "../../../services/api.service";
import { removeDeviceRoutine } from "../../actions/devices";
import { setErrorToState } from "../../../services/error.service";

function* removeDevice(action: any) {
    const { deviceUuid, userId } = action.payload;
    try {
        const callResponse = yield call(deleteDevice, deviceUuid, userId);

        if(!callResponse) {
            return;
        }

        return yield put(removeDeviceRoutine.success(deviceUuid))
    } catch (err) {
        setErrorToState(err.message);
        yield put(removeDeviceRoutine.failure(err.message));
    }
}

export default function* devicesSaga() {
    yield all([
        takeLatest(removeDeviceRoutine, removeDevice),
    ]);
}