import defaultState from "../../defaultState";
import { loadEventsByFiltersRoutine } from "../../actions/events/filtered-events";

export const filteredEventsReducer = (state = defaultState.filteredEvents, action: any) => {
    switch (action.type) {
        case loadEventsByFiltersRoutine.SUCCESS: {
            return {
                source: [...action.payload.source],
            }
        }
        case loadEventsByFiltersRoutine.FAILURE: {
            return {
                ...state,
                source: []
            }
        }
        default: {
            return state;
        }
    }
}
