import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import createSagaMidddelware from "redux-saga";
import { createStore, applyMiddleware, compose } from "redux";
import rootSaga from './redux/rootSage';
import reducers from '../src/redux/rootReducer';
import App from './router/app';
import { createSocketMiddleware } from './redux/socket';
import env from './env';
import { EnvironmentType } from './common/types/envType';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const sagaMiddleware = createSagaMidddelware();
const socketMiddleware = createSocketMiddleware();
const middleware = [socketMiddleware, sagaMiddleware];

const composeEnhancer = (env.envType === EnvironmentType.local
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : null) || compose

export const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(...middleware))
);
sagaMiddleware.run(rootSaga);

ReactDOM.render(<App store={store} />, document.getElementById('root'));

