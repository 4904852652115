
import { switchNotificationsReceive } from "../../actions/change-password";

const switchNotificationReducer = (state: boolean | null = null, action: any) => {
    switch (action.type) {
        case switchNotificationsReceive.SUCCESS:
            return true
        case switchNotificationsReceive.FAILURE:
            return false
        default: {
            return state;
        }
    }
}

export default switchNotificationReducer;