import React from 'react';
import EventDropdown from "../events-dropdown/events-dropdown";
import SportEvent from '../../schedule/models/sport-event';
import StreamSetting from "../../schedule/types/stream-setting.type";

import "../web-rtc-player/index.scss";

export interface BlankPlayerProps {
    width: number,
    height: number,
    isPart: boolean,
    openEvents: SportEvent[],
    playerNumber: number,
    onPlayerClose: (playerNumber: number) => void
    onEventSelect: (source: any, playerNumber: number, isRealtime: boolean, isRtmp: boolean, event: SportEvent) => void
    streamSettings: StreamSetting[];
}

const BlankPlayer: React.FC<BlankPlayerProps> = ({
    width,
    height,
    openEvents,
    playerNumber,
    onEventSelect,
    isPart,
    onPlayerClose,
    streamSettings,
}) => {
    const eventSelectHandler = 
        (link: string, isRealtime: boolean,
        isHd: boolean, event: SportEvent) => onEventSelect(link, playerNumber, isRealtime, isHd, event);

    return (
        <div style={{ maxWidth: isPart ? '50%' : '100%', width: '100%' }}>
            <div className='player-container' style={{ height }}>
                <EventDropdown
                    width={width}
                    height={height}
                    events={openEvents || []}
                    currentEventId={0}
                    onPlayerClose={() => onPlayerClose(playerNumber)}
                    onStreamClose={() => { }}
                    onEventSelect={eventSelectHandler}
                    streamSettings={streamSettings}
                    playerNumber={playerNumber}
                />
            </div>
        </div>
    )
}


export default BlankPlayer;