import defaultState from "../../defaultState";
import { setFilterValues, discardFilterValues, setDefaultFilterValues, resetToDefaultFilterValues } from "../../actions/filters/routines";

export const filterValuesReducer = (state = defaultState.filterValues, action: any) => {
    switch (action.type) {
        case setFilterValues.SUCCESS: {
            const { sports, countries, services } = action.payload;
            return { ...state, sports, countries, services }
        }
        case discardFilterValues.SUCCESS: {
            return {  ...state, sports: [], countries: [], services: [] };
        }
        case setDefaultFilterValues.SUCCESS: {
            const { sports, countries, services } = action.payload;
            return { default: action.payload, sports, countries, services }
        }
        case resetToDefaultFilterValues.SUCCESS: {
            const { sports, countries, services } = state.default;
            return { ...state, sports, countries, services}
        }
        default: {
            return state;
        }
    }
}
