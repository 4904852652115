import defaultState from "../../defaultState";
import { loadVodLink, setVOD } from "../../actions/vod/routines";

export const vodReducer = (state = defaultState.vod, action: any) => {
    switch (action.type) {
        case loadVodLink.SUCCESS:
        case setVOD.SUCCESS: {
            return action.payload || ''
        }
        case setVOD.FAILURE:
        case loadVodLink.FAILURE: {
            return '';
        }
        default: {
            return state;
        }
    }
}
