import React, { createRef } from 'react';
import 'react-dates/initialize';
import './style.scss';
import SportEvent from './models/sport-event';
import 'react-dates/lib/css/_datepicker.css';
import update from 'react-addons-update';
import StreamSetting from './types/stream-setting.type';
import CustomDocument from './interfaces/custom-document.interface';
import PlayersContainerComponent from './players-container';
import { connect } from 'react-redux';
import SiteHeader from './header';
import ScheduleTableBody from './table/body';
import ExpandButton from './table/expand-button';
import moment, { Moment } from 'moment';
import uuid, {v4 as uuidv4} from 'uuid';
import { localTime, minutesToMiliseconds } from '../utils/time-converter.utils';
import { isOpenEvent } from './services/shared.service';
import { isEdge } from '../utils/user-agent';
import {defaultScheduleFilters, ScheduleFilters} from './models/schedule-filters';
import { SchedulePageProps, SchedulePageState, mapStateToProps, mapDispatchToProps } from './infrastructure';
import MulticastModal from "./multicast-modal";
import {Restriction} from "../common/types/restriction.type";

class SchedulePage extends React.Component<SchedulePageProps, SchedulePageState> {
    refs: any = [];
    isFirstWithStream: boolean = false;
    firstLiveEvent: SportEvent | null = null;
    refreshTimer: any;
    skySportsLink: string = "https://review.inplayip.tv/inplay_vod/1234321_9876789_720/chunks_dvr.m3u8";

    private defaultSetting: StreamSetting = {
        autoplay: true,
        controls: true,
        liveui: true,
        muted: true,
        playbackRates: [0.1, 0.5, 1, 2, 4, 8],
        width: 0,
        height: 0,
        source: '',
        isRealtime: false,
        key: "",
        event: SportEvent.createInstance(),
        isHd: false,
        isVod: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            isExpanded: true,
            width: window.innerWidth,
            height: window.innerHeight,
            streamSettings: [],
            isShowCloseButton: false,
            isOpenSkyBoxes: false,
            skyBoxesHeight: 0,
            multicastVisible: false,
            activeBox: null,
            skyBoxWssStreamLink: "",
            isPlaySkyBoxStream: false,
            boxPanelHeight: 0,
            isFullscreen: false,
            isOpenInternalStreamsPanel: false,
            streamsPanelHeight: 0,
            activeInternalStream: null,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.props.getScheduleDueToJumpToLive(moment().toDate());
        this.refreshTimer = setInterval(this.refreshTable.bind(this), minutesToMiliseconds(5));

        if (this.props.user!.restriction.isSkyBoxesAllowed) {
            this.props.getSkyBoxes();
            this.props.getScreens();
            this.props.getInternalStreams();
        }

        if (this.props.user && this.props.user.userScheduleFilter) {
            const filters = this.props.user.userScheduleFilter;

            const filtersModel: ScheduleFilters = {
                ...defaultScheduleFilters,
                sportsCriteria: filters.sports,
                countriesCriteria: filters.countries,
                servicesCriteria: filters.services,
                showVOD: filters.showVOD,
                showLive: filters.showLive,
                onlyNew: filters.onlyNew,
                searchWord: ""
            }
            this.props.loadEventsByFilters(filtersModel, this.props.user!.restriction as Restriction);
            this.props.applyFilters(true);
            this.props.setFilters(filters);
        }
    }

    componentWillReceiveProps(props: SchedulePageProps) {
        if (props.isJumpToLive) {
            this.props.setJumpToLive(false);

            if (!this.firstLiveEvent) {
                this.scrollToNearestEvent();
                return;
            }
            this.scrollToEvent(this.firstLiveEvent);
        }

        if (props.vod && props.vod !== this.props.vod) {
            const vodSetting: StreamSetting = {
                ...this.defaultSetting,
                event: this.state.streamSettings[0].event,
                source: props.vod,
                isRealtime: false,
                isVod: true
            };
            this.setState({ streamSettings: [vodSetting] })
        }

        if (props.user
            && props.user.id === props.userLogout.userId
            && props.user.deviceUid === props.userLogout.deviceUid) {
            localStorage.clear();
            window.location.href = '/login?errorMessage=Your session was invalidated!';
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.events !== this.props.events && this.props.filtersApplied) {
             this.jumpToLive(moment(this.props.events.selectedDate) || moment(new Date()));
        }
        if (this.state.isOpenSkyBoxes && !this.state.skyBoxesHeight){
            const height = document.getElementById("skyBoxes")!.clientHeight;
            this.setState({skyBoxesHeight:height})
        }
    }

    render() {
        !this.props.events.isLoading && this.createRefsForTableRows();
        const { streamSettings, isShowCloseButton, width, height, isPlaySkyBoxStream } = this.state;

        return (
            <div
                style={{ backgroundColor: '#212529' }}
                onMouseMove={streamSettings.length ? this.handleMouseMove : undefined}>
                {
                    this.state.multicastVisible &&
                        <MulticastModal
                            multicastModalToggle={this.multicastModalToggle}
                            activeSkyBox={this.state.activeBox}
                            activeInternalStream={this.state.activeInternalStream}
                            />
                }
                <div className={'header'}>
                    <SiteHeader isHidden={this.state.isExpanded} />
                    <div className={'schedule'}>
                        <div className={`table${this.state.isExpanded ? '-expanded' : ''}`}>
                            <SiteHeader
                                isHidden={!this.state.isExpanded}
                                isTableExpanded={this.state.isExpanded}
                                firstLiveEvent={this.firstLiveEvent}
                                filtersData={null}
                                jumpToLive={this.jumpToLive}
                                onDateChange={this.onDateChange}
                                selectStream={this.selectStream}
                                isOpenSkyBoxes={this.state.isOpenSkyBoxes}
                                multicastModalToggle={this.multicastModalToggle}
                                toggleSkyBoxes={this.toggleSkyBoxes}
                                skySportsLink={this.skySportsLink}
                                setActiveBox={this.setActiveBox}
                                isPlaySkyBoxStream={isPlaySkyBoxStream}
                                activeSkyBox={this.state.activeBox}
                                resizeBoxPanelHandler={this.resizeBoxPanelHandler}
                                streamSettings={this.state.streamSettings}
                                isOpenInternalStreamsPanel={this.state.isOpenInternalStreamsPanel}
                                toggleInternalStreamsPanel={this.toggleInternalStreamsPanel}
                                boxPanelHeight={this.state.boxPanelHeight}
                            />
                            <ScheduleTableBody
                                isExpanded={this.state.isExpanded}
                                windowHeight={height}
                                streamSettings={this.state.streamSettings}
                                firstLiveEvent={this.firstLiveEvent}
                                filteredEvents={this.props.filteredEvents}
                                refs={this.refs}
                                selectVod={this.selectVod}
                                selectHd={this.selectHd}
                                isOpenSkyBoxes={this.state.isOpenSkyBoxes}
                                skyBoxesHeight={this.state.skyBoxesHeight}
                                selectStream={this.selectStream}
                                boxPanelHeight={this.state.boxPanelHeight}
                            />
                            <ExpandButton
                                toggleSchedule={this.toggleSchedule}
                                isExpanded={this.state.isExpanded}
                                isFullscreenToggle={this.isFullscreenToggle}
                                isFullscreen={this.state.isFullscreen}
                            />
                        </div>
                    </div>
                </div>
                {
                    isShowCloseButton && !this.state.isFullscreen && (
                        <div className="close-players-btn" onClick={this.closeAllPlayers} style={{ right: (width / 2 - 27), top: '70px' }}>
                            <i className="fas fa-times" />
                        </div>)
                }
                {
                    this.renderPlayersContainer()
                }
            </div>
        );
    }

    isFullscreenToggle = (isFull) => {
        this.setState({isFullscreen: isFull});
    }

    renderPlayersContainer() {
        const { streamSettings, width, height, isPlaySkyBoxStream } = this.state;

        return <PlayersContainerComponent
            key={'PlayerContainer'}
            streamSettings={streamSettings}
            height={height}
            width={width}
            selectStream={this.selectStream}
            onPlayerClose={this.onPlayerClose}
            multicastModalToggle={this.multicastModalToggle}
            isPlaySkyBoxStream={isPlaySkyBoxStream}
            activeSkyBox={this.state.activeBox}
            isFullscreenToggle={this.isFullscreenToggle}
        />
    }

    handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { isExpanded, isShowCloseButton } = this.state;
        const { clientY: y } = event;
        if (y < 125 && !isExpanded) {
            !isShowCloseButton && this.setState({ isShowCloseButton: true });
        }
        else {
            isShowCloseButton && this.setState({ isShowCloseButton: false });
        }
    }

    handleWindowResize = () => {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    createRefsForTableRows = () => {
        let events = this.props.events.source;

        if (this.props.filtersApplied) {
            events = this.props.filteredEvents;
        }

        this.refs = [];
        this.firstLiveEvent = null;

        events.forEach((item: SportEvent) => {
            this.refs[`${item.eventId.toString()} ${item.startTime.toString()}`] = createRef();

            const isLive = isOpenEvent(item);
            //set first live event for jump to live
            if (isLive && !this.firstLiveEvent) {
                this.firstLiveEvent = item;
            }
        });
    }

    toggleSchedule = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
        document.getElementById('header')!.style.opacity = '0'
    }

    closeFullscreen() {
        const doc = document as CustomDocument;
        doc.exitFullscreen && doc.exitFullscreen();
        doc.mozCancelFullScreen && doc.mozCancelFullScreen();
        doc.webkitExitFullscreen && doc.webkitExitFullscreen();
        doc.msExitFullscreen && doc.msExitFullscreen();
    }

    onDateChange = (date: Moment) => {
        const el = (document.getElementById('table-container') as HTMLDivElement);
        if (isEdge()) {
            el.scrollTop = 41;
            return;
        }
        el.scrollTo({ top: 41 })

        if (this.props.filtersApplied) {
            this.scrollToNearestEvent(date);
        } else {
            const filters = { ...defaultScheduleFilters, searchDate: date.toDate() }
            this.props.getSchedule(filters);
        }
    }

    scrollToNearestEvent = (date?: Moment) => {
        const events = this.props.filtersApplied ? this.props.filteredEvents : this.props.events.source
        const nearestEvent = events
            .find((x: SportEvent) => moment(localTime(x.startTime)).isAfter(date ? date : moment()));

        if (!nearestEvent) {
            return;
        }
        this.scrollToEvent(nearestEvent);
    }

    jumpToLive = (selectedDate: Moment) => {

        if (!this.firstLiveEvent) {

            const startOfSelectedDate = selectedDate && selectedDate.startOf('day');
            const startOfCurrentDate = moment().startOf('day');

            if (startOfSelectedDate && !startOfSelectedDate.isSame(startOfCurrentDate)) {
                this.props.getScheduleDueToJumpToLive(moment().toDate());
                this.firstLiveEvent = null;
            }

            if (this.props.events.source.length !== 0) {
                this.scrollToNearestEvent(moment(this.props.events.selectedDate) || moment());
            }
            return;
        }

        this.scrollToEvent(this.firstLiveEvent);
    }

    scrollTo = (positionTop: number) => {
        const el = (document.getElementById('table-container') as any);

        if (isEdge()) {
            el.scrollTop = positionTop - 49;
            return;
        }

        el.scrollTo({
            top: positionTop - 49,
            behavior: 'smooth'
        })
    }

    scrollToEvent = (event: SportEvent) => {
        const refLabel = `${event.eventId.toString()} ${event.startTime.toString()}`;

        if (Object.keys(this.refs).length === 0 || !this.refs[refLabel]) {
            return;
        }

        const topPoint = this.refs[refLabel].current;

        if (!topPoint) {
            return;
        }

        this.scrollTo(topPoint.offsetTop as number);
    }

    selectStream = (link: string, playerNumber: number, isRealtime: boolean, isHd: boolean, event: SportEvent) => {

        if (event!.eventId !== 0) {
            this.setState({
                isPlaySkyBoxStream: false
            });
        } else {
            this.setState({
                isPlaySkyBoxStream: true
            });
        }
        const settings = this.state.streamSettings;
        if (settings[playerNumber - 1]) {
            const key = uuid()
            this.setState({
                streamSettings: update(
                    this.state.streamSettings,
                    {
                        [playerNumber - 1]: {
                            source: {
                                $set: link
                            },
                            isRealtime: {
                                $set: isRealtime
                            },
                            event: {
                                $set: event
                            },
                            key: {
                                $set: key
                            },
                            isHd: {
                                $set: isHd
                            },
                            isVod: {
                                $set: false
                            }
                        }
                    })
            })
            return;
        }

        const newSetting: StreamSetting = {
            ...this.defaultSetting,
            source: link,
            isRealtime,
            isHd: isHd,
            event: event,
            key: uuid(),
            isVod: false
        };

        if (settings.length >= 4) {
            return
        }

        if (settings.length === 2) {
            //Add blank player for fourth quadrant
            const blankSetting: StreamSetting = { ...newSetting, source: '', key: uuid() };
            this.setState({ streamSettings: [...settings, newSetting, blankSetting] });
            return;
        }

        this.setState({ streamSettings: [...settings, newSetting] });
    }

    onPlayerClose = (playerNumber: number) => {
        const streamSettings = this.state.streamSettings.filter((s: any, index: any) => (index + 1) !== playerNumber);
        (streamSettings.length)
            ? this.setState({streamSettings: streamSettings, isExpanded: false})
            : this.setState({streamSettings: streamSettings, isExpanded: true})
    }

    closeAllPlayers = () => {
        this.setState({ streamSettings: [], isShowCloseButton: false, isExpanded: true });
        this.setState({isPlaySkyBoxStream: false});
    }

    selectVod = async (id: string, event: SportEvent, isHd) => {

        const newSetting: StreamSetting = {
            ...this.defaultSetting,
            source: id,
            event: event,
            isHd: isHd,
            key: uuid(),
            isVod: true
        };

        await this.setState({ streamSettings: [newSetting] });
        this.props.setVOD(parseInt(id));
    }

    selectHd = async (id: string, event: SportEvent) => {
        const newSetting: StreamSetting = {
            ...this.defaultSetting,
            source: id,
            event: event,
            key: uuid(),
            isHd: true,
            isVod: false
        };

        await this.setState({ streamSettings: [newSetting] });
    }

    refreshTable = () => {
        if(this.props.filtersApplied) {
            return;
        }
        this.props.getScheduleForRefresh(this.props.events.selectedDate || new Date());
    }

    toggleSkyBoxes = () => {
        this.setState({isOpenSkyBoxes:! this.state.isOpenSkyBoxes})
    }

    toggleInternalStreamsPanel = () => {
        this.setState({isOpenInternalStreamsPanel: !this.state.isOpenInternalStreamsPanel})
    }

    setActiveBox = (box) => {
        this.setState({activeBox: box});
    }

    resizeBoxPanelHandler = (boxPanelRef) => {
        if(boxPanelRef.current !== null)  {
            this.setState({boxPanelHeight: boxPanelRef.current!.clientHeight}, () => {
            })
        }
    }

    multicastModalToggle = (internalStream?) => {
        this.setState({multicastVisible: !this.state.multicastVisible});
        if (internalStream) {
            this.setState({activeInternalStream: internalStream});
        } else {
            this.setState({activeInternalStream: null});
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchedulePage);
