import SportEvent from "../../schedule/models/sport-event";
import { localTime } from "../../utils/time-converter.utils";
import moment from 'moment';

export const isOpenEvent = (event: SportEvent) => {
    const startTime = localTime(event.startTime);
    const endTime = localTime(event.endTime);
    const dtNow = moment();
    const minsToScheduledStart = startTime.diff(dtNow) / 60000;
    return minsToScheduledStart <= 15 && endTime.isAfter(dtNow) && !event.cancelled;
}

export const calculateCountdown = (event: SportEvent) => {
    const minsToScheduledStart = localTime(event.startTime).diff(moment()) / 60000;
    return minsToScheduledStart > 15 && minsToScheduledStart < 60 ? Math.ceil(minsToScheduledStart) : null;
}

export const isNewEvent = (event: SportEvent) => {
    const createdDate = localTime(event.created);
    const sixHourEarlier = moment().subtract(6, 'hours');
    return createdDate.isAfter(sixHourEarlier);
}
