import SportEvent from '../../../../models/sport-event';
import React from 'react';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { localTime } from '../../../../../utils/time-converter.utils';

export interface SportradarRowProps {
    item: SportEvent;
    startTime: moment.Moment;
    refs: any;
}

const renderWatch = (eventStatus: string, startTime: Date) => {
    switch (eventStatus) {
        case 'O':
            return (
                <div>
                    <span className='dot' style={{ backgroundColor: 'red' }}></span>
                    <span className='live-vod-btn'>LIVE</span>
                </div>  
            );
        case 'C':
            return (
                <div>
                    <span className='dot' style={{ backgroundColor: 'green' }}></span>
                    <span className='live-vod-btn'>VOD</span>
                </div>  
            );
        case 'V':
            return (
                <div>
                    <span className='cancelled-label'>Event Cancelled</span>
                </div>  
            );
        case 'P':
            const t = moment(localTime(startTime).diff(moment(new Date()))).minutes();
            return (
                <div>{ localTime(startTime) > moment(new Date()) && localTime(startTime) < moment(new Date()).add(1, "h") ? `${t} minutes` : "" }</div>  
            );
    }
}

const SportradarRow: React.FC<SportradarRowProps> = ({ item, startTime, refs }) => {

    return (
        <tr
            className="event-row"            
            start-time={startTime}
            style={{ background: '#4a4646' }}
            ref={refs[`${item.eventId.toString()} ${item.startTime.toString()}`]}
        >
            <td className="start-time">
                <span>                    
                    {startTime.format('D/MM/YYYY HH:mm')}
                </span>
            </td>
            <td className="latency">
                <img alt="SPRAV" src='./service-icons/spr.png' />
            </td>
            <td className='sport-column'>
                {item.sport}                
            </td>
            <td className="competition-column">
                <img
                    alt={item.countryCode}
                    src={`./countryflags/${item.countryCode.toLowerCase()}.gif`}
                    style={{ marginRight: '5px', paddingBottom: '3px' }}
                />
                {item.competition}
            </td>
            <td className="home-team-column">{item.competitor1}</td>
            <td className="away-team-column">{item.competitor2}</td>
            <td align="center" className="watch-col">
                <div className="d-flex">
                    {
                        <Tooltip placement='top' trigger={'hover'} overlay={<span>Unfortunately you are not subscribed to see this content- Please contact sales@igamemedia.com</span>}>
                            { renderWatch(item.information, item.startTime) }
                        </Tooltip>
                    }

                </div>
            </td>
        </tr>
    );
}

export default SportradarRow;