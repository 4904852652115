import * as React from 'react';
import './styles.scss'
import {ChangeEvent} from "react";

type CheckboxProps = {
    label: string,
    value: boolean,
    onSelect: (value: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
    return <div>
        <div style={{ display: 'relative'}}>
            <input 
            className={'styled-checkbox'} 
            id={props.label} 
            checked={props.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                props.onSelect(e.target.checked);
            }}
            type="checkbox"/>
            <label htmlFor={props.label}>{props.label}</label>
        </div>
    </div>;
}

export default Checkbox;