import React from "react";
import {ISessionTypeData} from "../sky-box/SkyBox";

import "./style.scss";

export interface ISessionButtonProps {
    data: ISessionTypeData;
    progress: string;
    setSessionTypeManually: (btnPosition: number, lockType) => void;
    setSessionProgressClass: (btnSession: string, activeSession: string | null) => string;
    setSessionBtnIconClass: (btnSession: string, activeSession: string | null) => string;
    lockProperties: any;
}

const SessionButton: React.FunctionComponent<ISessionButtonProps> = ({
    data: {
        lockName,
        lockValue,
        labelPosition,
        labelName,
        icon
    },
    progress,
    setSessionTypeManually,
    lockProperties,
    setSessionProgressClass,
    setSessionBtnIconClass
}) => {
    return (
        <button
            onClick={() => setSessionTypeManually(labelPosition, lockValue)}
            className="box-session-controls__btn">
            <div className={setSessionProgressClass(lockName, lockProperties.lockName)}
                 style={{width: progress, marginLeft: "10%"}}></div>
            <div className={setSessionBtnIconClass(lockName, lockProperties.lockName)}>
                <img src={icon} alt={"icon"}/>
            </div>
            <span className="box-session-controls__btn-text" >{labelName}</span>
        </button>
    )
}


export default SessionButton;