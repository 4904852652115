import { setSignInTimeRoutine } from "../../actions/logs/actions";
import defaultState from "../../defaultState";

export const signInTimeReducer = (state: string = defaultState.signInTime, action: any) => {
    switch (action.type) {
        case setSignInTimeRoutine.TRIGGER:
            return new Date().toISOString();
        default:
            return state;
    }
}