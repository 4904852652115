import defaultState from "../../defaultState";
import {
    getScreensListRoutine, sendInternalStreamToScreenRoutine,
    sendStreamToScreenRoutine,
    updateScreenNameRoutine,
    updateScreenRoutine
} from "../../actions/screens";

const screensReducer = (state = defaultState.screens, action: any) => {
    switch (action.type) {

        case updateScreenRoutine.TRIGGER:
            const updateData = action.payload.data[0];
            const updatedScreens = state.source.map(x => {
                if (x.location === updateData.location) {
                    return {
                        location: updateData.location,
                        screens: x.screens.map(item => {
                            const screenInfo = updateData.screens.find(y => y.screenId === item.screenId);
                            if (screenInfo) {
                                return {
                                    ...item,
                                    locked: screenInfo.skyBoxId !== null || screenInfo.internalStreamId !== null,
                                    skyBoxId: screenInfo.skyBoxId,
                                    internalStreamId: screenInfo.internalStreamId,
                                    internalStreamName: screenInfo.internalStreamName,
                                    status: screenInfo.status,
                                    loading: false,
                                }
                            } else {
                                return {
                                    ...item,
                                    loading: false
                                };
                            }
                        })
                    }
                }
                return x;
            });
            return {...state, source: updatedScreens}


        case sendStreamToScreenRoutine.TRIGGER:
            let dataArr: any = [];

            if (action.payload.screenIds.length > 1) {
                dataArr = state.source.map(x => {
                    if (x.location === action.payload.location) {
                        return {
                            location: action.payload.location,
                            screens: x.screens.map(item => {
                                return {
                                    ...item,
                                    loading: true,
                                }
                            })
                        }
                    }
                    return x;
                });
            } else {
                dataArr = state.source.map(x => {
                    if (x.location === action.payload.location) {
                        return {
                            location: action.payload.location,
                            screens: x.screens.map(item => {
                                if (item.screenId === action.payload.screenIds[0]) {
                                    return {
                                        ...item,
                                        loading: true,
                                    }
                                }
                                return item;
                            })
                        }
                    }
                    return x;
                });
            }
            return {...state, source: dataArr}


        case sendStreamToScreenRoutine.FAILURE:
            return state;


        case getScreensListRoutine.TRIGGER:
            return {...state, isLoading: true}


        case getScreensListRoutine.SUCCESS:
            const screensData = action.payload.map(x => {
                return {
                    location: x.location,
                    screens: x.screens.map(item => {
                        return {
                            ...item,
                            locked: item.skyBoxId !== null || item.internalStreamId !== null,
                            loading: false,
                        }
                    })
                }
            })
            return {...state, source: screensData, isLoading: false}

        case updateScreenNameRoutine.SUCCESS:
           const screensUpdated = state.source.map(x => {
                if (x.location === action.payload.location) {
                    return {
                        location: action.payload.location,
                        screens: x.screens.map(item => {
                            if (item.screenId === action.payload.screenId) {
                                return {
                                    ...item,
                                    name: action.payload.name
                                }
                            }
                            return item;
                        })
                    }
                }
                return x;
            });
            return {...state, source: screensUpdated}

        case sendInternalStreamToScreenRoutine.TRIGGER:
            let screensInfo: any = [];

            if (action.payload.screenIds.length > 1) {
                screensInfo = state.source.map(x => {
                    if (x.location === action.payload.location) {
                        return {
                            location: action.payload.location,
                            screens: x.screens.map(item => {
                                return {
                                    ...item,
                                    loading: true,
                                }
                            })
                        }
                    }
                    return x;
                });
            } else {
                screensInfo = state.source.map(x => {
                    if (x.location === action.payload.location) {
                        return {
                            location: action.payload.location,
                            screens: x.screens.map(item => {
                                if (item.screenId === action.payload.screenIds[0]) {
                                    return {
                                        ...item,
                                        loading: true,
                                    }
                                }
                                return item;
                            })
                        }
                    }
                    return x;
                });
            }
            return {...state, source: screensInfo}

        case sendInternalStreamToScreenRoutine.FAILURE:
            return state;


        default:
            return state;
    }
}

export default screensReducer;