import React, {useEffect, useState} from "react";
import Screen from "./screen";
import {ISkyBoxState} from "../../common/types/sky-boxes";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../common/types/state.type";
import {sendInternalStreamToScreenRoutine, sendStreamToScreenRoutine} from "../../redux/actions/screens";

import './style.scss';
import {toastr} from "react-redux-toastr";
import {IInternalStream} from "../../common/types/internal-streams";

export interface IMulticastModalProps {
    multicastModalToggle: () => void;
    activeSkyBox: ISkyBoxState;
    activeInternalStream: null | IInternalStream;
}

const MulticastModal: React.FunctionComponent<IMulticastModalProps> = ({
    multicastModalToggle,
    activeSkyBox,
    activeInternalStream
}) => {

    const screens = useSelector((state: State) => state.screens.source);
    const currentUser = useSelector((state: State) => state.user);
    const dispatch = useDispatch();

    const isNotAccessible = currentUser!.role === "User" || currentUser!.role === "Basic User" || currentUser!.role === "Client Analyst";

    const [sendToAll, setSendToAll] = useState(false);
    const [location, setLocation] = useState(screens[0].location);
    const [visibleScreens, setVisibleScreens] = useState(screens[0].screens);

    useEffect(() => {
        const data = screens!.find((item: any) => item.location === location);
        setVisibleScreens(data!.screens);
    }, [screens, location, visibleScreens]);

    useEffect(() => {
        const allScreensLocked = visibleScreens.every(item => item.locked);
        if (allScreensLocked && visibleScreens.length !== 0) {
            setSendToAll(true);
        }
        if (!allScreensLocked){
            setSendToAll(false);
        }
    }, [location, visibleScreens]);

    const sendToAllToggle = () => {
        if (isNotAccessible) {
            return;
        }

        const locationData = screens.find(item => item.location === location);
        const locationScreensIds = locationData!.screens.map(x => x.screenId);
        const unlockedScreensIds = visibleScreens.filter(item => !item.locked).map(x => x.screenId);

        if (!sendToAll) {
            const data = {
                location: location,
                screenIds: unlockedScreensIds
            }
            localStorage.setItem("unLockedScreensData", JSON.stringify(data));
            if (activeInternalStream !== null) {
                dispatch(sendInternalStreamToScreenRoutine({
                    skyBoxId: null,
                    internalStreamId: activeInternalStream.id,
                    locked: !sendToAll,
                    screenIds: locationScreensIds,
                    location
                }));
            } else {
                dispatch(sendStreamToScreenRoutine({
                    skyBoxId: activeSkyBox.skyBoxId,
                    locked: !sendToAll,
                    screenIds: locationScreensIds,
                    location
                }));
            }
        } else {
            const data: any = localStorage.getItem("unLockedScreensData")
            const previousScreensState = JSON.parse(data);
            if (previousScreensState !== null) {
                if(activeInternalStream !== null) {
                    dispatch(sendInternalStreamToScreenRoutine({
                        skyBoxId: null,
                        internalStreamId: activeInternalStream.id,
                        locked: false,
                        screenIds: previousScreensState.screenIds,
                        location: previousScreensState.location
                    }));
                } else {
                    dispatch(sendStreamToScreenRoutine({
                        skyBoxId: activeSkyBox.skyBoxId,
                        locked: false,
                        screenIds: previousScreensState.screenIds,
                        location: previousScreensState.location
                    }));
                }
            } else {
                if(activeInternalStream !== null) {
                    dispatch(sendInternalStreamToScreenRoutine({
                        skyBoxId: null,
                        internalStreamId: activeInternalStream.id,
                        locked: false,
                        screenIds: locationScreensIds,
                        location: location
                    }));
                } else {
                    dispatch(sendStreamToScreenRoutine({
                        skyBoxId: activeSkyBox.skyBoxId,
                        locked: false,
                        screenIds: locationScreensIds,
                        location: location
                    }));
                }
            }
        }
    }

    const onLocationSelected = (locationName) => {
        setLocation(locationName);
    }

    const setLocationClass = (locationName) => {
        if (location === locationName) {
            return "locations__list-item active";
        } else {
            return "locations__list-item";
        }
    }

    const inUseToggle = (screenId, location, locked) => {
        if (isNotAccessible) {
            toastr.error('Sorry!', 'You are not allowed to perform this action!');
           return;
        }
        if (activeInternalStream !== null) {
            dispatch(sendInternalStreamToScreenRoutine({
                skyBoxId: null,
                internalStreamId: activeInternalStream.id,
                locked,
                screenIds: [screenId], location
            }));
        } else {
            dispatch(sendStreamToScreenRoutine({skyBoxId: activeSkyBox.skyBoxId, locked, screenIds: [screenId], location}));
        }
    }

    return (
        <div className="multicast-modal-overlay">
            <div className="multicast-modal-container">
                <div className="modal-header">
                    <img className="modal-header__logo"
                         src={process.env.PUBLIC_URL + 'service-icons/sky-logo.svg'}
                         alt="sky"/>
                    <span className="modal-header__box-number">{activeInternalStream === null ? `Box ${activeSkyBox.output}` : `${activeInternalStream.name}`}</span>
                    <img className="modal-header__close-button"
                         src={process.env.PUBLIC_URL + 'service-icons/close.svg'}
                         alt="close"
                         onClick={() => multicastModalToggle()}
                    />
                </div>
                <div className="modal-body">
                    <div className="locations">
                        <h1 className="locations__title">Locations</h1>
                        <ul className="locations__list">
                            {
                                screens.map((item, index) => (
                                    <li className={setLocationClass(item.location)}
                                        onClick={() => onLocationSelected(item.location)} key={index}>
                                        <span className="locations__location-name">{item.location}</span>
                                        <span className="locations__screen-icon"></span>
                                        <span className="locations__screen-quantity">{item.screens.length}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="screens">
                        <div className="screens__header">
                            <span className="send-to-all-title">Send To All</span>
                            <div className={`send-to-all-button ${sendToAll && "active"}`}
                                 onClick={sendToAllToggle}>
                                <div className="send-to-all-circle"></div>
                            </div>
                        </div>
                        <div className="screens__table">
                            {
                                visibleScreens.map((data, index) => (
                                    <Screen
                                        data={data}
                                        inUseToggle={() => inUseToggle(data.screenId, location, !data.locked)}
                                        locked={data.locked}
                                        activeSkyBox={activeSkyBox}
                                        key={index}
                                        location={location}
                                        isNotAccessible={isNotAccessible}
                                        activeInternalStream={activeInternalStream}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MulticastModal;


