import React, { Fragment } from "react";
import LoginPage from "../login";
import { Route, Redirect, Switch } from "react-router-dom";
import SchedulePage from "../schedule";
import PrivateRoute from "./private-route";
import { connect } from "react-redux";
import Logout from "./logout";
import ReduxToastr from "react-redux-toastr";
import { ChangePasswordPage } from "../reset-password/profile-page";
import { getCurrentUser } from "../redux/actions/auth/actions";
import { HubConnection } from "@microsoft/signalr";
import { setIsConnected } from "../redux/actions/is-connected";
import { User } from "../common/types/user.type";
import { State, LogDownload } from "../common/types/state.type";
import { setSignInTimeRoutine } from '../redux/actions/logs/actions';
import uuid from "uuid";
import { connectSocket } from "../redux/actions/sockets";
import { PlayerStats } from "../common/types/player-stats.type";
import { history } from './history';
import VodClipper from "../vod-clipper";

interface AppRouterProps {
	setSignInTime: () => void;
	connectSocket: (token: string) => void;
	setConnected: (value: boolean) => void;
	getCurrentUser: () => void;
	user: User | null;
	logs: any;
	signInTime: any;
	rtmp: string[];
}

class AppRouter extends React.Component<AppRouterProps, { hub: HubConnection | undefined }> {

	//TODO: Investigate re-rendering of this component
	//It happens twice - before login and right after login
	componentDidMount() {
		this.clearStorageIfNotReload();
		this.getCurrentUser();
		this.props.setSignInTime();
		localStorage.setItem('sessionId', uuid.v4())

		this.setupBeforeUnloadListener();
	}

	setupBeforeUnloadListener = () => {
		window.addEventListener("beforeunload", (ev) => {
			if (localStorage) {
				localStorage.setItem('unloadEventFlag', new Date().getTime().toString());
			}
		});
	}
	componentWillUnmount() {
		localStorage.removeItem('sessionId');
	}

	clearStorageIfNotReload() {
		const timeLastTimeClosed = localStorage.getItem('unloadEventFlag');

		const time = timeLastTimeClosed ? Number.parseInt(timeLastTimeClosed) : 0
		const timeNow = new Date().getTime();
		const duration = timeNow - time;

		if (duration > 10*1000) {
			if (!localStorage.getItem('redirectToCms')) {
				// localStorage.removeItem('token');
				// localStorage.removeItem('refreshToken');
				localStorage.removeItem('redirectToCms');
				return;
			}

			localStorage.removeItem('redirectToCms')
		}
	}

	private getCurrentUser() {
		if (!localStorage.getItem('token')) {
			return;
		}

		this.props.getCurrentUser();
	}

	render() {
		return (<Fragment>
			<ReduxToastr
				timeOut={10000}
				newestOnTop={false}
				preventDuplicates={true}
				position="top-right"
				transitionIn="fadeIn"
				transitionOut="fadeOut"
				progressBar />
			<Switch>
				<PrivateRoute exact path="/schedule" component={SchedulePage} />
				<PrivateRoute exact path="/profile/change-password" component={ChangePasswordPage} />
				<PrivateRoute exact path="/profile/information" component={ChangePasswordPage} />
				<PrivateRoute exact path="/profile/device-management" component={ChangePasswordPage} />
				<PrivateRoute exact path="/vod-clipper/:id" component={VodClipper} />
				<Route path="/login" component={LoginPage} />
				<PrivateRoute exact path="/logout" component={Logout} />
				{/* Temp remove help page */}
				{/* <PrivateRoute exact path="/help" component={HelpPage} /> */}
				<Redirect from='*' to={this.defaultRedirect()} />
			</Switch>
		</Fragment>
		);
	}

	defaultRedirect = (): string => {
		let token = localStorage.getItem('token');

		if ((!token) && (history.location.pathname !== '/login')) {
			let initPath = history.location.pathname;
			return initPath !== '/' ?`/login?redirectUrl=${initPath}` : '/login';
		} else {
			const initPath = new URLSearchParams(window.location.search).get("redirectUrl");
			if (initPath) {
				return initPath;
			} else {
				return '/schedule';
			}
		}
	}
}

const mapStateToProps = (state: State) => {
	return {
		user: state.user,
		logs: state.logs,
		signInTime: state.signInTime,
		rtmp: state.rtmp,
	}
}

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
	return {
		getCurrentUser(){
			dispatch(getCurrentUser())
		},
		setConnected(value: boolean) {
			const func = value ? setIsConnected.success : setIsConnected.failure
			dispatch(func());
		},
		setSignInTime() {
			dispatch(setSignInTimeRoutine())
		},
		connectSocket(token: string) {
			dispatch(connectSocket(token));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
