import { all, takeLatest, put, call } from "redux-saga/effects";
import {loadVodLink as routines, downloadVod, clipVodRoutine} from "../../actions/vod/routines";
import {clipVodRequest, loadVod} from "../../../services/api.service";
import {toastr} from "react-redux-toastr";

function* loadVodLink(action: any) {
    const id = action.payload;
    try {
        const resp = yield call(loadVod, id);

        yield put(routines.success(resp));
    } catch (err) {
        console.log(err);
        yield put(routines.failure());
    }
}

function* downloadVodStream(action: any) {
    const id = action.payload;
    try {
        yield call(downloadVod, id);
        yield put(downloadVod.success());
    } catch (err) {
        console.log(err);
        yield put(downloadVod.failure());
    }
}

function* clipVod(action: any) {
    const { startTime, endTime, link } = action.payload;
    try {
        const resp = yield call(clipVodRequest, startTime, endTime, link);

        if (resp.status === 200) {
            toastr.success("Success!", "We are preparing your request. A download link will be sent to you mailbox once the clip is available.", { timeOut: 10000 });
        } else {
            toastr.error("Error!", `${resp.data}`);
        }
        yield put(clipVodRoutine.success(resp));
    } catch (err) {
        console.log(err);
        yield put(clipVodRoutine.failure());
    }
}

export default function* vodSaga() {
    yield all([
        takeLatest(routines.TRIGGER, loadVodLink),
        takeLatest(downloadVod.TRIGGER, downloadVodStream),
        takeLatest(clipVodRoutine.TRIGGER, clipVod)
    ]);
}