import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addInternalStreamRoutine,
    updateInternalStreamRoutine
} from "../../../../redux/actions/internal-streams";
import {IInternalStream} from "../../../../common/types/internal-streams";
import {State} from "../../../../common/types/state.type";
import OutsideClickHandler from 'react-outside-click-handler';

import './index.scss';

interface AddStreamModalProps {
    onAddStream: () => void;
    streamToEdit: IInternalStream | null;
    setStreamToEdit: (stream: IInternalStream | null) => void;
    editMode: boolean
}

interface IStreamValues {
    name: string;
    multicastUri: string;
}

const AddStreamModal: React.FunctionComponent<AddStreamModalProps> = ({ onAddStream, streamToEdit, setStreamToEdit, editMode}) => {

    const initialValues = {
        name: editMode ? streamToEdit!.name : "",
        multicastUri: editMode ? streamToEdit!.multicastUri : ""
    }

    const [streamValues, setStreamValues] = useState<IStreamValues>(initialValues);
    const [errors, setErrors] = useState<IStreamValues>({
        name: "",
        multicastUri: ""
    });
    const user = useSelector((state: State) => state.user);
    const streams = useSelector((state: State) => state.internalStreams.source);
    const dispatch = useDispatch();

    const handleValueChange = (e) => {
        setStreamValues({
            ...streamValues,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    const validateForm = (values) => {
        const errors = {} as any;
        const uriRegex = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/

        const alreadyExists = streams.some(x => x.name.toLowerCase() === values.name.toLowerCase());

        if ((alreadyExists && streamToEdit !== null && streamToEdit!.name.toLowerCase() !== values.name.toLowerCase())
            || (alreadyExists && streamToEdit === null)) {
            errors.name = "Stream with the same name already exists";
        }

        if (values.name.length < 2) {
            errors.name = "Stream name must contain at least 2 characters";
        }
        if (!uriRegex.test(values.multicastUri)) {
            errors.multicastUri = "Invalid URI format";
        }
        return errors;
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const invalidFields = validateForm(streamValues);
        setErrors(invalidFields);

        if(invalidFields && Object.keys(invalidFields).length > 0) {
            return;
        }

        if (editMode) {
            handleCancelClick();
            dispatch(updateInternalStreamRoutine({
                ...streamToEdit,
                name: streamValues.name,
                multicastUri: streamValues.multicastUri
            }));
        } else {
            onAddStream();
            dispatch(addInternalStreamRoutine({
                ...streamValues,
                groupId: user!.groupId
            }));
        }
    }

    const handleCancelClick = () => {
        onAddStream();
        setStreamToEdit(null);
    }

    return (
        <div className="add-stream-modal__overlay">
            <OutsideClickHandler
                onOutsideClick={() => handleCancelClick()}
            >
                <div className="add-stream-modal">
                    <h1 className="add-stream-modal__title">
                        {
                            editMode ? streamToEdit!.name : "New Internal Stream"
                        }
                    </h1>
                    <form className="add-stream-modal__form" onSubmit={handleFormSubmit}>
                        <label>
                            Stream Name
                            <input
                                type="text"
                                name="name"
                                onChange={(e) => handleValueChange(e)}
                                value={streamValues.name}
                                required
                            />
                            <p className="add-stream-modal__error">{errors.name}</p>
                        </label>
                        <label>
                            Link
                            <input
                                type="text"
                                name="multicastUri"
                                onChange={(e) => handleValueChange(e)}
                                value={streamValues.multicastUri}
                                required
                            />
                            <p className="add-stream-modal__error">{errors.multicastUri}</p>
                        </label>
                        <div className="add-stream-modal__buttons">
                            <button className="add-stream-modal__btn add-stream-modal__cancel-btn" onClick={handleCancelClick}>Cancel</button>
                            <button className="add-stream-modal__btn add-stream-modal__add-btn" type="submit">
                                {
                                    editMode ? "Update" : "Add"
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </OutsideClickHandler>
        </div>
    )
}

export default AddStreamModal;



