import React from 'react'
import SiteHeader from '../../schedule/header/index';
import ChangePasswordForm from '../change-password-form';
import './index.scss';
import { Route } from 'react-router';
import ProfileInformation from '../profile-information';
import MenuBar from '../menubar/menubar';
import DeviceManagement from '../device-management';

export const ChangePasswordPage = (props: any) => {
    return (
        <div
            style={{ backgroundColor: '#1a1f25' }}>
            <div className={'header'}>
                <div className={'schedule'}>
                    <div className={'table-expanded change-password-expanded'}>
                        <SiteHeader isTableExpanded={true} />
                        <div className={`change-pwd-container`}>
                            <MenuBar />
                            <Route path="/profile/change-password" component={ChangePasswordForm} />
                            <Route path="/profile/information" component={ProfileInformation} />
                            <Route path="/profile/device-management" component={DeviceManagement} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
