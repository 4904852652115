import React from 'react';
import videojs, {VideoJsPlayer} from 'video.js';
import 'videojs-hlsjs-plugin';
import "video.js/dist/video-js.css";
import "videojs-seek-buttons/dist/videojs-seek-buttons.css";
import './index.scss';
import 'videojs-markers';
import 'videojs-seek-buttons';
import "videojs-flash";
import EventDropdown from '../events-dropdown/events-dropdown';
import {setUpControls} from './controls-setup';
import {PlayerProps} from "../types/player-props.type";
import StatusIcon from '../status-icon/status-icon';
import {PlayerStats} from '../../common/types/player-stats.type';
import {connect} from 'react-redux';
import uuid from 'uuid';
import SportEvent from '../../schedule/models/sport-event';
import ActionsSidebar from './actions-sidebar/actions-sidebar';
import EventsProgressBar from './events-progressbar/events-progressbar';
import {} from 'dotenv/config'
import {getHlsConfigRoutine} from '../../redux/actions/hls';
import {addCurrentlyWatchedEventRoutine, removeCurrentlyWatchedEventRoutine} from '../../redux/actions/runningball';
import RemoteControl from "../../schedule/remote-control";
import {ISkyBoxState} from "../../common/types/sky-boxes";
import {ILogModel} from "../../common/types/statistics.type";
import {getBrowserType} from "../../utils/helpers";
import {startLogRecord, stopLogRecord} from "../../services/logs.service";
import clip from "../../assets/clip.png";
import {Link} from "react-router-dom";
import VodClipper from "../../vod-clipper";

export interface VideoJSPlayerState {
    isBlank: boolean;
    playerStats: PlayerStats;
    isProgressBarEnabled: boolean;
    videoLength: number;
    isLive: boolean;
    isRTMP: boolean;
    remoteControlVisible: boolean;
    skyBox: ISkyBoxState | undefined;
    logId: number;
    vodClipperVisible: boolean;
    initialClippingTime: number;
}

class VideoJSPlayer extends React.Component<PlayerProps, VideoJSPlayerState> {

    private player!: VideoJsPlayer;
    private videoNode: HTMLVideoElement | null = null;
    private id: string = uuid();

    constructor(props: PlayerProps) {
        super(props);
        const {event, playerNumber, source} = props;

        this.state = {
            isLive: false,
            isBlank: false,
            playerStats: {
                id: this.id,
                type: this.getType(event.vodLink, source.indexOf('rtmp://') !== -1),
                sport: event.sport,
                eventId: event.eventId,
                information: event.information,
                homeTeam: event.competitor1,
                awayTeam: event.competitor2,
                playerNumber: playerNumber,
                competitions: event.competition,
                eventDateTime: event.startTime,
                downloadedSnippets: [],
                startWatchingTime: new Date()
            },
            videoLength: 0,
            isProgressBarEnabled: true,
            isRTMP: source.indexOf('rtmp://') !== -1,
            remoteControlVisible: false,
            skyBox: undefined,
            logId: 0,
            vodClipperVisible: false,
            initialClippingTime: 0
        }
    }

    componentDidMount() {
        const {
            source,
            event,
            playerNumber,
            isRealtime,
            isVod,
            isHd,
            sessionId
        } = this.props;

        const {isRTMP} = this.state;
        const {getHlsConfigRoutine} = this.props;

        if(getHlsConfigRoutine) {
            getHlsConfigRoutine(isRTMP ? 'RTMP' : 'HLS');
        }

        if(!isRTMP && event.rbGameId) {
            this.props.addCurrentlyWatchedEventRoutine!({
                userConnection: localStorage.getItem('connection') as string,
                eventId: event.rbGameId
            });
        }

        const streamUrl = !this.props.isHd ? source.replace('_720/playlist_dvr.m3u8', '_576/playlist_dvr.m3u8') : source;
        this.props.options.sources = [{src: streamUrl}];

        if(isRTMP) {
            this.props.options.sources = [{...this.props.options.sources.pop(), type: 'rtmp/mp4'}]
        }

        const opts = {
            ...this.props.options,
            controlBar: {
                children: {
                    playToggle: true,
                    muteToggle: true,
                    volumeControl: true,
                    fullscreenToggle: true
                }
            }
        }

        // @ts-ignore
        this.player = videojs(this.videoNode,
            !isRTMP ? this.props.options : opts,
            function (this: any) {
            });

        this.player.ready(() => {
            setUpControls(this.player, isRTMP);
        });

        this.player.on('loadedmetadata', () => {
            this.player.volume(0);
            this.setState({
                videoLength: Math.ceil(this.player.duration())
            })
            this.setState({
                isLive: (((this.player) as any).liveTracker.atLiveEdge())
            })
            if(this.props.isVod) {
                this.setState({
                    isLive: false
                })
            }
        });
        (this.player as any).liveTracker.on('liveedgechange', () => {
            if((this.player as any).liveTracker.atLiveEdge() && this.player.playbackRate() !== 1) {
                this.player.playbackRate(1);
            }
            this.setState({
                isLive: this.props.event.vodLink ? false : (((this.player) as any).liveTracker.atLiveEdge())
            })
        });

        // Replace default error message with desired

        videojs.addLanguage('en', {
            "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Techinical Issue: Please try to reload or select a different stream."
        });

        if (event!.eventId !== 0) {
            const log: ILogModel = {
                browserType: getBrowserType(),
                sessionId: sessionId,
                deviceUuid: localStorage.getItem('deviceUuid'),
                eventId: event.wtScheduledEventId,
                eventSport: event.sport,
                eventName: event.description || event.competition + (event.information && ' ' + event.information),
                eventContentProvider:event.service,
                playerNumber: playerNumber,
                streamType: isVod
                    ? isHd ? 'HD VOD' : 'SD VOD'
                    : isRealtime
                        ? isHd ? 'HD Live' : 'SD Live'
                        : isHd ? 'HD Review' : 'SD Review'
            }
            startLogRecord(log).then(response => this.setState({logId: response.data || response}));
        }

        this.setSkyBoxForPlayer();
    }

    componentWillUnmount() {
        const { event} = this.props;
        const {isRTMP, logId} = this.state;

        if(!isNaN(logId))
            stopLogRecord(logId);

        if(this.player) {
            this.player.dispose()
        }

        if(!isRTMP && event.rbGameId) {
            this.props.removeCurrentlyWatchedEventRoutine!({
                userConnection: localStorage.getItem('connection') as string,
                eventId: event.rbGameId
            });
        }
        this.setState({playerStats: {...this.state.playerStats, endWatchingTime: new Date()}});

        if(this.props.event.eventId !== 0) {

        }
    }

    componentWillReceiveProps(props: PlayerProps) {
        const {width, height, source, playerNumber, event} = props;
        const {isRTMP} = this.state;

        if(props.hlsConfig) {
            (videojs as any).Hls.GOAL_BUFFER_LENGTH = props.hlsConfig!.goalBufferLength;
            (videojs as any).Hls.MAX_GOAL_BUFFER_LENGTH = props.hlsConfig!.maxGoalBufferLength;
        }

        console.log(`GOAL_BUFFER_LENGTH = ${(videojs as any).Hls.GOAL_BUFFER_LENGTH}`);
        console.log(`MAX_GOAL_BUFFER_LENGTH = ${(videojs as any).Hls.MAX_GOAL_BUFFER_LENGTH}`);

        if(getHlsConfigRoutine) {
            getHlsConfigRoutine(isRTMP ? 'RTMP' : 'HLS');
        }

        this.props.options.isRtmp = isRTMP;
        if(!isRTMP) {
            this.player.width(width);
            this.player.height(height);
        } else {
            this.player.ready(function () {
                this.width(width);
                this.height(height);
            })
        }

        if(!this.props.isRealtime) {
            if(source !== this.props.source) {
                this.id = uuid();

                const playerStats = {
                    id: this.id,
                    type: this.getType(event.vodLink, isRTMP),
                    sport: event.sport,
                    latency: event.service,
                    eventId: event.eventId,
                    information: event.information,
                    homeTeam: event.competitor1,
                    awayTeam: event.competitor2,
                    playerNumber: playerNumber,
                    competitions: event.competition,
                    eventDateTime: event.startTime,
                    downloadedSnippets: [],
                    startWatchingTime: new Date()
                }

                this.setState({
                    playerStats: playerStats
                })

                this.player.src(source);
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<PlayerProps>, prevState: Readonly<VideoJSPlayerState>, snapshot?: any) {
        if(prevProps.sessionId != this.props.sessionId){
            if(!isNaN(this.state.logId))
                stopLogRecord(this.state.logId);

            const { event, playerNumber, isRealtime, isHd, sessionId } = this.props;
            if (event.eventId !== 0) {
                const log: ILogModel = {
                    browserType: getBrowserType(),
                    sessionId: sessionId,
                    deviceUuid: localStorage.getItem('deviceUuid'),
                    eventId: event.wtScheduledEventId,
                    eventSport: event.sport,
                    eventName: event.description || event.competition + (event.information && ' ' + event.information),
                    eventContentProvider:event.service,
                    playerNumber: playerNumber,
                    streamType: isRealtime
                        ? isHd ? 'HD Live' : 'SD Live'
                        : isHd ? 'HD Review' : 'SD Review'
                }
                startLogRecord(log).then(response => this.setState({logId: response.data || response}));
            }
        }
    }

    renderLiveIcon = () => {
        const {source} = this.props;
        const isRTMP = source.indexOf('rtmp://') !== -1;
        return (
            <div className="live-icon">
                <div className="icon-container">
                    <span className='dot' style={{backgroundColor: isRTMP ? 'red' : 'yellow'}}></span>
                    <span className="live-label">{isRTMP ? 'RTMP' : 'Live Review'}</span>
                </div>
            </div>
        )
    }

    setCurrentTime = (currentTime: number) => {
        this.player.currentTime(currentTime / 1000);
    }

    getType = (vodLink: string, isRTMP: boolean) => {
        if(vodLink !== null) {
            return 'vod';
        }
        if(isRTMP) {
            return 'rtmp';
        } else {
            return 'live/hls'
        }
    }

    handleDropdownEventSelected = (link: string, isRealtime: boolean, isRtmp: boolean, event: SportEvent) => {
        const {playerNumber} = this.props;
        const {playerStats, isRTMP} = this.state;

        this.id = uuid();

        const newPlayerDetails = {
            id: this.id,
            type: this.getType(event.vodLink, isRTMP),
            sport: event.sport,
            eventId: event.eventId,
            information: event.information,
            homeTeam: event.competitor1,
            awayTeam: event.competitor2,
            playerNumber: playerNumber,
            competitions: event.competition,
            eventDateTime: event.startTime,
            startWatchingTime: new Date(),
            downloadedSnippets: []
        }

        playerStats.endWatchingTime = new Date();

        this.setState({
            playerStats: newPlayerDetails
        })

        this.props.onChangePlayer(link, playerNumber, isRealtime, isRtmp, event);
    }

    onStreamClose = () => {
        this.props.onChangePlayer('', this.props.playerNumber, true, false)
    }

    openProgressBar = () => {
        this.setState({
            isProgressBarEnabled: !this.state.isProgressBarEnabled
        })
    }

    isRunningBall = () => {
        const {user, event, runningBallActions} = this.props;
        const condition = this.state.playerStats.type === 'live/hls'
            ? user!.restriction.isLiveEnhancedVodEnabled
            : user!.restriction.isRunningBallEnabled

        return condition &&
            event.sport === 'Soccer' &&
            runningBallActions &&
            runningBallActions.length
    }

    renderProgressBar = () => {
        const {isRTMP} = this.state;

        if(!this.props.user!.restriction.isRunningBallEnabled || isRTMP) {
            return;
        }

        return (<div>
            {
                this.state.isProgressBarEnabled &&
                <EventsProgressBar
                    event={this.props.event}
                    actions={this.props.runningBallActions}
                    setCurrentTime={this.setCurrentTime}
                    videoId={this.state.playerStats.id}
                    videoLength={this.state.videoLength}
                    isLive={this.state.playerStats.type === 'live/hls'}
                />
            }
            {
                this.isRunningBall() &&
                <div className='action-buttons'>
                    <button
                        onClick={this.openProgressBar}>
                        <i className="fas fa-eye"/>
                    </button>
                </div>
            }

        </div>)
    }

    setSkyBoxForPlayer = () => {
        const link = this.props.options.event.streamLink;
        const skyBox = this.props.skyBoxes.find(item => item.hlsStreamLink === link);
        this.setState({skyBox});
    }

    remoteControlToggle = () => {
        this.setState({remoteControlVisible: !this.state.remoteControlVisible});
    }

    showVodClipper = () => {
        this.setState({initialClippingTime: this.player.currentTime()});
        this.setState({vodClipperVisible: true});
        this.player.pause();
    }

    handleClipperClose = () => {
        this.setState({vodClipperVisible: false});
        this.player.play();
    }

    render() {
        const {width, height, openEvents, playerNumber, user, isPart, multicastModalToggle} = this.props;
        const {isBlank, isLive, isRTMP} = this.state;
        const condition = this.state.playerStats.type === 'live/hls'
            ? user!.restriction.isLiveEnhancedVodEnabled
            : user!.restriction.isRunningBallEnabled

        return (
            <>
                <div style={{maxWidth: isPart ? '50%' : '100%'}}>
                    <div data-vjs-player style={{height}}>
                        <StatusIcon isRealtime={false} height={height}/>
                        {
                            !this.props.isPlaySkyBoxStream &&
                            <EventDropdown
                                width={width}
                                height={height}
                                currentEventId={this.props.event.eventId}
                                events={openEvents || []}
                                onPlayerClose={() => this.props.onPlayerClose(playerNumber)}
                                onStreamClose={() => this.onStreamClose()}
                                onEventSelect={this.handleDropdownEventSelected}
                                playerNumber={playerNumber}
                                streamSettings={this.props.streamSettings}
                            />
                        }
                        {/*{*/}
                        {/*    ['vod', 'live/hls'].includes(this.state.playerStats.type) && condition && !isRTMP &&*/}
                        {/*    <ActionsSidebar*/}
                        {/*        event={this.props.event}*/}
                        {/*        setCurrentTime={this.setCurrentTime}*/}
                        {/*        videoId={this.state.playerStats.id}*/}
                        {/*        videoLength={this.state.videoLength}*/}
                        {/*        isLive={this.state.playerStats.type === 'live/hls'}/>*/}
                        {/*}*/}
                        {
                            !isBlank &&
                            <video
                                style={isLive ? {border: '3px solid red'} : {border: 'none'}}
                                disablePictureInPicture
                                ref={node => this.videoNode = node}
                                id={`video-js${playerNumber}`}
                                className="video-js"
                            />
                        }
                        {
                            isLive && this.renderLiveIcon()
                        }
                        {
                            this.renderProgressBar()
                        }
                        {
                            this.props.isVod &&
                            /*this.props.user!.restriction.isVodClippingEnabled &&*/
                            <button className="clip-button" type="button" onClick={this.showVodClipper}>
                                <img src={clip} alt="clip"/>
                            </button>
                        }
                        {
                            this.props.user
                            && this.props.user!.restriction.isSkyBoxesAllowed
                            && this.props.isPlaySkyBoxStream
                            && this.props.activeSkyBox && (this.props.activeSkyBox!.userEmail === this.props.user.email
                                || this.props.activeSkyBox!.lockType === 0)
                            &&
                            <div className="skybox-options-icons"
                                 style={this.props.streamSettings.length === 2 ? {bottom: "270px"} : {bottom: "60px"}}
                            >
                                <div className="multicast-icon" onClick={multicastModalToggle}></div>
                                <div className="remote-icon" onClick={this.remoteControlToggle.bind(this)}></div>
                            </div>
                        }
                        {
                            this.state.remoteControlVisible && this.props.isPlaySkyBoxStream &&
                            <RemoteControl
                                remoteControlToggle={this.remoteControlToggle.bind(this)}
                                activeBox={this.props.activeSkyBox}
                                playerNumber={playerNumber}
                                streamSettings={this.props.streamSettings}
                                skyBox={this.state.skyBox}
                            />
                        }
                    </div>
                </div>
                {
                    this.state.vodClipperVisible &&
                    <VodClipper
                        link={this.props.event.vodLink}
                        initialTime={Math.round(this.state.initialClippingTime)}
                        onClipperClose={this.handleClipperClose}
                    />
                }
            </>
        )
    }
}

export const mapStateToProps = (state: any) => {
    return {
        runningBallActions: state.runningBallActions,
        user: state.user,
        hlsConfig: state.hlsConfig,
        skyBoxes: state.skyBoxes.source,
        logs: state.logs,
        sessionId: state.sessionId
    }
}

export const mapDispatchToProps = {
    getHlsConfigRoutine,
    addCurrentlyWatchedEventRoutine,
    removeCurrentlyWatchedEventRoutine
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoJSPlayer);
