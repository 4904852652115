
import defaultState from "../../defaultState";
import { getStreamAliasesRoutine } from "../../actions/alises/actions";

export const aliasesReducer = (state = defaultState.streamAliases, action: any) => {
    switch (action.type) {
        case getStreamAliasesRoutine.SUCCESS:
            return action.payload
        default:
            return state;
    }
}