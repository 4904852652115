
import { createRoutine } from "redux-saga-routines";

export const getEventsTableRoutine = createRoutine('GET_EVENTS_TABLE')

export const getScheduleDueToJumpToLive = createRoutine('GET_EVENTS_TABLE_AND_JUMP_TO_LIVE');

export const getEventsForInfiniteScrollRoutine = createRoutine('GET_EVENTS_FOR_INFINITE_SCROLL');

export const getEventsForTopInfiniteScrollRoutine = createRoutine('GET_EVENTS_FOR_TOP_INFINITE_SCROLL_ROUTINE');

export const refreshEventsRoutine = createRoutine('REFRESH_EVENTS');


