import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import logo from "../../assets/login-logo.png";

import "./index.scss";

export interface ForgotPasswordProps {
    forgotPasswordHandler: (data: { email: string }) => void;
    hideModal: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordProps> = ({ forgotPasswordHandler, hideModal }) => {
    const [email, setEmail] = useState("")

    const sendEmail = (email: string) => {
        forgotPasswordHandler({
            email: email
        })
    }

    const handleChange = (event) => {
        setEmail(event.target.value)
    }

    return (
        <div className="forgot-password-modal">
            <OutsideClickHandler
                onOutsideClick={() => hideModal()}
            >
                <div className="forgot-password-modal__container">
                    <div className="forgot-password-modal__logo">
                        <img src={logo} alt="logo"/>
                    </div>
                    <h1 className="forgot-password-modal__title">
                        Password reset
                        <p>* * *</p>
                    </h1>
                    <form onSubmit={() => sendEmail(email)} action="" className="forgot-password-modal__form">
                        <input type="email"
                               className="reset-input"
                               onChange={handleChange}
                               placeholder="Enter your e-mail"
                               value={email}
                        />
                        <button type="submit" className="reset-button">Reset my password</button>
                    </form>
                </div>
            </OutsideClickHandler>
        </div>
    )
}

export default ForgotPasswordModal;

