import client, {handleError} from "../utils/client";
import env from "../env";
import {IInternalStream} from "../common/types/internal-streams";

export const getInternalStreamsRequest = async (): Promise<IInternalStream[]> => {
    try {
        const response = await client.get(`${env.apiUrl}/api/internal-streams`);
        return response.data || [];
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const addInternalStreamRequest = async (stream: IInternalStream): Promise<IInternalStream> => {
    try {
        const response = await client.post(`${env.apiUrl}/api/internal-streams`, stream);
        return response.data;
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const updateInternalStreamRequest = async (stream: IInternalStream): Promise<IInternalStream> => {
    try {
        const response = await client.put(`${env.apiUrl}/api/internal-streams`, stream);
        return response.data;
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const deleteInternalStreamRequest = async (id: string) => {
    try {
        return await client.delete(`${env.apiUrl}/api/internal-streams/${id}`);
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const sendInternalStreamToScreenRequest = async ({ internalStreamId, screenIds, locked}) => {
    try {
        const response = await client.put(`${env.apiUrl}/api/internal-streams/multicast`, {
            internalStreamId,
            screenIds,
            locked
        });
        return response.data;
    }
    catch (error) {
        return handleError(error) || [];
    }
}