import React, {useRef, useState} from "react";
import chevron from "../../assets/skybox-icons/chevron.png";
import {useSelector} from "react-redux";
import {State} from "../../common/types/state.type";
import StreamItem from "./children/stream-item";
import AddStreamModal from "./children/add-stream-modal";
import {IInternalStream} from "../../common/types/internal-streams";

import "./index.scss";

interface InternalStreamsPanelProps {
    isOpenInternalStreamsPanel: boolean;
    toggleInternalStreamsPanel: () => void;
    multicastModalToggle: () => void;
}

const InternalStreamsPanel: React.FunctionComponent<InternalStreamsPanelProps> = ({
    isOpenInternalStreamsPanel,
    toggleInternalStreamsPanel,
    multicastModalToggle,
}) => {

    const [addStreamModalVisible, setAddStreamModalVisible] = useState(false);
    const [streamToEdit, setStreamToEdit] = useState<IInternalStream | null>(null);

    const { source, isLoading } = useSelector((state: State) => state.internalStreams);
    const panelRef = useRef<HTMLDivElement | null>(null);

    const handlePanelClick = (e) => {
        toggleInternalStreamsPanel();
    }

    const handleAddStreamClick = () => {
        setAddStreamModalVisible(!addStreamModalVisible);
    }

    const handleEditClick = (stream: IInternalStream) => {
        setStreamToEdit(stream);
        handleAddStreamClick();
    }

    const sortStreams = (a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }

    const renderStreamElements = () => {
        return source.sort(sortStreams).map((item, index) =>
            <StreamItem
                multicastModalToggle={multicastModalToggle}
                onEdit={handleEditClick}
                stream={item}
                key={index}
            />
        )
    }

    return (
        <>
            <div onClick={(e) => handlePanelClick(e)} className="internal-streams">
                <div className="internal-streams-panel">
                    <div className="internal-streams-panel__btn">
                        <img
                            className={
                                isOpenInternalStreamsPanel
                                    ? "internal-streams-panel__btn-arrow-active"
                                    : "internal-streams-panel__btn-arrow"}
                            src={chevron}/>
                    </div>
                    <p style={{marginBottom: 0}}>INTERNAL STREAMS</p>
                </div>
            </div>
            {isOpenInternalStreamsPanel &&
            <div id="internalStreams" className="stream-items-panel" ref={panelRef}>
                {
                    isLoading
                        ?
                        <div className="stream-items-loader-overlay">
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        renderStreamElements()
                }
                <div className="stream-items-panel__add-stream" onClick={handleAddStreamClick}>
                    <span>Add Internal Stream</span>
                    <span>+</span>
                </div>
            </div>}
            {
                addStreamModalVisible &&
                <AddStreamModal
                    onAddStream={handleAddStreamClick}
                    streamToEdit={streamToEdit}
                    editMode={streamToEdit !== null}
                    setStreamToEdit={setStreamToEdit}
                />
            }
        </>
    )
}

export default InternalStreamsPanel;