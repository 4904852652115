import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import SportEvent from '../../schedule/models/sport-event';
import { toastr } from 'react-redux-toastr';
import './styles.scss';
import { User } from '../../common/types/user.type';
import { localTime } from '../../utils/time-converter.utils';
import moment from 'moment';

export interface IEventNotificationProps {
    events: {
        source: SportEvent[],
        isLoading: boolean
    };
    user: User;
}

export interface IEventNotificationState {
    previousEvents: SportEvent[];
    renders: number;
}

class EventNotification extends React.Component<IEventNotificationProps, IEventNotificationState> {
    checkInterval: any;

    constructor(props) {
        super(props);
        this.state = {
            previousEvents: [],
            renders: 0
        }
    }

    componentDidMount() {
        if(this.props.user.isNotificationsEnabled) {
            this.checkInterval = setInterval(this.handler, 10000)
        }
    }

    filterTodayCancelledEvents = (e, startTime) => {
        return e.cancelled 
            && moment(e.startTime) > moment(startTime).add(-3, 'h') 
                && moment(e.startTime) < moment(startTime).add(1, 'd').add(3, 'h');
    }

    componentWillReceiveProps(nextProps: any) {
        const { isNotificationsEnabled } = nextProps.user;
        const startTime = new Date().setHours(0, 0, 0, 0);

        if(!isNotificationsEnabled) {
            clearInterval(this.checkInterval);
        }

        if (!nextProps.events.isLoading && isNotificationsEnabled && this.state.renders === 0) {       
            this.setState({
                previousEvents: nextProps.events.source
                    .filter(e => this.filterTodayCancelledEvents(e, startTime)),
                renders: this.state.renders + 1
            });
            this.checkInterval = setInterval(this.handler, 10000)
        }
    }

    componentWillUnmount() {
        clearInterval(this.checkInterval);
    }

    handler = () => {
        const startTime = new Date().setHours(0, 0, 0, 0);
        const currentEvents = this.props.events.source.filter(e => this.filterTodayCancelledEvents(e, startTime))
        
        if (this.state && currentEvents.length > this.state.previousEvents.length) {
            const newEvents = currentEvents.filter(x => !this.state.previousEvents.includes(x));
            newEvents.forEach(newEvent => {
                const component = (
                    <div className="details-container">
                        <div>
                            <span>Startdate:  </span>
                            {localTime(newEvent.startTime).format('D/MM/YYYY HH:mm')}
                        </div>
                        <div>
                            <span>Sport:  </span>
                            {newEvent.sport}
                        </div>
                        <div>
                            <span>Competition:  </span>
                            <div className='country-icon-container'>
                                <Fragment>
                                    <img className='country-icon'
                                        alt={newEvent.countryCode}
                                        src={`./countryflags/${newEvent.countryCode}.gif`}
                                        style={{ marginRight: '5px', paddingBottom: '3px' }}
                                    />
                                    {newEvent.competition}
                                </Fragment>
                            </div>
                        </div>
                        <div>
                            <span>Information:  </span>
                            {newEvent.information}
                        </div>
                    </div>
                );

                const toastrOptions = {
                    timeOut: 10000,
                    component
                }
                
                toastr.message('Event cancelled', `${newEvent.competitor1} - ${newEvent.competitor2}`, toastrOptions)
            })
        }
        this.setState({
            previousEvents: currentEvents
        });
    }

    render() {
        return null;
    }

}

const mapStateToProps = (state: any) => {
    return {
        events: state.events,
        user: state.user
    }
}

export default connect(mapStateToProps)(EventNotification)