import React, {useEffect, useRef, useState} from "react";
import {ISkyBoxState} from "../../../common/types/sky-boxes";
import {IScreen} from "../../../common/types/screens";
import {useDispatch} from "react-redux";
import {updateScreenNameRoutine} from "../../../redux/actions/screens";
import {IInternalStream} from "../../../common/types/internal-streams";

import "./style.scss";

export interface IScreenProps {
    data: IScreen;
    inUseToggle: () => void;
    locked: boolean;
    activeSkyBox: ISkyBoxState;
    location: string;
    isNotAccessible: boolean;
    activeInternalStream: IInternalStream | null;
}

const Screen: React.FunctionComponent<IScreenProps> = ({ data, inUseToggle, location, isNotAccessible, activeInternalStream}) => {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [editMode, setEditMode] = useState(false);
    const [screenName, setScreenName] = useState(data.name);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const onEdit = () => {
        if (isNotAccessible) {
            return;
        }
        setEditMode(true);
    }

    const onNameSubmit = (e) => {
        e.preventDefault();
        dispatch(updateScreenNameRoutine({screenId: data.screenId, name: screenName, location}));
        setEditMode(false);
    }

    const handleOutsideClick = (event) => {
        if (inputRef.current !== null && !inputRef.current!.contains(event.target)) {
            setEditMode(false);
        }
    }

    const renderScreenName = () => {
        if (!editMode) {
            return <h1 className="screen__name" onClick={onEdit} >{data.name}</h1>
        } else {
            return <form onSubmit={onNameSubmit} >
                <input
                    className="screen__input"
                    name="screen-input"
                    type="text"
                    value={screenName}
                    onChange={(e) => setScreenName(e.target.value)}
                    ref={inputRef}
                />
            </form>
        }
    }

    return (
        data.locked ?
        <div className="screen in-use">
            {
                data.loading &&
                <div className="loader-overlay">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            {renderScreenName()}
            <p className="screen__id">id: {data.screenId}</p>
            <div className="screen__state-indicator">
                <span className="state-circle red"></span>
                <span className="state-text">{data.status}</span>
            </div>
            <div className="screen__box-info">
                <img src={process.env.PUBLIC_URL + 'service-icons/lock.svg'} alt="lock" className="locked-icon"/>
                <span className="box-number">
                    {
                        data.skyBoxId === null
                        ? data.internalStreamName
                        : `Box ${data.skyBoxId!.substring(data.skyBoxId!.indexOf("@") + 1)}`
                }
                </span>
            </div>
            <div className="in-use-button active" onClick={inUseToggle}>
                <div className="in-use-circle"></div>
            </div>
        </div>
            :
        <div className="screen">
            {
                data.loading &&
                <div className="loader-overlay">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            {renderScreenName()}
            <p className="screen__id">id: {data.screenId}</p>
            <div className="screen__state-indicator">
                <div className="state-circle"></div>
                <span className="state-text">{data.loading ? data.status : "Available"}</span>
            </div>
            <div className="in-use-button" onClick={inUseToggle}>
                <div className="in-use-circle"></div>
            </div>
        </div>
    )
}

export default Screen;