import React, {ChangeEvent, useState} from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { State } from '../../common/types/state.type';
import logo from "../../assets/login-logo.png";
import { resetPasswordRoutine } from "../../redux/actions/auth/actions";
import {history} from "../../router/history";

interface IResetPasswordModalProps {
    resetPassword: (newPassword: {password: string}) => void;
    error: string;
    apiVersion: string;
    resetPasswordModalToggle: (visible: boolean) => void;
}

const ResetPasswordModal: React.FC<IResetPasswordModalProps> = ({
  resetPassword,
  error,
  apiVersion,
  resetPasswordModalToggle
  }) => {

    const [password, setPassword] = useState<{ value: string, isValid: boolean}>({ value: '', isValid: false});
    const [repeatPassword, setrepeatPassword] = useState<{ value: string, isValid: boolean}>({ value: '', isValid: false});
    const [touchedPassword, setTouchedPassword] = useState(false);
    const [touchedRepeatPassword, setTouchedRepeatPassword] = useState(false);


    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/gm;
    const disableBtn = password.value !== repeatPassword.value || !password.isValid || !repeatPassword.isValid;

    const passChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const isValid = regex.exec(value) !== null;
        setPassword({ value, isValid }); 
    }

    const repeatPassChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const isValid = regex.exec(value) !== null;
        setrepeatPassword({ value, isValid });  
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        resetPassword({password: password.value});
        history.push("/login");
        resetPasswordModalToggle(false);
    }

    return (
        <div className="change-password-modal">
            <div className="change-password-modal__container">
                <div className="left-side">
                    <h1 className="left-side__title">
                        Reset credentials
                        <p>* * *</p>
                    </h1>
                    <p className="left-side__rule-text">Password must be at least 6 characters, no more than 12 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit.</p>
                    <form action="" className="left-side__form" onSubmit={handleSubmit}>
                        <div className="left-side__inputs">
                            <label htmlFor="password" className="left-side__label">Set new password:
                            <input onFocus={() => setTouchedPassword(true)}
                                   type="password"
                                   id="password"
                                   onChange={passChange}
                                   className="left-side__input new-password"/>
                            {
                                password.isValid
                                    ? <i className="fa fa-check" aria-hidden="true"></i>
                                    : <i className="fas fa-times"></i>
                            }
                            </label>
                            <label htmlFor="repeat-password" className="left-side__label">Repeat new password:
                            <input onFocus={() => setTouchedRepeatPassword(true)}
                                   type="password"
                                   id="repeat-password"
                                   onChange={repeatPassChange}
                                   className="left-side__input repeat-password"/>
                            {
                                repeatPassword.isValid && password.value === repeatPassword.value
                                    ? <i className="fa fa-check" aria-hidden="true"></i>
                                    : <i className="fas fa-times"></i>
                            }
                            </label>
                        </div>
                        {
                            disableBtn && (touchedPassword || touchedRepeatPassword) &&
                            <i className="left-side__warning">Please ensure password matches and adheres to rules</i>
                        }
                        <button
                            disabled={disableBtn}
                            type="submit"
                            className="left-side__confirm-button">
                            Save Changes
                        </button>
                    </form>
                </div>
                <div className="right-side">
                    <img src={logo} alt="logo" className="right-side__logo"/>
                    {
                        error ? <div className="right-side__error">{error}</div> : <></>
                    }
                    <p className="right-side__version">{apiVersion}</p>
                </div>
            </div>
        </div>
    )
}

export const mapStateToProps = (state: State, ownProps: any) => {
    return {
        shouldTriggerModal: state.login.shouldResetPassword,
        error: state.error,
        apiVersion: state.app.apiVersion,
    }
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
    return {
        resetPassword(data: {password: string}) {
            dispatch(resetPasswordRoutine(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);