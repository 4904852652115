import React from 'react';

const StatusIcon = (props: { height: number, isRealtime: boolean }) => {
    if(true) {
        return null;
    }
    return (
        <img
            alt="dropdown-status"
            style={{ bottom: props.height / 10, right: '10px' }}
            src={props.isRealtime ? './inplay-icons/running_man_white.png' : './inplay-icons/binoculars_white.png'}
            className='stream-status' />
    );
}

export default StatusIcon;