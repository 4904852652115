import React, {ChangeEvent} from 'react';
import OutsideClickhandler from 'react-outside-click-handler';
import "./styles.scss";

export interface MultiselectProps{
    onSelected: (values: string[]) => void;
    options: string[];
    selectedValues: string[];
    placeholder?: string;
    render?: (item: string) => JSX.Element;
}

export interface MultiselectState{
    isOpen: boolean;
    searchWord: string;
    optionsBySearch: string[];
}

class Multiselect extends React.Component<MultiselectProps, MultiselectState> {
    constructor(props: MultiselectProps) {
        super(props);
        this.state = {
            isOpen: false,
            searchWord: '',
            optionsBySearch: []
        }
    }

    defineLabel = (options: string[], selectedValues: string[]) => {
        const dropdownType = this.props.placeholder!.split(" ")[1];
        if (options.length === 0) {
            return 'There is no options';
        }

        if (selectedValues.length === 0) {
            return this.props.placeholder || 'Select values';
        }

        return `${dropdownType} selected: ${selectedValues.length}`;
    }

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

    onLiveSearchHandler = (word: string) => {
        const text: string = word && word[0].toUpperCase() + word.substring(1);
        this.setState({ searchWord: text }, () => {
            const optionsBySearchWord = this.props.options.filter((item) => item.includes(this.state.searchWord));
            this.setState({ optionsBySearch: optionsBySearchWord });
        }); 
    }

    render() {
        const { selectedValues, onSelected, render } = this.props;
        const { isOpen } = this.state;

        const options = this.state.searchWord.length ? this.state.optionsBySearch : this.props.options;
        const label = this.defineLabel(options, selectedValues);

        return (
            <div className='multiselect' data-multiselect>
                {
                    !isOpen
                    ? (<>
                        <i className="fas fa-chevron-down multiselect-arrow" onClick={this.toggleOpen} />
                        <input className="category-input" type="text" style={{cursor: "pointer"}} value={label} onChange={() => { }} onClick={this.toggleOpen} />
                    </>)
                    : (<OutsideClickhandler onOutsideClick={this.toggleOpen}>
                        {
                            isOpen &&
                                <>
                                    <i className="fas fa-search category-search-icon"></i>
                                    <input type="text"
                                           autoFocus
                                           className="category-input live-search"
                                           placeholder="Search in category"
                                           onChange={(event) => this.onLiveSearchHandler(event.target.value)}
                                           value={this.state.searchWord}
                                    />
                                </>
                        }
                        <i className="fas fa-chevron-up multiselect-arrow open" onClick={this.toggleOpen}></i>
                        <input className="category-input" type="text" value={label} onChange={() => { }} onClick={this.toggleOpen} />
                        <div className="multiselect-body">
                        {
                            options.map(
                                (x: string, i: number) => (selectedValues.includes(x) &&
                                    <div className="option" key={i}>
                                        <div className="inputGroup">
                                            <input
                                                type="checkbox"
                                                id={i.toString()}
                                                checked={selectedValues.includes(x)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    !e.target.checked 
                                                    && onSelected(selectedValues.filter((val: string) => val !== x))
                                                }}
                                            />
                                            <label htmlFor={i.toString()}>{render ? render(x) : x}</label>
                                        </div>
                                    </div>
                                )
                            )
                        }

                        {
                            options.map(
                                (x: string, i: number) => (!selectedValues.includes(x) &&
                                    <div className="option" key={ i + selectedValues.length}>
                                        <div className="inputGroup">
                                            <input
                                                type="checkbox"
                                                id={i.toString()}
                                                checked={selectedValues.includes(x)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    e.target.checked
                                                        ? onSelected([...selectedValues, x]) 
                                                        : onSelected(selectedValues.filter((val: string) => val !== x))
                                                }}
                                            />
                                            <label htmlFor={i.toString()}>{render ? render(x) : x}</label>
                                        </div>
                                    </div>
                                )
                            )
                        }
                        </div>
                    </OutsideClickhandler>)
                }
            </div>
        )
    }
}

export default Multiselect;


