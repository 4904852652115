import {HubConnection} from '@microsoft/signalr';
import {setIsConnected} from "./actions/is-connected";
import {addLiveAction} from "./actions/runningball/index";
import {closeSocket, connectSocket, sendSkyBoxSignal} from "./actions/sockets";
import {logoutOnDeletedDevice} from "./actions/devices";
import * as signalR from '@microsoft/signalr';
import env from '../env';
import {simulateSignalRoutine, updateSkyBoxRoutine} from './actions/skyboxes';
import {updateScreenRoutine} from "./actions/screens";
import {setSessionIdRoutine} from "./actions/session";

const connectWithToken = (): signalR.HubConnection => {
    return new signalR.HubConnectionBuilder()
        .withUrl(`${env.apiUrl}/api-hub`, {accessTokenFactory: () => localStorage.getItem('token')!})
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
}

const createSocketMiddleware = () => {
    let hubConnection: HubConnection;

    return (storeAPI) => (next) => (action) => {
        switch (action.type) {
            case connectSocket.TRIGGER: {
                hubConnection = connectWithToken();
                hubConnection.start()
                    .then(() => {
                        storeAPI.dispatch(setIsConnected.success())
                    })
                    .then(() => {
                            localStorage.setItem("connection", hubConnection.connectionId!)
                            storeAPI.dispatch(setSessionIdRoutine.trigger(hubConnection.connectionId))
                        }
                    ).catch(() => {
                    localStorage.clear();
                    window.location.href = `${window.location.origin}/login`;
                })

                hubConnection.onreconnected((connectionId) =>{
                    localStorage.setItem("connection", connectionId!)
                    storeAPI.dispatch(setSessionIdRoutine.trigger(connectionId))
                })

                hubConnection.on("rbEvent", (data) => {
                    storeAPI.dispatch(addLiveAction.trigger({data}));
                });

                hubConnection.on("logoutUser", (data) => {
                    storeAPI.dispatch(logoutOnDeletedDevice.trigger(data));
                });

                if(action.payload.restriction.isSkyBoxesAllowed) {
                    hubConnection.on("skyBoxEvent", (data) => {
                        storeAPI.dispatch(updateSkyBoxRoutine.trigger(data));
                    });

                    hubConnection.on("skyBoxScreenEvent", (data) => {
                        storeAPI.dispatch(updateScreenRoutine.trigger(data));
                    });
                }

                return next(action);
            }
            case closeSocket.TRIGGER: {
                hubConnection.stop();
                return next(action);
            }
            case sendSkyBoxSignal.TRIGGER: {
                const skyBoxId: string = action.payload.skyBox.skyBoxId;
                const parts = skyBoxId.split('@');
                const redRatId = parts[0];
                const outputs = parts[1];
                hubConnection.send('ReceiveSkyBoxSignal', {
                    redRatId,
                    outputs,
                    signal: action.payload.signal,
                    dataset: "Sky+"
                })
                    .then(() => {
                        storeAPI.dispatch(simulateSignalRoutine.success());
                    })
                    .catch(e => {
                        console.error(e)
                        storeAPI.dispatch(simulateSignalRoutine.failure(e.message));
                    });

                return next(action);
            }
            default: {
                return next(action)
            }
        }
    }
}

export {createSocketMiddleware}
