import { setError } from "../../actions/errors";
import defaultState from "../../defaultState";

export const errorHandlerReducer = (state = defaultState.error, action: any) => {
    switch (action.type) {
        case setError.TRIGGER:
            return action.payload;
        default:
            return state;
    }
}