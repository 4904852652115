import { getActionsForEventRoutine,
    removeActionsRoutine,
    getActionsForEventByInternalIdsRoutine,
    addLiveAction,
    getActionsForEventByRbId
} from "../../actions/runningball";
import { distinct } from "../../../utils/query.utils";
import { eventCodes } from "../../../utils/constants";
import { RunningBallAction } from "../../../common/types/running-ball-action.type";

const isTheSameEvent = (x: RunningBallAction, y: RunningBallAction) => {
    return x.eventNumber === y.eventNumber && x.gameId === y.gameId;
}

export const runningBallReducer = (state: any[] = [], action: any) => {
    switch (action.type) {
        case getActionsForEventRoutine.SUCCESS:
            return distinct(x => y => isTheSameEvent(x,y))
            ([...state, ...action.payload]);

        case getActionsForEventByInternalIdsRoutine.SUCCESS:
            return distinct(x => y => isTheSameEvent(x,y))
            ([...state, ...action.payload]);

        case getActionsForEventByRbId.SUCCESS:
            return distinct(x => y => isTheSameEvent(x,y))
            ([...state, ...action.payload]);

        case getActionsForEventByInternalIdsRoutine.FAILURE:
        case getActionsForEventRoutine.FAILURE:
            return state;
        case removeActionsRoutine.TRIGGER:
            return state;
        case addLiveAction.TRIGGER:
            if (eventCodes.CONFIRMED_EVENTS.includes(action.payload.data.eventCodeId)) {
                return state;
            }

            if (eventCodes.CANCELED_EVENTS.includes(action.payload.data.eventCodeId)) {
                let reversedArray = state.reverse();
                const item = reversedArray.find((each)=> each.eventCodeId === eventCodes.GOAL_HOME || each.eventCodeId === eventCodes.GOAL_AWAY)
                return state.filter(each => `${each.gameId}${each.eventNumber}` !== `${item.gameId}${item.eventNumber}`)
            }

            return [
                ...state,
                action.payload.data
            ]
        default:
            return state;
    }
}
