import React from "react";

import "./index.scss";
import {useDispatch} from "react-redux";
import {askConfirmationRoutine, setResetInitPassword} from "../../redux/actions/auth/actions";

export interface ISendConfirmationWindowProps {
    askConfirmation: boolean;
    email: string;
}

const SendConfirmationWindow: React.FC<ISendConfirmationWindowProps> = ({ askConfirmation, email}) => {
    const dispatch = useDispatch();
    const position = askConfirmation ? "visible" : "hidden";

    const onYesClick = () => {
        dispatch(askConfirmationRoutine.failure());
        dispatch(setResetInitPassword({email}));
    }

    const onNoClick = () => {
        dispatch(askConfirmationRoutine.failure());
    }

    return (
        <div className={`confirmation-window ${position}`}>
            <div className="confirmation-window__top"></div>
            <p className="confirmation-window__question">Your account is not confirmed yet!<br/>Send another confirmation email?</p>
            <div className="confirmation-window__buttons">
                <button className="confirmation-window__button button-left" onClick={onYesClick}>Yes</button>
                <button className="confirmation-window__button button-right" onClick={onNoClick}>No</button>
            </div>
        </div>
    )
}

export default SendConfirmationWindow;
