import client from "../utils/client"
import env from "../env"

export const getActionsForEvent = (competitor1: string, competitor2: string, time: Date) => {
    return client.get(`${env.apiUrl}/api/vod/data?homeTeam=${competitor1}&awayTeam=${competitor2}&date=${time}`);
};

export const getActionsForEventByInternalIds = (homeTeamId: number, awayTeamId: number, time: Date) => {
    return client.get(`${env.apiUrl}/api/vod/dataById?homeTeamId=${homeTeamId}&awayTeamId=${awayTeamId}&date=${time}`);
}

export const getActionsForEventByRdId = (rbId: number) => {
    return client.get(`${env.apiUrl}/api/vod/dataByMatchId?matchId=${rbId}`)
}

export const addCurrenltyWatchedEventForRb = (userConnection: string, eventId: number) => {
    return client.post(`${env.apiUrl}/api/eventssubscription`, {
        userConnection,
        eventId
    })
}

export const removeCurrenltyWatchedEventForRb = (userConnection: string, eventId: number) => {
    return client.post(`${env.apiUrl}/api/eventssubscription/remove`, {
        userConnection,
        eventId
    })
}