import videojs, { VideoJsPlayer } from 'video.js'

export const setUpControls = (player: VideoJsPlayer | any , isRtmp: boolean) => {
    
    const frameStep = 1 / 24;
    
    const times = [2, 7, 30];

    if (!isRtmp) {
        [...times].reverse().forEach((t: number) => {
            player.seekButtons({
                forward: t
            });
        })
        times.forEach((t: number) => {
            player.seekButtons({
                back: t
            });
        })
    }

    const CommonButton = videojs.getComponent('Button');
    class RewindButton extends CommonButton {

        isRewind: boolean = false;
        interval: any;

        constructor(player: VideoJsPlayer, options: any) {
            super(player, options);
            this.controlText("Rewind")
        }

        createEl(tag = 'button', props = {}, attributes = {}) {
            let el = super.createEl(tag, props, attributes);
            el.innerHTML = ''
            return el;
        }

        handleClick() {
            const reset = () => {
                clearInterval(this.interval);
                this.isRewind = false;
                player.play();
            }

            if(this.isRewind) {
                return reset();
            }

            player.pause();

            const rewind = () => {
                if(!player.player_) {
                    return reset.bind(this);
                }
                const time = player.currentTime() - frameStep;
                player.currentTime(time);
            }

            this.interval = setInterval(rewind, 1000 / 24);
            this.isRewind = true;
        }

        buildCSSClass() {
            return `vjs-control vjs-button fas fa-angle-double-left`;
        }
        
    }
    
    class RewindFrameButton extends CommonButton {

        constructor(player: any, options: any) {
            super(player, options);
            this.controlText("Rewind frame")
        }

        createEl(tag = 'button', props = {}, attributes = {}) {
            let el = super.createEl(tag, props, attributes);
            el.innerHTML = ''
            return el;
        }

        handleClick() {
            const time = player.currentTime() - frameStep;
            player.currentTime(time);
        }

        buildCSSClass() {
            return `vjs-control vjs-button fas fa-chevron-left`;
        }
        
    }

    class ForwardFrameButton extends CommonButton {

        constructor(player: any, options: any) {
            super(player, options);
            this.controlText("Forward frame")
        }

        createEl(tag = 'button', props = {}, attributes = {}) {
            let el = super.createEl(tag, props, attributes);
            el.innerHTML = ''
            return el;
        }

        handleClick() {
            const time = player.currentTime() + frameStep;
            player.currentTime(time);
        }

        buildCSSClass() {
            return `vjs-control vjs-button fas fa-chevron-right`;
        }
        
    }

    if (!isRtmp) {
        (CommonButton as any).registerComponent('RewindButton', RewindButton);
        (CommonButton as any).registerComponent('RewindFrameButton', RewindFrameButton);
        (CommonButton as any).registerComponent('ForwardFrameButton', ForwardFrameButton);

        player.controlBar.rewindButton = player.controlBar.addChild(new RewindButton(player, {}));
        player.controlBar.rewindFrameButton = player.controlBar.addChild(new RewindFrameButton(player, {}));
        player.controlBar.forwardFrameButton = player.controlBar.addChild(new ForwardFrameButton(player, {}));

        ['forwardFrameButton', 'rewindFrameButton', 'rewindButton']
            .forEach(item => {
                player.controlBar
                .el()
                .insertBefore(
                    player.controlBar[item].el(),
                    player.controlBar.el().firstChild.nextSibling);
            })
        }
};