import React, { Fragment } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { State } from "../common/types/state.type";
import { Spinner } from "react-bootstrap";
import { LoginData } from "../common/types/login-data.type";
import {
    loginSubmit,
    reset,
    setResetInitPassword,
    fetchApiVersionRoutine
} from "../redux/actions/auth/actions";
import ResetPasswordModal from "./reset-password-modal";
import * as Fingerprint2 from 'fingerprintjs2';
import ForgotPasswordModal from "./forgot-password-modal";
import env from '../env'
import {getDeviceType, getOperatingSystem, secureOrigin} from "../utils/helpers";
import { setErrorToState } from '../services/error.service';
import {User} from "../common/types/user.type";
import SendConfirmationWindow from "./send-confirmation-window";

export interface ILoginState {
    email: string,
    password: string,
    resetPwd: boolean;
    apiVersion: string;
    resetPasswordModalVisible: boolean;
    azureHelper: string;
    inputFontSize: {fontSize: string}
}

export interface ILoginProps {
    error: string;
    user: User,
    apiVersion: string,
    fetchApiVersion,
    lastLocation: string;
    handleLoginSubmit: (data: { email: string, password: string, deviceUuid: string }) => void;
    initResetIfForgotten: (data: { email: string }) => void;
    loginLoading: boolean;
    askConfirmation: boolean;
}

class LoginPage extends React.Component<ILoginProps, ILoginState> {
    checkInterval!: NodeJS.Timeout;
    azureUserHintTimeout!: NodeJS.Timeout;

    constructor(props: any) {
        super(props);
        this.state = {
            email: '',
            password: '',
            resetPwd: false,
            apiVersion: '',
            resetPasswordModalVisible: false,
            azureHelper: 'hidden',
            inputFontSize: {fontSize: "15px"}
        }
    }

    gatherBrowserInfo = () => {
        //remove browser dependent properties
        const options = {
            excludes: {
                userAgent: true,
                language: true,
                sessionStorage: true,
                localStorage: true,
                indexedDb: true,
                addBehavior: true,
                openDatabase: true,
                doNotTrack: true,
                plugins: true,
                canvas: true,
                webgl: true,
                adBlock: true,
                fonts: true,
                audio: true,
                enumerateDevices: true,
                webdriver: true,
                deviceMemory: true,
                pixelRatio: true,
                screenResolution: true,
                availableScreenResolution: true,
                touchSupport: true,
                hasLiedLanguages: true,
                hasLiedOs: true,
                hardwareConcurrency: true
            }
        }

        Fingerprint2.get(options, (components) => {
            const gpuIndex = components.findIndex(x => x.key === 'webglVendorAndRenderer')
            const parts = components[gpuIndex].value.split('~');

            if (parts.length > 1) {
                components[gpuIndex].value = parts[1];
            }

            const values = components.filter(x =>
                x.key !== 'webdriver' || x.key !== 'deviceMemory').map(component => component.value);
            // console.log(components);
            const hash = Fingerprint2.x64hash128(values.join(''), 31);

            localStorage.setItem('deviceUuid', hash);
        })
    }

    setCookie = function (name: String, value: String, days: number) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    getCookie = function (name) {
        var cookieValue = '';
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    tryAutoLogin = () => {
        this.tryToRetrieveTokensFromQuery();
        if (localStorage.getItem('token')) {
            clearInterval(this.checkInterval);
            //history.push('/schedule');
            this.redirectDueToQueryParam();
        }
    }

    azureLogin = () => {
        const hash = localStorage.getItem('deviceUuid')!;
        let redirectUrl = document.location.href;
        if (redirectUrl.indexOf('?') > 0) {
            redirectUrl = redirectUrl.split('?')[0];
        }
        document.location.replace(env.apiUrl + '/azure/login?deviceUuid=' + hash + '&os=' + getOperatingSystem() + '&deviceType=' + getDeviceType() + '&redirectUrl=' + redirectUrl);
    }

    tryToRetrieveTokensFromQuery = () => {
        var refreshToken = new URLSearchParams(window.location.search).get('refreshToken');
        var accessToken = new URLSearchParams(window.location.search).get('accessToken');
        if (!localStorage.getItem('token')) {
            if (refreshToken) {
                localStorage.setItem('refreshToken', refreshToken);
            }

            if (accessToken) {
                localStorage.setItem('token', accessToken);
            }
        }
    }

    login = () => {
        const {email, password} = this.state;
        localStorage.removeItem("confirmationToken");
        let hash = localStorage.getItem('deviceUuid')!;
        if(!hash){
            this.gatherBrowserInfo();
            hash = localStorage.getItem('deviceUuid')!;
        }

        this.setCookie('deviceUuid', hash, 1);
        this.props.handleLoginSubmit({email, password, deviceUuid: hash});
    }

    componentDidMount() {
        this.gatherBrowserInfo();
        this.tryToRetrieveTokensFromQuery();
        this.setInputFontSize(this.state.email);

        const resetPasswordTrigger = new URLSearchParams(window.location.search).get("change");
        this.resetPasswordModalToggle(Boolean(resetPasswordTrigger));

        const confirmationToken = new URLSearchParams(window.location.search).get("ct");

        if (confirmationToken !== null) {
            localStorage.setItem("confirmationToken", confirmationToken);
        }

        const error = new URLSearchParams(window.location.search).get('errorMessage');
        if(error){
            window.history.pushState({}, document.title, window.location.pathname);
            setErrorToState(error);
        }
        this.checkInterval = setInterval(this.tryAutoLogin, 1000);

        if (this.props.user && localStorage.getItem('token') && secureOrigin()) {
            this.redirectDueToQueryParam();
        }
        // this.props.fetchApiVersion();
    }

    redirectDueToQueryParam = () => {
        const initPath = new URLSearchParams(window.location.search).get("redirectUrl");
        document.location.replace((initPath && initPath !== '') ? initPath : '/schedule');
    }

    getParameterByName = function (name: string): string {
        var url = window.location.href
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return '';
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.user && localStorage.getItem('token')) {
            this.redirectDueToQueryParam();
        }
    }

    emailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({email: event.target.value});
        this.setInputFontSize(event.target.value);
    }

    passChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({password: event.target.value});
        this.setInputFontSize(event.target.value);
    }

    onEnter = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            localStorage.removeItem("confirmationToken");
            event.preventDefault();
            event.stopPropagation();
            if (!(this.state.email && this.state.password)) {
                return;
            }
            this.login();
        }
    }

    setInputFontSize = (value) => {
        if (value.length < 16) {
            this.setState({inputFontSize: {fontSize: "15px"}});
        } else if (value.length >= 16 && value.length <= 22) {
            this.setState({inputFontSize: {fontSize: "12px"}});
        } else {
            this.setState({inputFontSize: {fontSize: "10px"}});
        }
    }

    forgotPasswordHandler = (data: { email: string }) => {
        this.props.initResetIfForgotten(data);
        this.setState({
            resetPwd: !this.state.resetPwd
        })
    }

    resetPasswordModalToggle = (visible: boolean) => {
        this.setState({
            resetPasswordModalVisible: visible
        })
    }

    infoHoverHandler = () => {
        clearTimeout(this.azureUserHintTimeout);
        this.setState({
            azureHelper: "visible"
        })

        this.azureUserHintTimeout = setTimeout(() => {
            this.setState({
                azureHelper: "hidden"
            })
        }, 5000)
    }

    render() {
        let error: string | null = this.props.error;
        const {email, password} = this.state;

        const pointerEventsStyle: React.CSSProperties = this.state.resetPasswordModalVisible
            ? {pointerEvents: 'none'}
            : {pointerEvents: 'auto'};

        return !this.props.user && !localStorage.getItem('token') ? (
                <Fragment>
                    {this.state.resetPasswordModalVisible &&
                        <ResetPasswordModal
                            resetPasswordModalToggle={this.resetPasswordModalToggle}
                        />
                    }
                    {this.state.resetPwd &&
                        <ForgotPasswordModal
                            hideModal={() => this.setState({
                                resetPwd: !this.state.resetPwd
                            })}
                            forgotPasswordHandler={this.forgotPasswordHandler}
                        />
                    }
                    <div className={`container ${this.state.resetPwd && "blurred"}`} onKeyDown={this.onEnter}>
                        <SendConfirmationWindow
                            askConfirmation={this.props.askConfirmation}
                            email={this.state.email}
                        />
                        <div className="login-form">
                            <div className="left">
                                <div className="inputs">
                                    <input type="email"
                                           onChange={this.emailChange}
                                           placeholder='Email'
                                           className="input login"
                                           style={this.state.inputFontSize}
                                    />
                                    <input type="password"
                                           onChange={this.passChange}
                                           placeholder='Password'
                                           className="input login"
                                           style={this.state.inputFontSize}
                                    />
                                </div>
                                <button
                                    type='submit'
                                    onClick={this.login}
                                    disabled={!email || !password}
                                    style={{cursor: 'pointer'}}
                                    className="login-btn">{this.props.loginLoading ? "Signing in..." : "Sign in"}
                                </button>
                                <button className="azure-login-btn" onClick={this.azureLogin} style={pointerEventsStyle}>
                                    <img src="./service-icons/ms-pictogram.svg"/>
                                    Sign in with Microsoft
                                    <div className="azure-info-icon" onMouseEnter={this.infoHoverHandler}> i
                                    </div>
                                </button>
                                <div className={`azure-help-bubble ${this.state.azureHelper}`}
                                     style={pointerEventsStyle}
                                     onMouseEnter={this.infoHoverHandler}>
                                    Contact us to configure access - <br/>
                                    <a href="mailto:support@igamemedia.com">support@igamemedia.com</a>
                                </div>
                                <button
                                    type='submit'
                                    onClick={() => this.setState({
                                        resetPwd: !this.state.resetPwd
                                    })}
                                    className="reset-pwd-btn">
                                    Reset password
                                </button>
                            </div>
                            <div className="right">
                                <div className="logo"></div>
                                {
                                    error ? <div className="error">{error}</div> : <></>
                                }
                                {/*<div className="version">{this.props.apiVersion}</div>*/}
                            </div>
                        </div>
                    </div>
                </Fragment>
            ) :
            (<div className='container'>
                <Spinner animation='border'/>
            </div>);
    }
}

export const mapStateToProps = (state: State) => {
    return {
        error: state.error,
        user: state.user,
        apiVersion: state.app.apiVersion,
        loginLoading: state.login.loading,
        askConfirmation: state.login.askConfirmation
    }
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
    return {
        handleLoginSubmit(userData: LoginData) {
            dispatch(loginSubmit(userData));
        },
        reset() {
            dispatch(reset());
        },
        initResetIfForgotten(data: { email: string }) {
            dispatch(setResetInitPassword(data));
        },
        fetchApiVersion() {
            dispatch(fetchApiVersionRoutine.trigger())
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
