import client, { handleError } from '../utils/client';
import { LoginData } from '../common/types/login-data.type';
import { User } from '../common/types/user.type';
import env from '../env'
import { ChangePasswordData } from '../common/types/reset-password-data.type';
import { getDeviceType, getOperatingSystem } from "../utils/helpers";
import * as publicIp from 'public-ip';

export const Login = async (data: LoginData): Promise<User | null | { askConfirmation: boolean }> => {
    try {
        let response;

        if (env.envType === "Local") {
            const options  = {
                headers: {
                    "X-Forwarded-For": (await publicIp.v4()).toString()
                }
            };
            response = await client.post(`${env.apiUrl}/api/auth/login`, {
                ...data,
                OS: getOperatingSystem(),
                type: getDeviceType()
            }, options);
        }
        else {
            response = await client.post(`${env.apiUrl}/api/auth/login`, {
                ...data,
                OS: getOperatingSystem(),
                type: getDeviceType()
            });
        }
        if (response.data.confirmed) {
            localStorage.setItem('token', response.data.accessToken)
            localStorage.setItem('refreshToken', response.data.refreshToken)
        }
        return response;
    }
    catch (error) {
        return handleError(error);
    }
}
function delete_cookie (name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const changePassword = async (data: ChangePasswordData) => {
    try {
        const response = await client.put(`${env.apiUrl}/api/users/change_password`, data);
        return response.data;
    }
    catch (error) {
        return handleError(error);
    }
}

export const initPasswordResetProcedure = async (data: {email: string}) => {
    try {
        const response = await client.put(`${env.apiUrl}/api/users/initiate/reset_password`, data);
        return response.data;
    }
    catch (error) {
        return handleError(error);
    }
}

export const resetPassword = async (data: {password: string}) => {
    try {
        const response = await client.put(`${env.apiUrl}/api/users/reset_password`, data);
        return response.data;
    }
    catch (error) {
        return handleError(error);
    }
}

export const GetCurrentUser = async () => {
    try {
        const response = await client.get(`${env.apiUrl}/api/users/current`);
        return response.data;
    }
    catch (error) {
        return handleError(error);
    }
}

export const switchNotifications = async (isEnabled: boolean) => {
    try {
        await client.put(`${env.apiUrl}/api/users/notifications`, isEnabled)
        return true;
    }
    catch (error) {
        return handleError(error);
    }
}

export const fetchApiVersion = async (): Promise<string> => {
    const response = await client.get(`${env.apiUrl}/api/version`);
    return response.data;
}

export const removeToken = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');

    delete_cookie('AccessToken');
    delete_cookie('RefreshToken');
}
