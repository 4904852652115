export const distinct = (cb: any) => (array: any) => array.filter((x: any,i: any) => array.findIndex(cb(x)) === i);
// x => y => x.item === y.item

export const groupBy = (list: any, keyGetter: any) => {
    const map = new Map();
    list.forEach((item: any) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
// x => x.item
