import React from "react";
import './index.scss';
import FullScreenDomElement from "../../interfaces/fullscreen-dom-element.interface";

export interface ExpandButtonProps {
    isExpanded: boolean;
    toggleSchedule: () => void;
    isFullscreen: boolean;
    isFullscreenToggle: (isFull: boolean) => void;
}

class ExpandButton extends React.Component<ExpandButtonProps> {

    componentDidMount() {
        this.setEventsOnFullscreenChange();
    }

    render() {
        const onMouseOver = () => {
            if (this.props.isExpanded) {
                return;
            }
            document.getElementById('header')!.style.opacity = '1';
        };

        const onMouseOut = () => {
            if (this.props.isExpanded) {
                return;
            }
            document.getElementById('header')!.style.opacity = '0';
        };

        return (
            <button
                className={`toggle-btn${this.props.isExpanded ? '-expanded' : ''} 
                ${!this.props.isFullscreen && !this.props.isExpanded ? 'toggle-btn-hover' : ''}`}
                onClick={this.props.toggleSchedule}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}>
                {
                    this.props.isExpanded
                        ? <i className="fas fa-arrow-up"></i>
                        : (
                            <div className="expand-with-toggle">
                                <i className="fas fa-expand" onClick={this.openFullscreen}></i>
                                <i className="fas fa-arrow-down"></i>
                            </div>
                        )
                }
            </button>
        )
    }

    openFullscreen = ($event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        $event.stopPropagation();
        this.props.isFullscreenToggle(true);
        const root = document.getElementById('root') as FullScreenDomElement;
        root.requestFullscreen && root.requestFullscreen();
        root.mozRequestFullScreen && root.mozRequestFullScreen();
        root.webkitRequestFullscreen && root.webkitRequestFullscreen();
        root.msRequestFullscreen && root.msRequestFullscreen();
    }

    setEventsOnFullscreenChange() {
        const handler = () => {
            const doc = document as any;
            const fullscreenElement = doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement;
            if (!fullscreenElement) {
                this.props.isFullscreenToggle(false);
            }
        }
        document.addEventListener("fullscreenchange", handler, false);
        document.addEventListener("webkitfullscreenchange", handler, false);
        document.addEventListener("mozfullscreenchange", handler, false);
    }
}

export default ExpandButton;