import { getLiveStreams } from '../../actions/live-streams';
import { call, put, all, takeLatest } from "redux-saga/effects";
import { getLiveStreams as ApiCall } from '../../../services/api.service';

function* getLiveStreamsAction(action: any) {
    try {
        const liveStreams = yield call(ApiCall);
        yield put(getLiveStreams.success(liveStreams));
    } catch (err) {
        yield put(getLiveStreams.failure(err.message))
    }
}

export default function* liveStreamsSaga() {
    yield all([
        takeLatest(getLiveStreams, getLiveStreamsAction),
    ]);
}