export interface ScheduleFilters {
    searchDate: Date,
    searchWord: string,

    onlyNew: boolean;
    showVOD: boolean;
    showLive: boolean;

    sportsCriteria : string[]
    countriesCriteria : string[]
    servicesCriteria : string[]
}

export const defaultScheduleFilters: ScheduleFilters = {
    searchDate: new Date(),
    searchWord: '',

    onlyNew: false,
    showVOD: false,
    showLive: false,

    sportsCriteria :[],
    countriesCriteria :[],
    servicesCriteria: []
}
