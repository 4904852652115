import defaultState from "../../defaultState";
import {
    getSkyBoxListRoutine,
    lockSkyBoxRoutine,
    updateSkyBoxRoutine
} from "../../actions/skyboxes";
import {ISkyBoxState} from "../../../common/types/sky-boxes";

const skyBoxesReducer = (state = defaultState.skyBoxes, action: any) => {
    switch (action.type) {
        case updateSkyBoxRoutine.SUCCESS:
            const devices = state.source.map(element => {
                const updateForBox = action.payload.find(x => x.skyBoxId === element.skyBoxId);
                if (updateForBox) {
                    return {
                        ...element,
                        userId: updateForBox.userId,
                        userName: updateForBox.userEmail ? updateForBox.userEmail.substring(0, updateForBox.userEmail.indexOf("@")) : "",
                        userEmail: updateForBox.userEmail,
                        lockType: updateForBox.lockType,
                        lockedAt: updateForBox.lockedAt,
                        loading: false
                    }
                } else {
                    return element;
                }
            })
            return {...state, source: devices, isLoading: false}


        case lockSkyBoxRoutine.TRIGGER:
            const skyBoxDevice = state.source.map(item => {
                if (item.skyBoxId === action.payload.skyBoxId) {
                    return {
                        ...item,
                        loading: true
                    }
                }
                return item;
            })
            return {...state, source: skyBoxDevice}


        case lockSkyBoxRoutine.SUCCESS:
            const skyBoxes = state.source.map(item => {
                const {id, username} = action.payload.currentUser;
                if (item.skyBoxId === action.payload.skyBoxId) {
                    return {
                        ...item,
                        userId: id,
                        userName: username.substring(0, username.indexOf("@")),
                        userEmail: username,
                        lockType: action.payload.lockType,
                        lockedAt: action.payload.lockType === 0 ? "" : new Date(),
                        loading: false
                    }
                }
                return item;
            })
            return {...state, source: skyBoxes, isLoading: false}


        case getSkyBoxListRoutine.TRIGGER:
            return {...state, isLoading: true}


        case getSkyBoxListRoutine.SUCCESS:
            const boxes: ISkyBoxState[] = Array.from({length: 9}, (element, index) => {
                const box = action.payload.find((item, i) => index === i);
                if (box) {
                    const output = +box.skyBoxId.substring(box.skyBoxId.indexOf("@") + 1)
                    return {
                        output: output,
                        skyBoxId: box.skyBoxId,
                        userId: box.userId,
                        userName: box.userEmail ? box.userEmail.substring(0, box.userEmail.indexOf("@")) : "",
                        userEmail: box.userEmail,
                        lockType: box.lockType,
                        lockedAt: box.lockedAt,
                        loading: false,
                        wssStreamLink: `wss://nmms1.inplayip.tv/inplay/sportingsolutions_${output}?wmsAuthSign=${box.token}`,
                        hlsStreamLink: `https://review.inplayip.tv/inplay_vod/sportingsolutions_${output}_720/chunks_dvr.m3u8`
                    }
                } else {
                    return {
                        output: 0,
                        skyBoxId: "",
                        userId: 0,
                        userName: "",
                        userEmail: "",
                        lockType: 0,
                        lockedAt: "",
                        loading: false,
                        wssStreamLink: "",
                        hlsStreamLink: ""
                    }
                }
            }).sort((x:ISkyBoxState, y:ISkyBoxState) => x.output - y.output);
            return {...state, source: boxes, isLoading: false}

        default:
            return state;
    }
}

export default skyBoxesReducer;
