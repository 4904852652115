import { getStreamAliases } from "../../../services/api.service";
import { call, put, all, takeLatest } from "redux-saga/effects";
import { getStreamAliasesRoutine } from "../../actions/alises/actions";

function* getStreamSettings(action: any) {
    try {
        let aliases: string[] = yield call(getStreamAliases);

        yield put(getStreamAliasesRoutine.success(aliases));
    } catch (err) {
        yield put(getStreamAliasesRoutine.failure(err.message));
    }
}

export default function* streamAliasesSaga() {
    yield all([
        takeLatest(getStreamAliasesRoutine, getStreamSettings),
    ]);
}