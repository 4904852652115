import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import chevron from '../../assets/skybox-icons/chevron.png';
import SkyBox from "./children/sky-box/SkyBox";
import {useSelector} from "react-redux";
import {State} from "../../common/types/state.type";

import './style.scss';
import StreamSetting from "../types/stream-setting.type";
import SportEvent from "../models/sport-event";

interface SkyBoxesProps {
    isOpenBoxesPanel: boolean;
    setIsOpenBoxesPanel: () => void;
    multicastModalToggle: () => void;
    setActiveBox: (box: any) => void;
    resizeBoxPanelHandler: (boxPanelRef) => void;
    streamSettings: StreamSetting[];
    selectStream: (link: string, playerNumber: number, isRealtime: boolean, isRtmp: boolean, event: SportEvent) => void;
}

const SkyBoxes: FunctionComponent<SkyBoxesProps> = ({
    isOpenBoxesPanel,
    setIsOpenBoxesPanel,
    multicastModalToggle,
    setActiveBox,
    streamSettings,
    selectStream,
}) => {

    const toggleSkyBoxes = (e) => {
        setIsOpenBoxesPanel();
    }

    const { source, isLoading } = useSelector((state: State) => state.skyBoxes);

    const renderSkyBoxes = () => {
        return source.map((item, index) =>
            <SkyBox
                skyBoxId={item.skyBoxId}
                skyBoxNumber={index + 1}
                output={item.output}
                lockType={item.lockType}
                userName={item.userName}
                userEmail={item.userEmail}
                userId={item.userId}
                multicastModalToggle={multicastModalToggle}
                lockedAt={item.lockedAt}
                skyBox={item}
                setActiveBox={setActiveBox}
                key={index}
                streamSettings={streamSettings}
                selectStream={selectStream}
            />
        )
    }

    return (
        <>
            <div onClick={(e) => toggleSkyBoxes(e)} className="sky-boxes">
                <div className="sky-boxes-control-panel">
                    <div className="sky-boxes-control-panel__btn">
                        <img
                            className={
                                isOpenBoxesPanel
                                    ? "sky-boxes-control-panel__btn-arrow-active"
                                    : "sky-boxes-control-panel__btn-arrow"}
                            src={chevron}/>
                    </div>
                    <p style={{marginBottom: 0}}>SKY BOXES</p>
                </div>
            </div>
            {isOpenBoxesPanel &&
            <div id="skyBoxes" className="boxes-panel" >
                {
                    isLoading
                    ?
                    <div className="boxes-loader-overlay">
                        <div className="spinner-border text-light" role="status">
                             <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    renderSkyBoxes()
                }
            </div>}
        </>
    );
};

export default SkyBoxes

