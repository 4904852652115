import SportEvent from '../schedule/models/sport-event';
import env from '../env'
import moment, { Moment } from 'moment';
import client, { handleError } from '../utils/client';
import { ILiveStream } from '../common/types/live-stream.interface';
import * as publicIp from 'public-ip';
import { ScheduleFilters } from '../schedule/models/schedule-filters';
import {IFilters} from "../schedule/header/filters/interfaces/filters.interface";

export const getEvents = async (filters: ScheduleFilters, timezoneOffset: number): Promise<SportEvent[]> => {
    try {
        const options  = {
            headers: { ...(await getIPHeaders()) }
        };

        const response = await client.post(`${env.apiUrl}/api/schedule/table`, { filters, timezoneOffset }, options);
        return response.data || [];
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const getFilterValues = async (): Promise<SportEvent[]> => {
    try {
        const response = await client.get(`${env.apiUrl}/api/schedule/filter_values`);
        return response.data || [];
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const saveFiltersRequest = async (filters): Promise<IFilters> => {
    try {
        await client.put(`${env.apiUrl}/api/users/schedule_filter`, filters);
        return filters;
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const getLiveStreams = async (): Promise<ILiveStream[]> => {
    try {
        const options  = {
            headers: { ...(await getIPHeaders()) }
        };
        return (await client.get(`${env.apiUrl}/api/schedule/table/live`, options)).data || [];
    }
    catch (error) {
        return handleError(error) || [];
    }
}

const getIPHeaders = async (): Promise<{ [key:string]: string } | null> => {
    if(env.envType !== "Local") {
        return null;
    }

    return { "X-Forwarded-For": (await publicIp.v4()).toString()}
}

export const loadDocument = async (date: {startDate: Date | Moment, endDate: Date | Moment}, type: string) => {
    const start = moment(date.startDate).startOf('day')

    try {
        const timezoneOffset = new Date().getTimezoneOffset();
        const response = await client({
            url: `${env.apiUrl}/api/schedule/document/${type}?startDate=${start.toISOString()}&endDate=${date.endDate.toISOString()}&timezoneOffset=${timezoneOffset}`,
            method: 'GET',
            responseType: 'blob'
        })

        if (navigator.msSaveBlob) {
            // Foe IE and Edge purposes
            return navigator.msSaveBlob(response.data, `${start.add(- timezoneOffset, 'm').toISOString()}-${moment(date.endDate).add(- timezoneOffset, 'm').toISOString()}.${type}`);
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${'Schedule_' + start.format('YYYY-MM-DD').toString() + "-" + moment(date.endDate).format('YYYY-MM-DD').toString()}.${type}`);
        document.body.appendChild(link);
        link.click();
    }
    catch (error) {
        return handleError(error);
    }
}

export const loadVod = async (id: number) => {
    try {
        const response = await client.get(`${env.apiUrl}/api/schedule/vod`, {
            params: { id }
        });
        return response.data;
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const downloadVod = async (id: number) => {
    try {
        const response = await client.put(`${env.apiUrl}/api/vod/download?eventId=${id}`);
        return response.data;
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const clipVodRequest = async (startTime: number, endTime: number, link: string) => {
    try {
        const response = await client.post(`${env.apiUrl}/api/Vod/vodClip`, { inputVideoUrl: link, startTime, endTime });
        return response;
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const getStreamAliases = async () => {
    try {
        const response = await client.get(`${env.apiUrl}/api/schedule/stream_settings_aliases`)
        return response.data;
    } catch (error) {
        return handleError(error) ||  [];
    }
} 

export const deleteDevice = async (deviceUuid: string, userId: number) => {
    try {
        const model = {
            deviceUuid,
            userId
        }

        const response = await client.post(`${env.apiUrl}/api/devices`, model)

        return response.data;
    } catch (error) {
        return handleError(error) ||  [];
    }
}

export const getHlsConfig = async (player: string) => {
    try {
        const response = await client.get(`${env.apiUrl}/api/buffer/${player}`);
        return response.data;
    }
    catch (error) {
        return handleError(error);
    }
}

