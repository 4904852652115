import React, {useState} from "react";
import {IInternalStream} from "../../../../common/types/internal-streams";
import ConfirmDeleteModal from "../confirm-delete-modal";

import './index.scss';

interface StreamItemProps {
    stream: IInternalStream;
    multicastModalToggle: (internalStream: IInternalStream) => void;
    onEdit: (stream: IInternalStream) => void;
}

const StreamItem: React.FunctionComponent<StreamItemProps> = ({
    stream,
    multicastModalToggle,
    onEdit
}) => {

    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

    const handleDeleteClick = () => {
        setConfirmDeleteModalVisible(!confirmDeleteModalVisible);
    }

    return (
        <>
            <div className="stream-item">
                <p className="stream-item__title">
                    {stream.name}
                </p>
                <div className="stream-item__buttons">
                    <button className="stream-item__multicast-btn stream-item__btn" onClick={() => multicastModalToggle(stream)}>
                    </button>
                    <button className="stream-item__edit-btn stream-item__btn" onClick={() => onEdit(stream)}>
                    </button>
                    <button className="stream-item__delete-btn stream-item__btn" onClick={handleDeleteClick}>
                    </button>
                </div>
            </div>
            {
                confirmDeleteModalVisible &&
                    <ConfirmDeleteModal
                        onDeleteClick={handleDeleteClick}
                        stream={stream}
                    />
            }
        </>

    )
}

export default StreamItem;