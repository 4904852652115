import React, {useEffect, useState} from 'react';
import { Dropdown } from 'react-bootstrap';
import moment, { Moment } from 'moment';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';

export interface DownloadDropdownProps {
    availableDocumentsTypes: string[];
    selectedDate: Moment | null;
    loadDocument: (date: { startDate: Moment | Date, endDate: Moment | Date}, type: string) => Promise<any>;
}

const DownloadDropdown: React.FC<DownloadDropdownProps> = ({ availableDocumentsTypes, selectedDate, loadDocument }) => {
    const date = selectedDate && selectedDate.toDate();

    const [focusedInput, setFocusedInput] = useState();
    const [startDate, setStartDate] = useState<Moment | Date>(moment(date));
    const [endDate, setEndDate] = useState<Moment | Date>(moment(date));
    const [dateRangePickerVisible, setDateRangePickerVisible] = useState(false);

    useEffect(() => {
        setDateRangePickerVisible(false);
        return(() => {
            return setDateRangePickerVisible(false);
        })
    }, [])

    const changeFocusedInput = (focusedInput) => {
        setFocusedInput(focusedInput);
    }

    const dateRangePickerToggle = () => {
        setDateRangePickerVisible(!dateRangePickerVisible);
    }

    const datesChangeHandler = (startDate, endDate) => {
        if (endDate === null) {
            endDate = startDate!.clone();
        }

        if (startDate === null) {
            return
        }

        setStartDate(startDate);
        setEndDate(endDate);
    }

    const renderDateRangePicker = () => {
        const start = (startDate as Moment).startOf('day');
        const end = (endDate as Moment).endOf('day');

        return dateRangePickerVisible && (
           <DateRangePicker
                displayFormat={() => "DD/MM/YYYY"}
                startDate={start}
                endDate={end}
                onDatesChange={({ startDate, endDate }) => {
                    datesChangeHandler(startDate, endDate);
                }}
                startDateId={moment(start).toString()}
                endDateId={moment(end).toString()}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => changeFocusedInput(focusedInput)}
                isOutsideRange={day => isInclusivelyAfterDay(day + 1, moment())}
                minimumNights={0}
            />
        )
    }

    return (
        <Dropdown>
            <Dropdown.Toggle id="dropdown" className="doc-toggle" title="Download">
                <span>
                    <i className="fas fa-download header-icon"></i>
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="doc-dropdown">
               <div className="doc-dropdown-item">
                    <span
                        className="doc-dropdown-button"
                        onClick={dateRangePickerToggle}>
                        Select period
                    </span>
               </div>
                {renderDateRangePicker()}
                {
                    availableDocumentsTypes.map(i => {
                        return <Dropdown.Item as='div' key={i} className="doc-dropdown-item">
                            <span
                                className="doc-dropdown-button"
                                onClick={() => loadDocument({startDate: startDate, endDate: endDate}, i)}>
                                Download {i.toUpperCase()}
                            </span>
                        </Dropdown.Item>
                    })
                }

            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DownloadDropdown;