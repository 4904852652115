import { StringLiteral } from "typescript";

class SportEvent {
    public wtScheduledEventId!: number;
    public eventId!: number;
    public internalSchedule!: boolean;
    public startTime!: Date;
    public endTime!: Date;
    public countryCode!: string;
    public countryName!: string;
    public region!: string;
    public serverName!: string;
    public sport!: string;
    public competition!: string;
    public information!: string;
    public description!: string;
    public competitor1!: string;
    public competitor2!: string;
    public cancelled!: boolean;
    public verified!: boolean;
    public created!: Date;
    public channel!: string;
    public webRTCSource!: string;
    public isEnhancedVod!: boolean;
    public streamLink!: string;
    public rtmpLink!: string;
    public vodLink!: string;
    public vodsdLink!: string;
    public wssLink!: string;
    public service!: string;
    public isRestricted?: boolean;
    public rbGameId?: number;

    // public latency!: string;
    // public latencyDescription!: string;
    // public vodDownloadCount!: number;
    // public internalTeam1Id?: number;
    // public internalTeam2Id?: number;
    // public competitor1id!: number;
    // public competitor2id!: number;

    static createInstance() {
        return {
            cancelled: false,
            channel: '',
            competition: '',
            description: '',
            competitor1: '',
            competitor2: '',
            countryCode: '',
            countryName: '',
            region: '',
            created: new Date(),
            endTime: new Date(),
            eventId: 0,
            information: '',
            internalSchedule: false,
            serverName: '',
            sport: '',
            startTime: new Date(),
            verified: false,
            vodLink: '',
            vodsdLink: '',
            wssLink: '',
            webRTCSource: '',
            wtScheduledEventId: 0,
            service: '',
            isEnhancedVod: false,
            rtmpLink: '',
            streamLink: '',
            isRestricted: false
        }
    }
}

export default SportEvent;
