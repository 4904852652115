import React from "react";
import Multiselect from "../../../custom-controls/dropdown/dropdown";
import Checkbox from "../../../custom-controls/checkbox/checkbox";
import { Button } from "react-bootstrap";
import { IFilters } from "./interfaces/filters.interface";
import { defaultFilters } from "../../../store/default-filters";
import { State } from "../../../common/types/state.type";
import { connect } from "react-redux";
import { IFiltersForm } from "./interfaces/filters-form.interface";
import { setFilters, discardFilters, setFilterValues, discardFilterValues, resetToDefaultFilterValues, applyFilters } from "../../../redux/actions/filters/routines";
import './index.scss'
import {loadEventsByFiltersRoutine, saveFiltersRoutine} from "../../../redux/actions/events/filtered-events";
import moment from "moment";
import { ScheduleFilters } from "../../models/schedule-filters";
import { Restriction } from "../../../common/types/restriction.type";
import { getScheduleDueToJumpToLive } from "../../../redux/actions/events/actions";

class FiltersComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            initValues: this.props.filterValues,
            filterValues: this.props.data,
            selectedFilterValues: this.props.appliedFilters,
            groupedValues: null,
            countryFlags: null,
            selectedServices: this.props.appliedFilters.services,
        };
    }

    componentDidMount() {
        const { groupedValues, countryFlags } = this.props;
        this.setState({ groupedValues, countryFlags });
        localStorage.removeItem("mainCriteria");
    }

    componentWillReceiveProps(props: { filterValues: IFilters, data: any }) {
        this.setState({ filterValues: props.data });
    }

    resetFilters = () => {
        this.setState({ selectedFilterValues: defaultFilters, mainFilterField: null })
        this.props.discardFilters();
        this.props.resetFilteredEvents();
        this.props.resetDefaultFilterValues();
        !this.props.filtersApplied && this.props.getScheduleDueToJumpToLive(moment().toDate(), this.props.user!.restriction);
        this.props.toggleFilters();
        this.props.resetDateAndSearchWord();
        this.props.applyFilters(false);
        localStorage.removeItem("mainCriteria");
    }

    onFiltersApply = () => {
        const filters = { ...this.state.selectedFilterValues, services: this.state.selectedServices };
        this.props.setFilters(filters);
        this.props.toggleFilters();
        if (this.isEmpty(filters))
        {
            this.props.applyFilters(false);
            this.props.resetDefaultFilterValues();
            return;
        }
        this.props.loadEventsByFilters(this.props.getScheduleFiltersModel(filters), this.props.user!.restriction as Restriction);
        this.props.applyFilters(true);
    }

    onFiltersApplyAndSave = () => {
        this.onFiltersApply();
        const filtersToSave = { ...this.state.selectedFilterValues};
        delete filtersToSave.isApplied;
        this.props.saveFilters({ ...filtersToSave, services: this.state.selectedServices });
    }

    onSelect = (selectedValues: string[], type: string) => {
        const { selectedFilterValues: appliedFilters } = this.state;
        let inMethodState = appliedFilters;

        const checkOfNull = () => {
            if (!inMethodState.sports.length && !inMethodState.countries.length) {
                this.setState({ mainFilterField: null });
                localStorage.removeItem("mainCriteria");
                this.setState({ filterValues: this.props.data.default })
            }
        }

        if (!appliedFilters.sports.length && !appliedFilters.countries.length) {
            this.setState({ mainFilterField: type });
            localStorage.setItem("mainCriteria", type);
        }

        let mainCriteria = localStorage.getItem("mainCriteria");

        switch (type) {
            case 'sports': {
                this.setState({ selectedFilterValues: { ...this.state.selectedFilterValues, sports: selectedValues } });
                inMethodState = { ...inMethodState, sports: selectedValues };
                checkOfNull();
                if (mainCriteria && mainCriteria === 'sports') {
                    let countries: string[] = [];
                    if (selectedValues.length > 0) {
                        selectedValues.forEach(item => {
                            const findedItem = this.state.groupedValues.groupedBySport.get(item);
                            if (!findedItem) {
                                return;
                            }
                            findedItem.forEach((x: any) => {
                                countries.push(x.country)
                            })
                        })
                        const state = { ...this.state.filterValues, countries: Array.from(new Set(countries)).sort() };
                        this.setState({ filterValues: state });
                        this.props.setFilterValues(state);
                    } else {
                        const state = { ...this.state.filterValues, countries: this.props.data.default.countries };
                        this.setState({ filterValues: state });
                        this.props.setFilterValues(state);
                    }
                }
                break;
            }
            case 'countries': {
                this.setState({ selectedFilterValues: { ...this.state.selectedFilterValues, countries: selectedValues } });
                inMethodState = { ...inMethodState, countries: selectedValues };
                checkOfNull();
                if (mainCriteria && mainCriteria === 'countries') {
                    let s: string[] = [];
                    if (selectedValues.length > 0) {
                        selectedValues.forEach(item => {
                            const findedItem = this.state.groupedValues.groupedByCountry.get(item);
                            if (!findedItem) {
                                return;
                            }
                            findedItem.forEach((x: any) => {
                                s.push(x.sport)
                            })
                        })
                        const state = { ...this.state.filterValues, sports: Array.from(new Set(s)).sort() };
                        this.setState({ filterValues: state });
                        this.props.setFilterValues(state);
                    } else {
                        const state = { ...this.state.filterValues, sports: this.props.data.default.sports };
                        this.setState({ filterValues: state });
                        this.props.setFilterValues(state);
                    }
                }
                break;
            }
            case 'services': {
                this.setState({ selectedServices: selectedValues })
                break;
            }
        }
    }

    renderCountry = (val) => {
        const flag = this.state.countryFlags[val];
        return <div>
            <img
                alt={flag}
                src={`../../../countryflags/${flag}.gif`}
                style={{ marginRight: '15px', paddingBottom: '3px' }}
            />
            {val}
        </div>
    }

    isEmpty = (filters: IFiltersForm) => {
        const { sports, countries, services, onlyNew, showVOD, showLive } = filters;
        return (
            !sports.length
            && !countries.length
            && !services.length
            && !onlyNew
            && !showVOD
            && !showLive
            && !this.props.searchWord
        )
    }

    render() {
        const { selectedFilterValues, filterValues } = this.state;
        return (
            <div className="filters__container">
                <div>
                    <div className='filters__label'>Advanced filter</div>
                    <Multiselect
                        onSelected={(arr: string[]) => this.onSelect(arr, 'services')}
                        options={filterValues.services || []}
                        selectedValues={this.state.selectedServices}
                        placeholder='Select Content Provider'
                    />
                    <Multiselect
                        onSelected={(arr: string[]) => this.onSelect(arr, 'sports')}
                        options={filterValues.sports || []}
                        selectedValues={selectedFilterValues.sports}
                        placeholder='Select Sports'
                    />
                    <Multiselect
                        onSelected={(arr: string[]) => this.onSelect(arr, 'countries')}
                        options={filterValues.countries.filter(x => x != "") || []}
                        render={this.renderCountry.bind(this)}
                        selectedValues={selectedFilterValues.countries}
                        placeholder='Select Countries'
                    />
                    <div className="filters__buttons_controls">
                        <label className="filters__type-label">Select Type:</label>
                        <div className="filters__type-checkboxes">
                            <Checkbox label={'NEW'} value={selectedFilterValues.onlyNew}
                                      onSelect={(onlyNew: boolean) => this.setState({ selectedFilterValues: { ...selectedFilterValues, onlyNew } })} />
                            {
                                (this.props.user.restriction.isHdPlayerEnabled
                                    || this.props.user.restriction.isHlsEnabled
                                    || this.props.user.restriction.isSdPlayerEnabled
                                    || this.props.user.restriction.isRtmpEnabled)
                                &&
                                <Checkbox label={'LIVE'} value={selectedFilterValues.showLive}
                                          onSelect={(showLive: boolean) => this.setState({ selectedFilterValues: { ...selectedFilterValues, showLive } })} />
                            }

                            {
                                (this.props.user.restriction.isHdVodEnabled || this.props.user.restriction.isSdVodEnabled) &&
                                <Checkbox label={'VOD'} value={selectedFilterValues.showVOD}
                                          onSelect={(showVOD: boolean) => this.setState({ selectedFilterValues: { ...selectedFilterValues, showVOD } })} />
                            }
                        </div>
                    </div>
                    <div className='filters__buttons_actions'>
                        <Button onClick={this.resetFilters}>Reset</Button>
                        <Button onClick={this.onFiltersApply}>Apply</Button>
                        <Button onClick={this.onFiltersApplyAndSave}>Apply & Save</Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
    return {
        setFilters(filters: IFiltersForm) {
            dispatch(setFilters.success(filters))
        },
        resetFilteredEvents() {
            dispatch(loadEventsByFiltersRoutine.failure());
        },
        discardFilters() {
            dispatch(discardFilters.success())
        },
        setFilterValues(data: any) {
            dispatch(setFilterValues.success(data))
        },
        discardFilterValues() {
            dispatch(discardFilterValues.success())
        },
        resetDefaultFilterValues() {
            dispatch(resetToDefaultFilterValues.success())
        },
        getScheduleDueToJumpToLive(date: Date, restriction: Restriction) {
            dispatch(getScheduleDueToJumpToLive({date, restriction}))
        },
        loadEventsByFilters(filters: ScheduleFilters, restriction: Restriction) {
            dispatch(loadEventsByFiltersRoutine({filters, restriction}))
        },
        applyFilters(value: boolean) {
            value
                ? dispatch(applyFilters.success())
                : dispatch(applyFilters.failure())
        },
        saveFilters(filters: ScheduleFilters) {
            dispatch(saveFiltersRoutine({filters}))
        }
    };
}

const mapStateToPros = (state: State) => {
    return {
        appliedFilters: state.filters,
        events: state.events.source,
        data: state.filterValues,
        user: state.user,
        filtersApplied: state.filters.isApplied
    };
}
export default connect(mapStateToPros, mapDispatchToProps)(FiltersComponent);