import { call, put, all, takeLatest } from "redux-saga/effects";
import { history } from "../../../router/history";
import { changePass, switchNotificationsReceive } from '../../actions/change-password/index';
import {
    Login,
    GetCurrentUser,
    changePassword,
    switchNotifications,
    resetPassword,
    initPasswordResetProcedure
} from '../../../services/auth.service';
import { toastr } from "react-redux-toastr";
import { setErrorToState } from "../../../services/error.service";
import {
    loginSubmit,
    getCurrentUser as getUser,
    setResetInitPassword,
    askConfirmationRoutine
} from "../../actions/auth/actions";
import { resetPasswordRoutine } from "../../actions/auth/actions";
import { connectSocket } from "../../actions/sockets";
import { User } from '../../../common/types/user.type';
import env from '../../../env';
import CryptoJS from 'crypto-js';
import {secureOrigin} from "../../../utils/helpers";

function* login(action: any) {
    const {email, password, deviceUuid} = action.payload;
    try {
        const response = yield call(Login, {email, password, deviceUuid});
        if (!response) {
            yield put(loginSubmit.failure());
            return;
        }

        if(response.status !== 200) {
            setErrorToState(response.data);
            yield put(loginSubmit.failure())
            return;
        }

        if (response.status === 200 && !response.data.confirmed) {
            yield put(askConfirmationRoutine.success());
            yield put(loginSubmit.failure());
            return;
        }

        yield put(loginSubmit.success())

        const initPath = new URLSearchParams(window.location.search).get("redirectUrl");

        if (initPath!.includes(env.cmsUrl) && secureOrigin()) {
            localStorage.setItem('redirectToCms', 'true');
            const data = JSON.stringify({
                accessToken: localStorage.getItem('token') as string,
            });
            const hashed = CryptoJS.AES.encrypt(data, env.hashKey);
            window.location.href = `${env.cmsUrl}/t=${hashed.toString()}?redirectUrl=${initPath}`;
        }
    } catch (err) {
        yield put(loginSubmit.failure(err.message));
    }
}

function* initPasswordReset(action: any) {
    try {
        const response = yield call(initPasswordResetProcedure, action.payload);

        if (response.status !== 400 && response.status !== 500) {
            yield put(setResetInitPassword.success());
            toastr.success('Success', 'Check your e-mail to confirm password reset!');
            setErrorToState("");
        } else {
            yield put(setResetInitPassword.failure());
            toastr.error('Error!', response.data);
        }
    } catch (err) {
        yield put(setResetInitPassword.failure());
        toastr.error('Error!', 'User does not exist. Try again!');
    }
}

function* changePwrd(action: any) {
    try {
        const response = yield call(changePassword, action.payload);

        if (response.status !== 400 && response.status !== 500) {
            yield put(changePass.success());
            toastr.success('Success', 'Password changed!');
            history.push('/schedule');
        } else {
            yield put(changePass.failure());
            toastr.error('Error!', response.data);
        }

    } catch (err) {
        yield put(changePass.failure(err));
        toastr.error('Error!', 'Password not changed');
    }
}

function* resetPwrd(action: any) {
    try {
        const response = yield call(resetPassword, action.payload);

        if (response.status !== 400 && response.status !== 500) {
            yield put(resetPasswordRoutine.success());
            toastr.success('Success', 'Password reset successful!');
        } else {
            yield put(resetPasswordRoutine.failure());
            toastr.error('Error', response.data);
        }
        localStorage.removeItem("confirmationToken");
    } catch (err) {
        yield put(resetPasswordRoutine.failure(err));
        toastr.error('Error', 'Confirmation link is invalid.');
        localStorage.removeItem("confirmationToken");
    }
}

function* switchNotification(action: any) {
    try {
        yield call(switchNotifications, action.payload);
        yield put(switchNotificationsReceive.success());
        toastr.success('Success', 'Notifications successfully switched')
    } catch (err) {
        yield put(switchNotificationsReceive.failure(err));
        toastr.error('Error', 'Error switching notifications')
    }
}

function* getCurrentUser() {
    try {
        const user: User = yield call(GetCurrentUser);

        if (!user) {
            yield put(getUser.failure())
        }
        else{
            yield put(getUser.success(user));
            yield put(connectSocket(user));
        }
    } catch (err) {
        yield put(getUser.failure(err.message))
    }
}

export default function* loginSaga() {
    yield all([
        takeLatest(loginSubmit, login),
        takeLatest(getUser, getCurrentUser),
        takeLatest(changePass, changePwrd),
        takeLatest(switchNotificationsReceive, switchNotification),
        takeLatest(setResetInitPassword, initPasswordReset),
        takeLatest(resetPasswordRoutine, resetPwrd)
    ]);
}
