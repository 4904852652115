import React, {Fragment, useEffect, useRef, useState} from 'react';
import SportEvent from '../../../../models/sport-event';
import { User } from '../../../../../common/types/user.type';
import moment from 'moment';
import { localTime } from '../../../../../utils/time-converter.utils';
import PlayersDropdown from '../players-dropdown/players-dropdown';
import { isOpenEvent, calculateCountdown } from '../../../../services/shared.service';
import env from "../../../../../env";
import "./index.scss";
import { connect } from 'react-redux';
import { downloadVod } from '../../../../../redux/actions/vod/routines';
import uuid from 'uuid';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { addDownloadRoutine } from '../../../../../redux/actions/logs/actions';
import { State } from '../../../../../common/types/state.type';
import {addDownloadLog} from "../../../../../services/logs.service";
import {Dropdown} from "react-bootstrap";
import PaywallPopup from "../paywall-popup/paywall-popup";
import clip from "../../../../../assets/clip.png";
import { Link } from 'react-router-dom';

export interface WatchColumnProps {
    item: SportEvent;
    user: User | null;
    selectVod: (link: string, event: SportEvent, isHd: boolean) => void;
    downloadVod: (eventId: number) => void;
    isPlayerSelectable: (playerNumber: number) => boolean;
    selectStream: (link: string, playerNumber: number, isRealtime: boolean, isHd: boolean, event: SportEvent) => void;
    addDownloadToStats: (payload: any) => void;
    streamAliases: string[];
    setPopupRef: (ref: any) => void;
    popupRef: any;
    streamSettings: any[];
}

const createFileName = (item: SportEvent) => {
    const timeZoneOffset = new Date().getTimezoneOffset();
    const startTime = `${moment(item.startTime).add('m', - timeZoneOffset).format("YYYY-MM-YY-HH_mm")}`;

    if (item.competitor1 && item.competitor2) {
        return `${item.sport}-${item.competitor1.replace(' ', '_')}-${item.competitor2.replace(' ', '_')}-${startTime}`;
    }

    return `${item.sport}-${item.competition.replace(' ', '_')}-${startTime}`;
}

const createFormattedFileName = (item: SportEvent) => {
    if (item.competitor1 && item.competitor2) {
        return `${item.sport}: ${item.competitor1} - ${item.competitor2}`;
    }

    return `${item.sport}: ${item.competition}`;
}

const WatchColumn: React.FC<WatchColumnProps> = ({
    item,
    user,
    selectVod,
    isPlayerSelectable,
    selectStream,
    streamAliases,
    setPopupRef,
    popupRef,
    streamSettings
}) => {
    let popupTimeoutRef: any = useRef(null);
    const [downloadGuid] = useState(uuid());
    const endTime = localTime(item.endTime)
    const dtNow = moment();
    const isLive = isOpenEvent(item);
    const isFinished = dtNow.isAfter(endTime) && !item.cancelled && !isLive;
    const isShowFinished = isFinished && dtNow.diff(endTime, "day") < 14;
    const isShowDownload = isFinished && dtNow.diff(endTime, "day") >= 14;
    const minutesCountdown = calculateCountdown(item);
    const playersList = user!.restriction.isMaxPlayersEnabled ? [1, 2, 3, 4] : [1, 2];

    const [paywallPopupVisibility, setPaywallPopupVisibility] = useState("hidden");
    const [verticalPosition, setVerticalPosition] = useState(0);
    const [popupPositionClass, setPopupPositionClass] = useState("");
    const thisPopupRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const popupClass = verticalPosition < 335 ? "mirrored" : "";
        setPopupPositionClass(popupClass);
        if (popupRef && thisPopupRef && popupRef.current && thisPopupRef.current) {
            if (popupRef.current! !== thisPopupRef.current!) {
                hidePaywallPopup();
            }
        }
    }, [popupRef]);

    useEffect(() => {
        return () => clearTimeout(popupTimeoutRef.current)
    }, []);


    const renderUploadingVodTooltip = () => {
        return <div>
            <Tooltip placement='top' trigger={'hover'} overlay={<span>VOD is uploading</span>}>
                <div>
                    <i className="fas fa-cloud-upload-alt vod-loading-icon"></i>
                    <span className='live-vod-btn'>VOD</span>
                </div>
            </Tooltip>
        </div>
    }

    const renderVodIcon = () => {
        if(item.vodLink && item.vodsdLink){
            return <div>
                <span className='dot' style={{backgroundColor: '#0cbb3d'}}></span>
                <span className='live-vod-btn'>VOD</span>
            </div>
        }

        if(item.vodLink || item.vodsdLink) {
            return <div>
                <span className='left-semi-dot' style={{backgroundColor: '#d0d0d0'}}></span>
                <span className='right-semi-dot' style={{backgroundColor:'#0cbb3d'}}></span>
                <span className='live-vod-btn'>VOD</span>
            </div>
        }

        return renderUploadingVodTooltip();
    }

    const renderComingSoonTooltip = () => {
        return (
            <span className="coming-soon-label">
                Coming Soon
            </span>
        );
    }

    const onDownloadClick = (isHdVod) => {
        if (item.isRestricted || (isHdVod && user!.restriction.isHdVodPaywallEnabled) || (!isHdVod && user!.restriction.isSdVodPaywallEnabled)) {
            return;
        } else {
            const link = isHdVod ? item.vodLink : item.vodsdLink;
            window.open(link, '_blank')
            addDownloadLog({ eventId: item.eventId });
        }
    }

    const renderDownloadIcon = (isHdVod) => {
        return (
            !item.isRestricted &&
            <Tooltip placement='top'
                     trigger={
                         isHdVod && !user!.restriction.isHdVodPaywallEnabled
                     || !isHdVod && !user!.restriction.isSdVodPaywallEnabled
                         ? 'hover' : ''
                     }
                     overlay={<span>Download</span>}>
                <a
                    className={
                        isHdVod && !user!.restriction.isHdVodPaywallEnabled
                    || !isHdVod && !user!.restriction.isSdVodPaywallEnabled
                        ? "btn download-stream_link"
                        : "btn download-stream_link disabled"
                    }
                    href={isHdVod ? item.vodLink : item.vodsdLink}
                    rel="noopener noreferrer"
                    download
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '10px'
                    }}
                    onClick={() => onDownloadClick(isHdVod)}>
                    <i style={{
                        fontSize: '20px'
                    }} className="fas fa-arrow-circle-down fa-2x cntr-icon"></i>
                </a>
            </Tooltip>
        )
    }

    const onRestrictedHover = (verticalPosition, forVod) => {
        setVerticalPosition(verticalPosition);

        if (!item.isRestricted && !forVod) {
            return;
        } else {
            setPopupRef(thisPopupRef);
            clearTimeout(popupTimeoutRef.current);
            showPaywallPopup();
            popupTimeoutRef.current = setTimeout(() => {
                hidePaywallPopup();
            }, 5000)
        }
    }

    const showPaywallPopup = () => {
        setPaywallPopupVisibility("visible");
    }

    const hidePaywallPopup = () => {
        setPaywallPopupVisibility("hidden");
    }

    const renderPaywallPopup = () => {
        return (
            item.isRestricted &&
            <PaywallPopup
                paywallPopupVisibility={paywallPopupVisibility}
                popupPositionClass={popupPositionClass}
                popupRef={thisPopupRef}
            />
        )
    }

    const vodClickHandler = (link, item, isHd, isPaywall) => {
        if (isPaywall) {
            return;
        } else {
            selectVod(link, item, isHd);
        }
    }

    const renderVodControl = (item: SportEvent) => {
        const shouldShowVod = streamAliases.includes(item.serverName);
        let renderNoVodTooltip = shouldShowVod ? renderUploadingVodTooltip() : renderComingSoonTooltip();


        if(!item.isEnhancedVod || !user!.restriction.isRunningBallEnabled) {
            return (
                <div className="vod-dropdown">
                    <Dropdown>
                        <Dropdown.Toggle disabled={item.isRestricted || (!item.vodsdLink && !item.vodLink) }>
                            <div className='play-btn'>
                                {
                                   renderVodIcon()
                                }
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                (user!.restriction.isHdVodEnabled || user!.restriction.isHdVodPaywallEnabled)
                                &&
                                <Dropdown.Item onMouseEnter={(e) => onRestrictedHover(e.clientY, user!.restriction.isHdVodPaywallEnabled)}>
                                    <span>HD VOD</span>
                                    <Tooltip placement='top' trigger={!user!.restriction.isHdVodPaywallEnabled ? 'hover' : ''} overlay={<span>Play</span>}>
                                        <div className={user!.restriction.isHdVodPaywallEnabled ? "vod-play-btn disabled" : "vod-play-btn"}
                                             onClick={() => vodClickHandler(item.vodLink, item, true, user!.restriction.isHdVodPaywallEnabled)}>
                                            <div className="vod-play-btn__icon"></div>
                                        </div>
                                    </Tooltip>
                                    {
                                        user!.restriction!.isStreamsDownloadEnabled
                                        && shouldShowVod
                                        && (
                                            <>
                                                <div style={{ marginLeft: '10px' }}>
                                                    {
                                                        item.vodLink
                                                            ? renderDownloadIcon(true)
                                                            : renderNoVodTooltip
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                    {   user!.restriction.isHdVodPaywallEnabled &&
                                        <PaywallPopup
                                            paywallPopupVisibility={paywallPopupVisibility}
                                            popupPositionClass={`right ${popupPositionClass} vod`}
                                            popupRef={thisPopupRef}
                                        />
                                    }
                                </Dropdown.Item>
                            }
                            {
                                (user!.restriction.isSdVodEnabled || user!.restriction.isSdVodPaywallEnabled)
                                &&
                                <Dropdown.Item onMouseEnter={(e) => onRestrictedHover(e.clientY, user!.restriction.isSdVodPaywallEnabled)}>
                                    <span>SD VOD</span>
                                    <Tooltip placement='top' trigger={!user!.restriction.isSdVodPaywallEnabled ? 'hover' : ''} overlay={<span>Play</span>}>
                                        <div className={user!.restriction.isSdVodPaywallEnabled ? "vod-play-btn disabled" : "vod-play-btn"}
                                             onClick={() => vodClickHandler(item.vodsdLink, item, false, user!.restriction.isSdVodPaywallEnabled)}>
                                            <div className="vod-play-btn__icon"></div>
                                        </div>
                                    </Tooltip>
                                    {
                                        user!.restriction!.isStreamsDownloadEnabled
                                        && shouldShowVod
                                        && (
                                            <>
                                                <div style={{ marginLeft: '10px' }}>
                                                    {
                                                        item.vodsdLink
                                                            ? renderDownloadIcon(false)
                                                            : renderNoVodTooltip
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                    {   user!.restriction.isSdVodPaywallEnabled &&
                                    <PaywallPopup
                                        paywallPopupVisibility={paywallPopupVisibility}
                                        popupPositionClass={`right ${popupPositionClass} vod`}
                                        popupRef={thisPopupRef}
                                    />
                                    }
                                </Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        }
    }

    if (isShowFinished && (user!.restriction.isHdVodEnabled
        || user!.restriction.isSdVodEnabled
        || user!.restriction.isHdVodPaywallEnabled
        || user!.restriction.isSdVodPaywallEnabled)) {
        return (
            <div className='d-flex'
                 style={{justifyContent: item.vodLink ? 'space-between' : 'space-between', position: 'relative'}}
                 onMouseEnter={(e) => onRestrictedHover(e.clientY, false)}
            >
                {renderVodControl(item)}
                {renderPaywallPopup()}
            </div>)
    }

    if (isLive &&
		(user!.restriction.isHdHlsEnabled
        || user!.restriction.isSdHlsEnabled
        || user!.restriction.isHdPlayerEnabled
        || user!.restriction.isSdPlayerEnabled
        || user!.restriction.isHdHlsPaywallEnabled
        || user!.restriction.isSdHlsPaywallEnabled
        || user!.restriction.isHdPlayerPaywallEnabled
        || user!.restriction.isSdPlayerPaywallEnabled
    )) {
        return (
            <div style={{position: "relative"}}
                 onMouseEnter={(e) => onRestrictedHover(e.clientY, false)}>
                <PlayersDropdown
                    playersList={playersList}
                    item={item}
                    user={user}
                    isPlayerSelectable={isPlayerSelectable}
                    selectStream={selectStream}
                    streamSettings={streamSettings}
                />
                {renderPaywallPopup()}
            </div>
        )
    }

    if (isShowDownload && user!.restriction.isStreamsDownloadEnabled) {
        // if (!item.vodLink || item.vodLink === null || item.vodLink === "") {
        //     return (
        //     <div>
        //         Download Unavailable
        //     </div>
        //     )
        // }
        // return (
        //     <div>
        //        {
        //            renderDownloadIcon()
        //        }
        //     </div>
        // )
        return (
            <div>
                Unavailable
            </div>
            )
    }

    if (item.cancelled) {
        return (
            <span className="cancelled-label">
                Event Cancelled
            </span>
        )
    }

    if (!item.cancelled && minutesCountdown) {
        return <div> {minutesCountdown} Minutes</div>
    }

    return null;
}

const mapStateToProps = (state: State) => {
    return {
        streamAliases: state.streamAliases
    }
}

const mapDispatchToProps = {
    downloadVod,
    addDownloadToStats: addDownloadRoutine
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchColumn);
