import React from "react";
import {useDispatch} from "react-redux";
import {deleteInternalStreamRoutine} from "../../../../redux/actions/internal-streams";
import {IInternalStream} from "../../../../common/types/internal-streams";
import OutsideClickHandler from 'react-outside-click-handler';

import './index.scss';

interface ConfirmDeleteModalProps {
    onDeleteClick: () => void;
    stream: IInternalStream;
}

const ConfirmDeleteModal: React.FunctionComponent<ConfirmDeleteModalProps> = ({ onDeleteClick, stream }) => {

    const dispatch = useDispatch();

    const handleDeletionConfirmation = () => {
        dispatch(deleteInternalStreamRoutine(stream.id));
        onDeleteClick();
    }

    return (
        <div className="confirm-delete-modal__overlay">
            <OutsideClickHandler
                onOutsideClick={() => onDeleteClick()}
            >
                <div className="confirm-delete-modal">
                    <h1 className="confirm-delete-modal__question">
                        Are you sure you want to delete this stream?
                    </h1>
                    <div className="confirm-delete-modal__buttons">
                        <button className="confirm-delete-modal__btn confirm-delete-modal__yes-btn" onClick={handleDeletionConfirmation}>Yes</button>
                        <button className="confirm-delete-modal__btn confirm-delete-modal__no-btn" type="submit" onClick={onDeleteClick}>No</button>
                    </div>
                </div>
            </OutsideClickHandler>
        </div>
    )
}

export default ConfirmDeleteModal;