import { createRoutine } from 'redux-saga-routines';

export const setFilters = createRoutine('SET_FILTERS');
export const discardFilters = createRoutine('DISCARD_FILTERS');

export const setFilterValues = createRoutine('SET_FILTER_VALUES');
export const setDefaultFilterValues = createRoutine('SET_DEFAULT_FILTER_VALUES');
export const resetToDefaultFilterValues = createRoutine('RESET_TO_DEFAULT_FILTERS');
export const applyFilters = createRoutine('APPLY_FILTERS');

export const discardFilterValues = createRoutine('DISCARD_FILTER_VALUES');
