import StreamSetting from "./types/stream-setting.type";
import SportEvent from "./models/sport-event";
import { ScheduleFilters } from "./models/schedule-filters";
import { User } from "../common/types/user.type";
import {ILogModel} from "../common/types/statistics.type";
import { State } from "../common/types/state.type";
import { getEventsTableRoutine, getScheduleDueToJumpToLive, refreshEventsRoutine } from "../redux/actions/events/actions";
import { setJumpToLive } from "../redux/actions/jumpToLive/actions";
import { setVOD } from "../redux/actions/vod/routines";
import { loadEventsByFiltersRoutine } from "../redux/actions/events/filtered-events";
import { UserLogout } from "../common/types/user-logout";
import { logoutOnDeletedDevice } from "../redux/actions/devices";
import { IFiltersForm } from "./header/filters/interfaces/filters-form.interface";
import {getSkyBoxListRoutine} from "../redux/actions/skyboxes";
import {getScreensListRoutine} from "../redux/actions/screens";
import {IInternalStream} from "../common/types/internal-streams";
import {getInternalStreamsRoutine} from "../redux/actions/internal-streams";
import {applyFilters, setFilters} from "../redux/actions/filters/routines";
import {Restriction} from "../common/types/restriction.type";

export interface SchedulePageState {
    isExpanded: boolean;
    width: number;
    height: number;
    streamSettings: StreamSetting[];
    isShowCloseButton: boolean;
    isOpenSkyBoxes:boolean;
    skyBoxesHeight:number;
    multicastVisible: boolean;
    activeBox: any;
    skyBoxWssStreamLink: string
    isPlaySkyBoxStream: boolean;
    boxPanelHeight: number;
    isFullscreen: boolean;
    isOpenInternalStreamsPanel: boolean;
    streamsPanelHeight: number;
    activeInternalStream: null | IInternalStream;
    }

export interface SchedulePageProps {
    getSchedule: (filters: ScheduleFilters) => void;
    setJumpToLive: (condition: boolean) => void;
    getScheduleDueToJumpToLive: (date: Date) => void;
    setVOD: (link: number) => void;
    applyFilters: (isApplied: boolean) => void;
    setFilters: (filters: IFiltersForm) => void;
    getScheduleForRefresh: (date: Date) => void;
    logoutUser: (data: UserLogout) => void;
    loadEventsByFilters: (filters: ScheduleFilters, restriction: Restriction) => void;
    resetDefaultFilterValues: () => void;
    events: {
        source: SportEvent[],
        selectedDate: Date,
        isLoading: boolean,
        isTopScrollLoading: boolean,
        isBottomScrollLoading: boolean
    },
    isJumpToLive: boolean,
    user: User | null,
    vod: string,
    logs: ILogModel[],
    filteredEvents: SportEvent[],
    filtersApplied: boolean,
    userLogout: UserLogout,
    appliedFilters: IFiltersForm,
    isOpenSkyBoxes:boolean,
    getSkyBoxes: () => void;
    getScreens: () => void;
    screensLoading: boolean;
    startLogRecord: (log: ILogModel) => void;
    getInternalStreams: () => void;
}

export function mapStateToProps(state: State) {
    const { events, isJumpToLive, user, vod, logs, userLogout, screens } = state;
    return {
        events,
        isJumpToLive,
        user,
        vod,
        logs,
        filteredEvents: state.filteredEvents.source,
        filtersApplied: state.filters.isApplied,
        userLogout,
        appliedFilters: state.filters,
        screensLoading: screens.isLoading
    };
}

export function mapDispatchToProps(dispatch: any) {
    return {
        getSchedule(filters: ScheduleFilters) {
            dispatch(getEventsTableRoutine({filters}))
        },
        getScheduleDueToJumpToLive(date: Date) {
            dispatch(getScheduleDueToJumpToLive({date}))
        },
        setJumpToLive(value: boolean) {
            const action = value ? setJumpToLive.success : setJumpToLive.fulfill;
            dispatch(action())
        },
        setVOD(link: number) {
            dispatch(setVOD.success(link));
        },
        getScheduleForRefresh(date: Date) {
            dispatch(refreshEventsRoutine({date}));
        },
        logoutUser(data: UserLogout) {
            dispatch(logoutOnDeletedDevice(data));
        },
        loadEventsByFilters(filters: ScheduleFilters, restriction: Restriction) {
            dispatch(loadEventsByFiltersRoutine({filters, restriction}))
        },
        getSkyBoxes() {
            dispatch(getSkyBoxListRoutine());
        },
        getScreens() {
            dispatch(getScreensListRoutine());
        },
        getInternalStreams() {
            dispatch(getInternalStreamsRoutine())
        },
        setFilters(filters: IFiltersForm) {
            dispatch(setFilters.success(filters))
        },
        applyFilters(value: boolean) {
            value
                ? dispatch(applyFilters.success())
                : dispatch(applyFilters.failure())
        },
    };
}

