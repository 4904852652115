import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import chevron from '../../assets/skybox-icons/chevron.png';
import Marker from "../marker";
import {FRAMES_NUMBER, IFrames} from "../index";
import {Spinner} from "react-bootstrap";

interface IClipControlsProps {
    onStartMarkerMouseDown: (e: React.MouseEvent) => void;
    onEndMarkerMouseDown: (e: React.MouseEvent) => void;
    onConfirmClick: () => void;
    frames: IFrames[];
    startMarker: number;
    endMarker: number;
    activeMarker: 'start' | 'end' | null;
    movingMarkerForward: boolean;
    movingSelectionWindowState: boolean;
    selectionWindowPosition: number;
    timelineRef: React.RefObject<HTMLDivElement>;
    selectionWindowRef: React.RefObject<HTMLDivElement>;
    convertSecondsToTimeString: (seconds: number) => string;
    onFrameClickHandler: (time: number) => void;
    onSelectionWindowDown: (e: React.MouseEvent) => void;
    onSelectionWindowUp: (e: React.MouseEvent) => void;
    onSelectionWindowMove: (e: React.MouseEvent) => void;
    markerMovingDistance: number;
    onLeftArrowClick: (marker: string) => void;
    onRightArrowClick: (marker: string) => void;
}

const ClipControls: React.FunctionComponent<IClipControlsProps> = ({
    onStartMarkerMouseDown,
    onEndMarkerMouseDown,
    onConfirmClick,
    frames,
    startMarker,
    endMarker,
    activeMarker,
    movingMarkerForward,
    movingSelectionWindowState,
    selectionWindowPosition,
    timelineRef,
    selectionWindowRef,
    convertSecondsToTimeString,
    onFrameClickHandler,
    onSelectionWindowDown,
    onSelectionWindowUp,
    onSelectionWindowMove,
    markerMovingDistance,
    onLeftArrowClick,
    onRightArrowClick
}) => {

    const [dragPanelVisible, setDragPanelVisible] = useState(false);

    const hideTimeout = useRef<number | null>(null);

    const showDragPanel = () => {
        if (typeof hideTimeout.current === 'number') {
            clearTimeout(hideTimeout.current);
        }
        setDragPanelVisible(true);
    };

    const hideDragPanel = () => {
        hideTimeout.current = window.setTimeout(() => {
            setDragPanelVisible(false);
        }, 3000) as number;
    };

    const renderFrames = () => {
        return (
            frames.map((frame, index) => (
                frame.isLoading
                    ?
                    <Spinner key={index} animation='border' style={{margin: '0 auto'}}/>
                    :
                    <img
                        key={index}
                        className='frame'
                        src={frame.frameImageUrl}
                        alt={`frame ${index}`}
                        onClick={() => index !== 0 && index !== FRAMES_NUMBER - 1 && onFrameClickHandler(frame.time)}
                    />
            ))
        )
    }

    const calculateSelectionWindowWidth = () => {
        const widthConverted = Math.abs(markerMovingDistance) / window.innerWidth * 100;
        if ((markerMovingDistance < 0 && activeMarker === 'end') || (markerMovingDistance > 0 && activeMarker === 'start')) {
            return `${62 - widthConverted}vw`;
        } else {
            return `${62 + widthConverted}vw`;
        }
    }

    const calculateSelectionWindowPosition = () => {
        if (activeMarker === null || activeMarker === 'end') {
            return { left: `${selectionWindowPosition}px`, width: calculateSelectionWindowWidth() };
        } else {
            return { right: `${selectionWindowPosition}px`, width: calculateSelectionWindowWidth() };
        }
    }

    return (
        <div className='clip-controls'>
            <div className='timeline'
                 ref={timelineRef}
                 onMouseEnter={showDragPanel}
                 onMouseLeave={hideDragPanel}
                 onMouseMove={onSelectionWindowMove}
                 onMouseUp={onSelectionWindowUp}
            >
                <div className={`selection-window ${movingSelectionWindowState && "active"}`}
                     ref={selectionWindowRef}
                     style={calculateSelectionWindowPosition()}
                >
                    {
                        dragPanelVisible &&
                        <div className='selection-window__drag-panel'
                             style={movingSelectionWindowState
                                 ? {cursor: "grabbing"}
                                 : {}}
                             onMouseDown={onSelectionWindowDown}
                        >
                            <img src={chevron} alt="arrow-left" onClick={() => onLeftArrowClick('center')}/>
                            <img src={chevron} alt="arrow-right" onClick={() => onRightArrowClick('center')}/>
                        </div>
                    }
                    <Marker
                        activeMarker={activeMarker}
                        marker="start"
                        movingMarkerForward={movingMarkerForward}
                        onMouseDown={onStartMarkerMouseDown}
                        time={convertSecondsToTimeString(startMarker)}
                        onLeftArrowClick={onLeftArrowClick}
                        onRightArrowClick={onRightArrowClick}
                    />
                    <Marker
                        activeMarker={activeMarker}
                        marker="end"
                        movingMarkerForward={movingMarkerForward}
                        onMouseDown={onEndMarkerMouseDown}
                        time={convertSecondsToTimeString(endMarker)}
                        onLeftArrowClick={onLeftArrowClick}
                        onRightArrowClick={onRightArrowClick}
                    />
                </div>
                <div className='frames-wrapper'>
                    {renderFrames()}
                </div>
                <button className='clip-confirm-button' onClick={onConfirmClick}>Confirm Clipping</button>
            </div>
        </div>
    );
};

export default ClipControls;
