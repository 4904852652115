import moment, { Moment } from "moment";

export const localTime = (date: Date): Moment => moment(date).add('m', -new Date().getTimezoneOffset());

export const minutesToMiliseconds = (minutes: number): number => minutes * 60000;

export const millisecondToStringFormattedDate = (milliseconds: number) => {
    let seconds: number | string = Math.floor((milliseconds / 1000) % 60);
    let minutes: number | string = Math.floor((milliseconds / (1000 * 60)) % 60);
    let hours: number | string = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) && (hours < 1) ? "0" + (minutes + Number(hours) * 60) : minutes + Number(hours) * 60;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return minutes + ":" + seconds;
}

export const getTimeDiffInDays = (date1: number, date2: number) => {
    const timeDiff = Math.abs(date2 - date1);
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
}
