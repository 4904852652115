import React from 'react'
import './styles.scss';
import { history } from '../../router/history';
import { User } from '../../common/types/user.type';
import { State } from '../../common/types/state.type';
import { connect } from 'react-redux';

export interface MenuBarProps {
    user: User | null;
}

const MenuBar: React.FC<MenuBarProps> = ({ user }) => {

    return user && (
        <div className='sidebar-menu'>
            <div className='sidebar-menu-inner'>
                <ul className="main-menu">
                    <li className="menu-item" onClick={() => history.push('/profile/information')}>
                        <i className='fas fa-user' />
                        <p>Information</p>
                    </li>
                    {
                        (!user.isExternalUser)&&
                        <li  className="menu-item" onClick={() => history.push('/profile/change-password')}>
                            <i className='fas fa-key' />
                            <p>Change password</p>
                        </li>
                    }
                    {
                        user.group &&
                        <li className="menu-item" onClick={() => history.push('/profile/device-management')}>
                            <i className="fas fa-desktop"></i>
                            <p>Device management</p>
                        </li>
                    }
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(MenuBar)