import { all, fork } from 'redux-saga/effects';
import loginSaga from './sagas/login';
import eventsSaga from './sagas/events';
import vodSaga from './sagas/vod';
import runningBallSaga from './sagas/runningball';
import liveStreamsSaga from './sagas/live-streams';
import streamAliasesSaga from './sagas/aliases';
import devicesSaga from './sagas/devices';
import hlsSaga from './sagas/hls';
import filteredEventsSaga from './sagas/events/filtered-events';
import appSaga from './sagas/app';
import skyBoxSaga from "./sagas/skyboxes";
import screenSaga from "./sagas/screens";
import internalStreamsSaga from "./sagas/internal-streams";
import announcementsSaga from "./sagas/announcements";

export default function* rootSaga() {
    yield all([
        fork(loginSaga), 
        fork(eventsSaga), 
        fork(vodSaga), 
        fork(runningBallSaga),
        fork(liveStreamsSaga),
        fork(streamAliasesSaga),
        fork(devicesSaga),
        fork(hlsSaga),
        fork(filteredEventsSaga),
        fork(appSaga),
        fork(skyBoxSaga),
        fork(screenSaga),
        fork(internalStreamsSaga),
        fork(announcementsSaga)
    ]);

}
