import defaultState from "../../defaultState";
import {setSessionIdRoutine} from "../../actions/session";

const sessionReducer = (state = defaultState.sessionId, action: any) => {
    switch (action.type) {
        case setSessionIdRoutine.TRIGGER: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export default sessionReducer;
