import React, {createRef, Fragment} from 'react';
import SportEvent from '../../schedule/models/sport-event';
import './index.scss';
import { Dropdown, Spinner } from 'react-bootstrap';
import { getLiveStreams } from '../../redux/actions/live-streams';
import { connect } from 'react-redux';
import { State } from '../../common/types/state.type';
import { ILiveStream } from '../../common/types/live-stream.interface';
import StreamSetting from "../../schedule/types/stream-setting.type";
import PaywallPopup from "../../schedule/table/body/children/paywall-popup/paywall-popup";

export interface EventDropdownProps {
    width: number,
    height: number,
    events: SportEvent[],
    currentEventId: number,
    liveStreams: ILiveStream[],
    isLoading: boolean,
    isHdPlayerEnabled: boolean,
    isSdPlayerEnabled: boolean,
    isHdHlsEnabled: boolean,
    isSdHlsEnabled: boolean,
    isHdPlayerPaywallEnabled: boolean,
    isSdPlayerPaywallEnabled: boolean,
    isHdHlsPaywallEnabled: boolean,
    isSdHlsPaywallEnabled: boolean,
    onEventSelect: (link: string, isRealtime: boolean, isHd: boolean, event: SportEvent) => void;
    onPlayerClose: () => void;
    onStreamClose: () => void;
    getLiveStreams: () => void;
    playerNumber: number;
    streamSettings: StreamSetting[];
}

export interface EventDropdownState {
    isExpanded: boolean,
    width: number,
    height: number,
    events: SportEvent[],
    currentEventId: number,
    paywallPopupVisibility: string,
    paywallPopupPosition: number
}

class EventDropdown extends React.Component<EventDropdownProps, EventDropdownState> {

    popupTimeoutRef: any = createRef();
    private timer: any;
    private toggle = () => this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));

    constructor(props: any) {
        super(props);
        this.state = {
            isExpanded: false,
            width: this.props.width,
            height: this.props.height,
            events: this.props.events,
            currentEventId: this.props.currentEventId,
            paywallPopupVisibility: "hidden",
            paywallPopupPosition: 0
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleToggle = () => {
        this.props.getLiveStreams();

        if (!this.state.isExpanded && !this.props.isLoading) {
            this.timer = setTimeout(this.toggle, 20000);
        }
        else {
            clearTimeout(this.timer);
        }
        this.toggle();
    }

    onEventSelect = (link: string, isRealtime: boolean, isRtmp: boolean, event: SportEvent) => {
        clearTimeout(this.timer);
        this.toggle();
        this.props.onEventSelect(link, isRealtime, isRtmp, event);
    }

    getHdLiveIcon = (eventItem, currEventId, currentSetting) => {
        if (eventItem.event.eventId === currEventId && currentSetting && currentSetting.isHd && currentSetting.isRealtime) {
            return './inplay-icons/hd_sel.svg';
        } else {
            return './inplay-icons/hd.svg';
        }
    }

    getSdLiveIcon = (eventItem, currEventId, currentSetting) => {
        if (eventItem.event.eventId === currEventId && currentSetting && !currentSetting.isHd && currentSetting.isRealtime) {
            return './inplay-icons/sd_sel.svg';
        } else {
            return './inplay-icons/sd.svg';
        }
    }

    getHdReviewIcon = (eventItem, currEventId, currentSetting) => {
        if (eventItem.event.eventId === currEventId && currentSetting && currentSetting.isHd && !currentSetting.isRealtime) {
            return './inplay-icons/hd_rev_sel.svg';
        } else {
            return './inplay-icons/hd_rev.svg';
        }
    }

    getSdReviewIcon = (eventItem, currEventId, currentSetting) => {
        if (eventItem.event.eventId === currEventId && currentSetting && !currentSetting.isHd && !currentSetting.isRealtime) {
            return './inplay-icons/sd_rev_sel.svg';
        } else {
            return './inplay-icons/sd_rev.svg';
        }
    }

    onPaywallHover = (e, enable) => {
       if (!enable) {
           return
       } else {
           this.setState({paywallPopupPosition: e.clientY});
           this.setState({paywallPopupVisibility: "visible"});
           this.popupTimeoutRef.current = setTimeout(() => {
               this.setState({paywallPopupVisibility: "hidden"});
               clearTimeout(this.popupTimeoutRef.current);
           }, 5000)
       }
    }

    renderPaywallPopup = () => {
        return (
            <PaywallPopup
                paywallPopupVisibility={this.state.paywallPopupVisibility}
                popupPositionClass="for-event-dropdown mirrored"
                popupRef={this.popupTimeoutRef}
            />
        )
    }

    renderEventRows() {

        if (!this.props.liveStreams.length && !this.props.isLoading) {
            return <Fragment key={0}>
                <div className="dropdown-element">
                    There no open events
                </div>
            </Fragment>
        }

        if (this.props.isLoading) {
            return <Fragment key={0}>
                <div className="spinner-container">
                    <Spinner animation="border" />
                </div>
            </Fragment>
        }
        let currEventId = this.props.currentEventId;
        return this.props.liveStreams.sort((a) =>
            (a.event.eventId === currEventId) ? -1 : 1
        ).map((item: ILiveStream) => {
            let isActiveEvent = item.event.eventId === currEventId;
            item.event.rtmpLink = item.rtmpLink;
            item.event.wssLink = item.wssLink;
            item.event.streamLink = item.hlsLink;

            const currentSetting = this.props.streamSettings && this.props.streamSettings.find(setting => setting.event.eventId === currEventId);

            return (
                <div key={item.event.eventId} className="dropdown-element"
                    style={{
                        position: isActiveEvent ?  "fixed" : "static",
                        color: isActiveEvent ? "yellow": "",
                        marginTop: currEventId && 0
                    }}>
                    <div>
                        {
                            item.label
                        }
                    </div>
                    <div className="buttons">
                        {
                            (this.props.isHdPlayerEnabled && !this.props.isHdPlayerPaywallEnabled) &&
                            <button
                                title='HD Live'
                                disabled={this.props.isHdPlayerPaywallEnabled}
                                onClick={() => this.onEventSelect(item.wssLink, true, true, item.event)}
                            >
                                <img
                                    alt='run'
                                    src={this.getHdLiveIcon(item, currEventId, currentSetting)}/>
                            </button>
                        }
                        {
                            (this.props.isSdPlayerEnabled && !this.props.isSdPlayerPaywallEnabled) &&
                            <button
                                title='SD Live'
                                disabled={this.props.isSdPlayerPaywallEnabled}
                                onClick={() => this.onEventSelect(item.wssLink, true, false, item.event)}
                            >
                                <img
                                    alt='run'
                                    src={this.getSdLiveIcon(item, currEventId, currentSetting)}/>
                            </button>
                        }
                        {
                            (this.props.isHdHlsEnabled && !this.props.isHdHlsPaywallEnabled) &&
                            <button
                                title='HD Review'
                                disabled={this.props.isHdHlsPaywallEnabled}
                                onClick={() => this.onEventSelect(item.hlsLink, false, true, item.event)}
                            >
                                <img
                                    alt='bino'
                                    src={this.getHdReviewIcon(item, currEventId, currentSetting)}/>
                            </button>
                        }
                        {
                            (this.props.isSdHlsEnabled && !this.props.isSdHlsPaywallEnabled) &&
                            <button
                                title='SD Review'
                                disabled={this.props.isSdHlsPaywallEnabled}
                                onClick={() => this.onEventSelect(item.hlsLink, false, false, item.event) }
                            >
                                <img
                                    alt='bino'
                                    src={this.getSdReviewIcon(item, currEventId, currentSetting)}/>
                            </button>
                        }
                    </div>
                </div>
            );
        })
    }

    renderCloseDropdown = () => {
        // 3804 - Removed ability to close the stream
        return (
            <Dropdown>
                <Dropdown.Toggle id="dropdown" as='div' className="close-toggle" title="Close">
                    <div className='close-btn'>
                        <i className="far fa-window-close" />
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu as='div'>
                    <Dropdown.Item key={1} onClick={this.props.onPlayerClose}>Close the player</Dropdown.Item>
                    <Dropdown.Item key={2} onClick={this.props.onStreamClose}>Close the stream</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    render() {
        const { width, height } = this.props;

        return (
            <Fragment>
                <div className="event-dropdown" style={{ top: height / 8, left: width / 20, zIndex: 2 }}>
                    <div>
                        <i style={{ fontSize: '25px', color: 'white' }} onClick={this.handleToggle} className="fas fa-bars"></i>
                    </div>
                    {
                        this.state.isExpanded &&
                        <div className='dropdown-body'>
                            <div className='dropdown-body-header'>
                                <div>Select Game</div>
                                <div className='close-btn' onClick={this.props.onPlayerClose}>
                                    <i className="far fa-window-close" />
                                </div>
                            </div>
                            <div className='dropdown-element-container' style={{position: 'relative'}}>
                                {this.renderEventRows()}
                            </div>
                        </div>
                    }
                </div>
                {this.renderPaywallPopup()}
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    getLiveStreams
}

const mapStateToProps = (state: State) => {
    return {
        liveStreams: state.liveStreams.source,
        isLoading: state.liveStreams.isLoading,
        isHdPlayerEnabled: state.user!.restriction.isHdPlayerEnabled,
        isHdHlsEnabled: state.user!.restriction.isHdHlsEnabled,
        isSdHlsEnabled: state.user!.restriction.isSdHlsEnabled,
        isSdPlayerEnabled: state.user!.restriction.isSdPlayerEnabled,
        isHdPlayerPaywallEnabled: state.user!.restriction.isHdPlayerPaywallEnabled,
        isHdHlsPaywallEnabled: state.user!.restriction.isHdHlsPaywallEnabled,
        isSdHlsPaywallEnabled: state.user!.restriction.isSdHlsPaywallEnabled,
        isSdPlayerPaywallEnabled: state.user!.restriction.isSdPlayerPaywallEnabled
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDropdown);
