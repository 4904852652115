import defaultState from "../../defaultState";
import { defaultFilters } from "../../../store/default-filters";
import { setFilters, discardFilters, applyFilters } from "../../actions/filters/routines";

export const filtersReducer = (state = defaultState.filters, action: any) => {
    switch (action.type) {
        case setFilters.SUCCESS: {
            return action.payload;
        }
        case discardFilters.SUCCESS: {
            return defaultFilters;
        }
        case applyFilters.SUCCESS: {
            return { ...state, isApplied: true}
        }
        case applyFilters.FAILURE: {
            return { ...state, isApplied: false}
        }
        default: {
            return state;
        }
    }
}