import defaultState from "../../defaultState";
import { logoutOnDeletedDevice } from "../../actions/devices";

const logoutUserReducer = (state = defaultState.userLogout, action: any) => {
    switch (action.type) {

        case logoutOnDeletedDevice.TRIGGER:
            return action.payload;

        default:
            return state;
    }
}

export default logoutUserReducer;