import {ILogModel} from "../common/types/statistics.type";
import client, {handleError} from '../utils/client';
import env from "../env";


export const startLogRecord = async (log: ILogModel) => {
    try {
        return await client.post(`${env.apiUrl}/api/video-logs`, log);
    } catch (error) {
        return handleError(error)
    }
}

export const stopLogRecord = async (logId) => {
    try {
        return await client.put(`${env.apiUrl}/api/video-logs`, logId);
    } catch (error) {
        return handleError(error)
    }
}

export const addDownloadLog = async (data: { eventId: number }) => {
    try {
        return await client.post(`${env.apiUrl}/api/download-logs`, data);
    } catch (error) {
        return handleError(error);
    }
}

