import defaultState from "../../defaultState";
import { User } from "../../../common/types/user.type";
import { getCurrentUser, logout } from "../../actions/auth/actions";
import { removeDeviceRoutine } from "../../actions/devices";
import {confirmAnnouncementRoutine} from "../../actions/announcements/actions";
import {saveFiltersRoutine} from "../../actions/events/filtered-events";

const userReducer = (state = defaultState.user, action: any) => {
    switch (action.type) {
        case getCurrentUser.SUCCESS: {
            return action.payload as User
        }
        case getCurrentUser.FAILURE: {
            return null;
        }
        case removeDeviceRoutine.TRIGGER: {
            if (state!.devices) {
                return {
                    ...state,
                    devices: state!.devices.filter(x => x.uuid !== action.payload.deviceUuid),
                } as User
            }
            return state;
        }
        case confirmAnnouncementRoutine.SUCCESS: {
            return {
                ...state,
                userAnnouncements: state!.userAnnouncements.map(x => {
                    if(x.id !== action.payload.id) {
                        return x;
                    } else {
                        return {
                            ...x,
                            viewed: true
                        }
                    }
                }),
            }
        }
        case saveFiltersRoutine.SUCCESS: {
            return {
                ...state,
                savedFilters: action.payload,
            } as User
        }
        case logout: {
            return null;
        }

        default: {
            return state;
        }
    }
}

export default userReducer;
