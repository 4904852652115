import { call, put, all, takeLatest } from "redux-saga/effects";
import { getActionsForEventRoutine,
    getActionsForEventByInternalIdsRoutine,
    getActionsForEventByRbId,
    addCurrentlyWatchedEventRoutine,
    removeCurrentlyWatchedEventRoutine
} from "../../actions/runningball";
import { getActionsForEvent,
    getActionsForEventByInternalIds,
    getActionsForEventByRdId,
    addCurrenltyWatchedEventForRb,
    removeCurrenltyWatchedEventForRb
} from "../../../services/rb.service";

function* getRunningBall(action: any) {
    try {
        const response = yield call(getActionsForEvent, action.payload.competitor1, action.payload.competitor2, action.payload.time);
        yield put(getActionsForEventRoutine.success(response.data));
    } catch (err) {
        yield put(getActionsForEventRoutine.failure(err.message));
    }
}

function* getRunningBallByInternalIds(action: any) {
    try {
        const response = yield call(getActionsForEventByInternalIds, action.payload.homeTeamId, action.payload.awayTeamId, action.payload.time);
        yield put(getActionsForEventByInternalIdsRoutine.success(response.data));
    } catch (err) {
        yield put(getActionsForEventByInternalIdsRoutine.failure(err.message));
    }
}

function* getRunningBallByRbId(action: any) {
    try {
        const response = yield call(getActionsForEventByRdId, action.payload.matchId);
        yield put(getActionsForEventByRbId.success(response.data));
    } catch (err) {
        yield put(getActionsForEventByRbId.failure(err.message));
    }
}

function* addCurrentlyWatchedEvent(action: any) {
    const { userConnection, eventId } = action.payload;

    try {
        yield call(addCurrenltyWatchedEventForRb, userConnection, eventId)
        yield put(addCurrentlyWatchedEventRoutine.success());
    } catch (err) {
        yield put(addCurrentlyWatchedEventRoutine.failure());
    }
}

function* removeCurrenltyWatchedEvent(action: any) {
    const { userConnection, eventId } = action.payload;

    try {
        yield call(removeCurrenltyWatchedEventForRb, userConnection, eventId);
        yield put(removeCurrentlyWatchedEventRoutine.success())
    } catch (err) {
        yield put(removeCurrentlyWatchedEventRoutine.failure());
    }
}

export default function* runningBallSaga() {
    yield all([
        takeLatest(getActionsForEventRoutine, getRunningBall),
        takeLatest(getActionsForEventByInternalIdsRoutine, getRunningBallByInternalIds),
        takeLatest(getActionsForEventByRbId, getRunningBallByRbId),
        takeLatest(addCurrentlyWatchedEventRoutine, addCurrentlyWatchedEvent),
        takeLatest(removeCurrentlyWatchedEventRoutine, removeCurrenltyWatchedEvent)

    ]);
}