import defaultState from "../../defaultState";
import {
    loginSubmit,
    fetchApiVersionRoutine,
    askConfirmationRoutine
} from '../../actions/auth/actions'

// Login Reducer
const loginReducer = (state = defaultState.login, action: any) => {
    switch (action.type) {
        case loginSubmit.TRIGGER: {
            return {
                ...state,
                isSuccess: true,
                loading: true
            };
        }
        case loginSubmit.SUCCESS: {
            return {
                ...state,
                isSuccess: true,
                loading: false
            };
        }
        case loginSubmit.FAILURE: {
            return {
                ...state,
                isSuccess: false,
                loading: false,
                ...action.payload
            };
        }
        case askConfirmationRoutine.SUCCESS: {
            return {
                ...state,
                askConfirmation: true,
                loading: false
            }
        }
        case askConfirmationRoutine.FAILURE: {
            return {
                ...state,
                askConfirmation: false
            }
        }
        case fetchApiVersionRoutine.SUCCESS: {
            return {
              ...state,
              apiVersion: action.payload
            };
          } 
        default: {
            return state;
        }
    }
}

export default loginReducer;