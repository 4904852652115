import { call, put, takeLatest } from "redux-saga/effects";
import { fetchApiVersion } from '../../../services/auth.service';
import { fetchApiVersionRoutine } from "../../actions/auth/actions";

function* tryFetchApiVersion() {
    try {
      const apiVersion = yield call(fetchApiVersion);
      yield put(fetchApiVersionRoutine.success(apiVersion));
    }
    catch (error) {
      yield put(fetchApiVersionRoutine.failure())
    }
  }

export default function* appSaga() {            
    yield takeLatest(fetchApiVersionRoutine, tryFetchApiVersion);
}