import React, {Fragment, createRef} from 'react';
import './index.scss';
import { SingleDatePicker } from 'react-dates';
import { Moment } from 'moment';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import jumpToLive from '../../assets/live.png';
import moment from 'moment';
import { connect } from 'react-redux';
import { removeToken } from '../../services/auth.service';
import DoDiagnosis from '../../services/diagnosis.service';
import { history } from "../../router/history";
import OutsideClickhandler from 'react-outside-click-handler';
import env from '../../env';
import { IFiltersForm } from './filters/interfaces/filters-form.interface';
import FiltersComponent from './filters';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import { loadDocument, getFilterValues } from '../../services/api.service';
import CryptoJS from 'crypto-js';
import { logout } from '../../redux/actions/auth/actions';
import { State } from '../../common/types/state.type';
import SportEvent from '../models/sport-event';
import { IFilters } from './filters/interfaces/filters.interface';
import DownloadDropdown from './children/download-dropdown/download-dropdown';
import { Role } from '../../router/roles';
import { distinct, groupBy } from '../../utils/query.utils';
import { setDefaultFilterValues } from '../../redux/actions/filters/routines';
import { defaultScheduleFilters, ScheduleFilters } from '../models/schedule-filters';
import { Restriction } from '../../common/types/restriction.type';
import { loadEventsByFiltersRoutine } from '../../redux/actions/events/filtered-events';
import { toastr, BasicToastrOptions } from 'react-redux-toastr';
import SkyBoxes from "../sky-boxes";
import { lockSkyBoxRoutine } from "../../redux/actions/skyboxes";
import { ISkyBoxState } from "../../common/types/sky-boxes";
import { closeSocket } from '../../redux/actions/sockets';
import StreamSetting from "../types/stream-setting.type";
import InternalStreamsPanel from "../internal-streams-panel";
import Announcements from "../../notifications/announcements/announcements";
import diagnostic from "../../assets/diagnostics-icon.svg";
import news from "../../assets/announcements.png";

export interface SiteHeaderProps {
    isHidden: boolean;
    isTableExpanded: boolean;
    firstLiveEvent: SportEvent;
    filtersData: IFilters;
    user: any;
    selectedDate: Date,
    search: (value: string) => void;
    logout: () => void;
    closeSocket: () => void;
    jumpToLive: (selectedDate: Moment) => void;
    setDefaultFilterValues: (filters: any) => void;
    onDateChange: (date: Moment) => void;
    selectStream: (link: string, playerNumber: number, isRealtime: boolean, isHd: boolean, event: SportEvent) => void;
    loadEventsByFilters: (filters: ScheduleFilters, restriction: Restriction) => void;
    skySportsLink: string;
    filtersApplied: boolean;
    isOpenSkyBoxes: boolean;
    toggleSkyBoxes: () => void;
    appliedFilters: IFiltersForm;
    multicastModalToggle: (internalStream?) => void;
    setActiveBox: (box: any) => void;
    playSkyBoxStream: () => void;
    stopSkyBoxStream: () => void;
    isPlaySkyBoxStream: boolean;
    lockSkyBox: (skyBoxId: string, lockType: number, user) => void;
    skyBoxes: ISkyBoxState[],
    resizeBoxPanelHandler: (boxPanelRef) => void;
    streamSettings: StreamSetting[];
    activeBox: any;
    toggleInternalStreamsPanel: () => void;
    isOpenInternalStreamsPanel: boolean;
    internalStreams: any,
    toggleAnnouncementsModal: () => void;
    setAnnouncementsModalType: (type: boolean) => void;
}

export interface SiteHeaderState {
    isFilterOpened: boolean;
    isDatepickerFocused: boolean;
    selectedDate: Moment | null;
    isSupportModalOpen: boolean;
    groupedValues: any;
    countryFlags: any;
    searchWord: string;
    toggleSkyBoxes: () => void;
    announcementsVisible: boolean;
    isLatest: boolean;
}

type GroupedEvent = {
    id: number,
    sport: string,
    country: string
}

class SiteHeader extends React.Component<SiteHeaderProps, SiteHeaderState> {
    searchHandler: any

    constructor(props: any) {
        super(props);
        this.state = {
            isDatepickerFocused: false,
            isFilterOpened: false,
            selectedDate: moment(this.props.selectedDate),
            isSupportModalOpen: false,
            groupedValues: {},
            countryFlags: [],
            searchWord: '',
            toggleSkyBoxes: this.props.toggleSkyBoxes,
            announcementsVisible: false,
            isLatest: true
        };
    }

    boxPanelRef = createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.props.isHidden) {
            return;
        }

        const mapper = (event: any): GroupedEvent => {
            return {
                id: event.id,
                sport: event.sport,
                country: event.countryName
            }
        }
        getFilterValues().then(events => {
            const countryFlags = (distinct(x => y => x.countryName === y.countryName)(events))
                .reduce((result, event) => {
                    result[event.countryName] = event.countryCode
                    return result;
                }, {});

            const projectedEvents = events.map(mapper);
            const groupedBySport = groupBy(projectedEvents, (ev: { sport: any; }) => ev.sport);
            const groupedByCountry = groupBy(projectedEvents, (ev: { country: any; }) => ev.country);

            this.setState({groupedValues: {groupedBySport, groupedByCountry}, countryFlags})
            const filtersData = {
                sports: Array.from(groupedBySport.keys()).sort(),
                countries: Array.from(groupedByCountry.keys()).sort(),
                services: Array.from(new Set(events.map(x => x.service).sort()))
            };

            this.props.setDefaultFilterValues(filtersData)
        })

        if ((this.props.user!.restriction.isSkyBoxesAllowed || this.props.user!.restriction.isInternalStreamsAllowed) && window.location.pathname === "/schedule") {
            window.addEventListener('resize', () => this.props.resizeBoxPanelHandler(this.boxPanelRef));

            if(window.location.pathname === "/schedule") {
                this.props.resizeBoxPanelHandler(this.boxPanelRef);
            }
        }
        const latestUpdatesAvailable = this.props.user!.userAnnouncements.some(x => !x.viewed);

        if(latestUpdatesAvailable) {
            this.setState({announcementsVisible: !this.state.announcementsVisible})
        }
    }

    componentDidUpdate(prevProps: Readonly<SiteHeaderProps>, prevState: Readonly<SiteHeaderState>, snapshot?: any) {
        if(prevProps.isOpenSkyBoxes !== this.props.isOpenSkyBoxes || prevProps.isOpenInternalStreamsPanel!== this.props.isOpenInternalStreamsPanel){
            this.props.resizeBoxPanelHandler(this.boxPanelRef);
        }

        if(!prevProps.internalStreams.isLoading && prevProps.internalStreams.source.length !== this.props.internalStreams.source.length) {
            if(this.boxPanelRef.current !== null) {
                this.props.resizeBoxPanelHandler(this.boxPanelRef);
            }
        }
        if (prevProps.user.userAnnouncements !== this.props.user!.userAnnouncements) {
            const latestUpdatesAvailable = this.props.user!.userAnnouncements.some(x => !x.viewed);

            if(latestUpdatesAvailable) {
                this.setState({announcementsVisible: true})
            } else {
                this.setState({announcementsVisible: false})
            }
        }
    }

    availableDocumentsTypes = ["pdf", "csv", "xml"];

    logout = async () => {
        await removeToken();
        this.props.closeSocket();
        this.props.logout();
        window.location.href = `${env.apiUrl}/azure/logout?redirectUrl=${window.location.origin}/login`;
    }

    gotoCMS = () => {
        localStorage.setItem('redirectToCms', 'true');
        const data = JSON.stringify({
            accessToken: localStorage.getItem('token') as string
        });
        const hashed = CryptoJS.AES.encrypt(data, env.hashKey);

        window.location.href = `${env.cmsUrl}/t=${hashed.toString()}`
    }

    toggleFilters = () => {
        const {isFilterOpened} = this.state;
        this.setState({isFilterOpened: !isFilterOpened});
    }

    resetDateAndSearchWord = () => {
        this.setState({selectedDate: moment(new Date())});
        this.setState({searchWord: ''});
    }

    getScheduleFiltersModel = (filters: IFiltersForm) => {
        const newStructure: ScheduleFilters = {
            ...defaultScheduleFilters,
            sportsCriteria: filters.sports,
            countriesCriteria: filters.countries,
            servicesCriteria: filters.services,
            showVOD: filters.showVOD,
            showLive: filters.showLive,
            onlyNew: filters.onlyNew,
            searchWord: this.state.searchWord,
        }
        return newStructure
    }

    search = (text: string) => {
        clearTimeout(this.searchHandler);

        this.setState({searchWord: text}, () => {
            this.searchHandler = setTimeout(() => {
                if (text.length && text.length < 3) {
                    return;
                }
                const filters = {...this.props.appliedFilters};
                this.props.loadEventsByFilters(this.getScheduleFiltersModel(filters), this.props.user!.restriction as Restriction);
            }, 1000);
        });
    }

    startDiagnosis = async () => {
        try {
            var options: BasicToastrOptions = {
                className: 'diagnostic-toaster-icon'
            };

            const fastlyLog = prompt('1. (Optional) Submit fastly log from https://www.fastly-debug.com:');

            const description = prompt('2. (Required) Reason for running diagnostics:');
            if(!description)
            {
                alert("Diagnosis was not initiated; please provide explanation for running diagnostics.");
                return;
            }

            toastr.info('Diagnostic running...', 'Do not close this page until completion is confirmed!', options);
            var totalTimeRequired = await DoDiagnosis(description, fastlyLog);
            var ms = parseInt(totalTimeRequired.toString()).toString();
            toastr.info('Diagnostic finished!', 'Total time taken: ' + ms + 'ms', options);
        } catch (error) {
            toastr.error('Diagnostic failed!', error + '');
        }
    }

    jumpToLive = (date: Moment = moment()) => {
        this.props.jumpToLive(date);
    }

    getLogo = (groupName: string) => {
        switch (groupName) {
            case "Bet365": {
                // return '../../inplay-icons/bet365.png';
                return '';
            }
            case "Perform Stats": {
                return '../../inplay-icons/stats_logo.png';
            }
            default: {
                return '';
            }
        }
    }

    openSkySportsNews = () => {
        if (this.props.skySportsLink) {
            const event = SportEvent.createInstance();
            event.sport = '24 Hr'
            this.props.selectStream(this.props.skySportsLink, 1, false, true, event);
        }
    }

    toggleAnnouncementsModal = () => {
        this.setState({announcementsVisible: !this.state.announcementsVisible})
    }

    setAnnouncementsModalType = (value) => {
        this.setState({isLatest: value})
    }

    handleAnnouncementsClick = () => {
        this.setAnnouncementsModalType(false);
        this.toggleAnnouncementsModal();
    }

    render() {
        const {pictureUrl, group, role, restriction} = this.props.user;

        const skySportsButton = restriction.is24HrEnabled &&
            <button
                title="24 Hr"
                id="24 Hr"
                className="header-cntr">
                 <span onClick={this.openSkySportsNews}>
                     <span>
                         <i className="fas fa-futbol header-icon"></i>
                     </span>
                 </span>
            </button>;

        const cmsButton = ![Role.User, Role.BasicUser].includes(role) &&
            <button
                title="CMS"
                id="CMS"
                className="header-cntr">
                <span onClick={this.gotoCMS}>
                    <span>
                        <i className="fas fa-cogs header-icon"></i>
                    </span>
                </span>
            </button>;

        const logo = this.getLogo(group);

        return <div className="header-wrapper">
            {
                this.state.announcementsVisible &&
                <Announcements
                    toggleAnnouncementsModal={this.toggleAnnouncementsModal}
                    latest={this.state.isLatest}
                />
            }
            <div
                id='header'
                className={`d-flex header-container 
                ${!this.props.isTableExpanded ? 'hoverable' : 'show'}`}>
                <div className="header-block" onClick={() => {
                    !window.location.pathname.includes("schedule") && history.push('/')
                }}>
                    <img
                        src={pictureUrl || logo}
                        alt=""
                        style={logo ? {objectFit: 'cover'} : {}}
                        className={'main-logo'}/>
                </div>
                {
                    this.props.isTableExpanded &&
                    <Fragment>
                        <div className='d-flex'>
                            {
                                this.renderSearchInput()
                            }
                            {
                                this.renderDatepicker()
                            }
                            <Button id="jump-live" onClick={() => this.jumpToLive(this.state.selectedDate!)}>
                                <img alt='' src={jumpToLive}/>
                            </Button>
                        </div>
                    </Fragment>
                }
                <div className="cntr-container">
                    {
                        skySportsButton
                    }
                    {
                        cmsButton
                    }
                    {
                        this.renderDownloadDropdown()
                    }
                    {
                        this.renderProfileOptionsDropdown()
                    }
                    {
                        this.renderSupportModal()
                    }
                </div>
            </div>
            {
                this.props.isTableExpanded &&
                <div className="stb-section" ref={this.boxPanelRef}>
                    {
                        this.props.user.restriction.isSkyBoxesAllowed &&
                       window.location.pathname === "/schedule" &&
                        <SkyBoxes
                            isOpenBoxesPanel={this.props.isOpenSkyBoxes}
                            setIsOpenBoxesPanel={this.state.toggleSkyBoxes}
                            multicastModalToggle={this.props.multicastModalToggle}
                            setActiveBox={this.props.setActiveBox}
                            resizeBoxPanelHandler={this.props.resizeBoxPanelHandler}
                            selectStream={this.props.selectStream}
                            streamSettings={this.props.streamSettings}
                        />
                    }
                    {
                        this.props.user.restriction.isInternalStreamsAllowed &&
                        window.location.pathname === "/schedule" &&
                        <InternalStreamsPanel
                            isOpenInternalStreamsPanel={this.props.isOpenInternalStreamsPanel}
                            toggleInternalStreamsPanel={this.props.toggleInternalStreamsPanel}
                            multicastModalToggle={this.props.multicastModalToggle}
                        />
                    }
                </div>
            }
        </div>
    }

    renderDownloads() {
        return (
            <span>
                <i className="fas fa-cloud-download-alt"></i>
            </span>
        )
    }

    renderProfileOptionsDropdown() {
        return (
            <Dropdown>
                <DropdownToggle id="profile-options-dropdown" className="doc-toggle" title="Profile actions">
                    <span>
                        <i className="fas fa-user header-icon"></i>
                    </span>
                </DropdownToggle>
                <Dropdown.Menu className="profile-dropdown">
                    {/* TEMP REMOVE HELP PAGE */}
                    {/* <Dropdown.Item as='div' key={0} className="profile-dropdown-item" onClick={() => history.push('/help')}>
                        <a
                            title="Help">
                            <span>
                                <i className="fas fa-question-circle  header-icon"></i>
                            </span>
                        </a>
                        <p>Help</p>
                    </Dropdown.Item> */}
                    <Dropdown.Item as='div' key={1} className="profile-dropdown-item"
                                   onClick={() => history.push('/profile/information')}>
                        <span
                            title="Profile"
                            id="Profile">
                            <span>
                                <i className="fa fa-key header-icon" aria-hidden="true"></i>
                            </span>
                        </span>
                        <p>Profile</p>
                    </Dropdown.Item>
                    <Dropdown.Item as='div' key={2} className="profile-dropdown-item"
                                   onClick={() => this.setState({isSupportModalOpen: true})}>
                        <span
                            title="Support"
                            id="Support">
                            <span><i className="fas fa-headset header-icon" aria-hidden="true"></i></span>
                        </span>
                        <p>Support</p>
                    </Dropdown.Item>

                    <Dropdown.Item className="profile-dropdown-item" onClick={this.startDiagnosis}>
                        <span
                            title="Diagnostic"
                            id="Diagnostic">
                                <img alt='run' src={diagnostic}></img>
                        </span>
                        <p>Diagnostic</p>
                    </Dropdown.Item>

                    <Dropdown.Item className="profile-dropdown-item" onClick={this.handleAnnouncementsClick}>
                        <span
                            title="Latest News"
                            id="Announcements">
                                <img alt='news' src={news}></img>
                        </span>
                        <p>Latest News</p>
                    </Dropdown.Item>

                    <Dropdown.Item as='div' key={3} className="profile-dropdown-item" onClick={this.logout}>
                        <span
                            title="Log out"
                            id="Logout">
                            <span>
                                <i className="fas fa-sign-out-alt header-icon"></i>
                            </span>
                        </span>
                        <p>Log out</p>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    renderSearchInput() {
        const {isFilterOpened} = this.state;

        return (
            <div className="webflow-style-input">
                <i style={{alignSelf: 'center'}} className="fas fa-search"></i>
                <input
                    placeholder="Search"
                    id="eventSearchBar"
                    onChange={event => this.search(event.target.value)}
                    value={this.state.searchWord}
                    autoComplete="off"
                />
                <div id="filters-outside">
                    <OutsideClickhandler onOutsideClick={() => this.setState({isFilterOpened: false})}>
                        <div className='advanced-filter-btn' onClick={this.toggleFilters}>
                            <i className="fas fa-filter"></i>
                        </div>
                        {
                            isFilterOpened &&
                            <FiltersComponent
                                groupedValues={this.state.groupedValues}
                                countryFlags={this.state.countryFlags}
                                filterValues={this.props.filtersData}
                                searchWord={this.state.searchWord}
                                toggleFilters={this.toggleFilters.bind(this)}
                                resetDateAndSearchWord={this.resetDateAndSearchWord.bind(this)}
                                getScheduleFiltersModel={this.getScheduleFiltersModel.bind(this)}
                            />
                        }
                    </OutsideClickhandler>
                </div>
            </div>
        )
    }

    renderDatepicker() {
        return (
            <SingleDatePicker
                id="date-picker"
                date={this.state.selectedDate}
                focused={this.state.isDatepickerFocused}
                onFocusChange={() =>
                    this.setState({isDatepickerFocused: !this.state.isDatepickerFocused})
                }
                onDateChange={this.changeSelectedDate}
                readOnly={true}
                customInputIcon={''}
                displayFormat={() => 'DD/MM/YYYY'}
                isOutsideRange={() => false}
                numberOfMonths={1}
                hideKeyboardShortcutsPanel={true}/>
        )
    }

    renderSupportModal = () => {
        const handleClose = () => this.setState({isSupportModalOpen: false});

        return <Modal show={this.state.isSupportModalOpen} dialogClassName={'support-modal'} centered
                      onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Contact us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="support-modal-info">
                    Customer Support 24/7 -
                    <a style={{marginLeft: 12}}
                       href="mailto:support@igamemedia.com">support@igamemedia.com</a>
                </div>
                <div className="support-modal-info">
                    Sales –
                    <a style={{marginLeft: 12}}
                       href="mailto:sales@igamemedia.com">sales@igamemedia.com</a>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    }

    renderDownloadDropdown() {
        if (this.props.user.restriction.isDocumentsDownloadEnabled)
            return (
                <DownloadDropdown
                    availableDocumentsTypes={this.availableDocumentsTypes}
                    loadDocument={loadDocument}
                    selectedDate={this.state.selectedDate}
                />
            )
    }

    changeSelectedDate = (date: Moment | null) => {
        this.props.onDateChange(date as Moment);
        this.setState({selectedDate: date});
    }
}

export const mapDispatchToProps = (dispatch: any, ownProps: any) => {
    return {
        logout() {
            dispatch(logout());
        },
        closeSocket() {
            dispatch(closeSocket());
        },
        setDefaultFilterValues(filtersData) {
            dispatch(setDefaultFilterValues.success(filtersData))
        },
        loadEventsByFilters(filters: ScheduleFilters, restriction: Restriction) {
            dispatch(loadEventsByFiltersRoutine({filters, restriction}))
        },
        lockSkyBox(skyBoxId: string, lockType: number, currentUser) {
            dispatch(lockSkyBoxRoutine({skyBoxId, lockType, currentUser}));
        },
    };
}

export function mapStateToProps(state: State) {
    return {
        user: state.user,
        selectedDate: state.events.selectedDate,
        filtersApplied: state.filters.isApplied,
        appliedFilters: state.filters,
        skyBoxes: state.skyBoxes.source,
        internalStreams: state.internalStreams
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteHeader);
