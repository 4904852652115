import React, {useState, useEffect, useRef} from 'react';
import videojs, {VideoJsPlayer} from 'video.js';
import 'video.js/dist/video-js.css';
import './index.scss';
import { throttle } from 'lodash';
import ClipControls from "./clip-controls";
import close from "../assets/close.svg";
import {useDispatch} from "react-redux";
import {clipVodRoutine} from "../redux/actions/vod/routines";

interface IVodClipperProps {
    link: string;
    initialTime: number;
    onClipperClose: () => void;
}

export interface IFrames {
    frameImageUrl: string;
    time: number;
    isLoading: boolean;
}

export const FRAMES_NUMBER = 7;

const VodClipper: React.FunctionComponent<IVodClipperProps> = ({ initialTime, onClipperClose, link }) => {

   /* const link = 'https://inplay-vod-clippings.s3.eu-west-2.amazonaws.com/Development/261/906625-Euro56-1690786800-01_29_40-01_32_28.mp4';*/

    const [startMarker, setStartMarker] = useState(initialTime - 15);
    const [endMarker, setEndMarker] = useState(initialTime + 15);
    const [frames, setFrames] = useState<IFrames[]>([]);
    const [videoDuration, setVideoDuration] = useState<number>(0);
    const [activeMarker, setActiveMarker] = useState<'start' | 'end' | null>(null);
    const [movingMarkerForward, setMovingMarkerForward] = useState<boolean>(false);
    const [markerMovingDistance, setMarkerMovingDistance] = useState(0);
    const [movingSelectionWindowState, setMovingSelectionWindowState] = useState<boolean>(false);
    const [selectionWindowPosition, setSelectionWindowPosition] = useState(0);
    const [fragmentDuration, setFragmentDuration] = useState(30);

    const videoRef = useRef<HTMLVideoElement>(null);
    const playerRef = useRef<VideoJsPlayer | null>(null);
    const timelineRef = useRef<HTMLDivElement>(null);
    const selectionWindowRef = useRef<HTMLDivElement>(null);
    const currentTimeRef = useRef<number>(startMarker);

    const dispatch = useDispatch();

    useEffect(() => {
      setSelectionWindowPosition((timelineRef!.current!.offsetWidth / 2) - (selectionWindowRef!.current!.offsetWidth / 2))
    }, [timelineRef, selectionWindowRef]);

    useEffect(() => {
        if (videoRef.current && link) {
            const player = videojs(videoRef.current, {
                sources: [{
                    src: link,
                    type: 'video/mp4'
                }],
                autoplay: true,
                controls: true,
                controlBar: {
                    progressControl: false,
                    fullscreenToggle: false,
                },
                bigPlayButton: false
            }, function onPlayerReady() {
                setStartMarker(initialTime - 15);
                setEndMarker(initialTime + 15);
            });

            playerRef.current = player;

            player.on('loadedmetadata', () => {
                const duration = player.duration();
                setVideoDuration(Math.round(duration));

                if (initialTime < 15) {
                    setStartMarker(0);
                    setEndMarker(30);
                }
            });

            createFramesForSelectionWindow();

            return () => {
                player.dispose();
            };
        }
    }, [videoRef, link, initialTime]);

    useEffect(() => {
        currentTimeRef.current = startMarker;

        if (videoRef.current && currentTimeRef.current) {
            videoRef.current.currentTime = currentTimeRef.current;
        }
        if (startMarker < 0) {
            setStartMarker(0);
            setEndMarker(30);
        }

        const player = playerRef.current;

        if (player) {
            const timeUpdateHandler = function() {
                if (player.currentTime() >= endMarker) {
                    player.currentTime(startMarker);
                    currentTimeRef.current = startMarker;
                }
            };

            player.on('timeupdate', timeUpdateHandler);

            return () => {
                player.off('timeupdate', timeUpdateHandler);
            };
        }
    }, [startMarker, endMarker, videoDuration]);

    const createFramesForSelectionWindow = () => {
        const visibleFragmentDuration = endMarker - startMarker;
        const framesStepInSeconds = visibleFragmentDuration / (FRAMES_NUMBER - 3);
        const totalFragmentStartTime = startMarker - framesStepInSeconds;

        const video = document.createElement('video');
        video.preload = 'auto';
        video.crossOrigin = 'anonymous';
        video.src = link;

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = 250;
        canvas.height = 140;

        let times: number[] = [];
        let initialFrames: IFrames[] = [];

        for (let i = 0; i < FRAMES_NUMBER; i++) {
            initialFrames.push({
                frameImageUrl: '',
                time: totalFragmentStartTime + i * framesStepInSeconds,
                isLoading: true
            });
            times.push(totalFragmentStartTime + i * framesStepInSeconds);
        }

        setFrames(initialFrames);

        video.onloadedmetadata = () => {
            if (times.length > 0) {
                video.currentTime = times.shift()!;
            }
        };

        video.onseeked = () => {
            context!.drawImage(video, 0, 0, canvas.width, canvas.height);
            const dataUrl = canvas.toDataURL('image/png');

            setFrames((prevFrames) => {
                const newFrames = [...prevFrames];
                const updatingFrameIndex = newFrames.length - times.length - 1;
                const updatingFrame = newFrames[updatingFrameIndex];

                updatingFrame.isLoading = false;
                updatingFrame.frameImageUrl = dataUrl;
                newFrames[updatingFrameIndex] = updatingFrame;

                return newFrames;
            });

            if (times.length !== 0) {
                video.currentTime = times.shift()!;
            }
        };
    };

    const convertSecondsToTimeString = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = secs.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const handleMouseDown = (marker: 'start' | 'end') => (e: React.MouseEvent) => {

        let lastMouseX = e.clientX;
        let lastTime = Date.now();
        setActiveMarker(marker);

        const handleMouseMove = throttle((e: MouseEvent) => {
            if (selectionWindowRef.current) {
                const selectionWindowWidthConverted = selectionWindowRef.current!.clientWidth / window.innerWidth * 100;
                if (selectionWindowWidthConverted > 71) {
                    return;
                }
            }

            const mouseMoveDistance = e.clientX - lastMouseX;
            setMovingMarkerForward(mouseMoveDistance > 0);
            setMarkerMovingDistance((prevPos) => {
                return prevPos + mouseMoveDistance;
            });
            lastMouseX = e.clientX;

            const currentTime = Date.now();
            const timeDiff = (currentTime - lastTime) / 655;
            lastTime = currentTime;

            if (marker === 'start') {
                setStartMarker((prevMarker) => {
                    const newMarker = Math.max(0, prevMarker + 5 * timeDiff * (mouseMoveDistance > 0 ? 1 : -1));
                    if (endMarker - newMarker < 5) {
                        return Math.min(endMarker - 5, prevMarker);
                    } else {
                        return Math.round(newMarker);
                    }
                });
            } else if (marker === 'end') {
                setEndMarker((prevMarker) => {
                    const newMarker = Math.min(videoDuration || Infinity, prevMarker + 5 * timeDiff * (mouseMoveDistance > 0 ? 1 : -1));
                    if (newMarker - startMarker < 5) {
                        return Math.max(startMarker + 5, prevMarker);
                    } else {
                        return Math.round(newMarker);
                    }
                });
            }
        }, 50);

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            setMarkerMovingDistance(0);
            setActiveMarker(null);
            updateCenterPos();
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const updateCenterPos = () => {
        if (timelineRef && selectionWindowRef) {
            const centerPos = (timelineRef!.current!.offsetWidth / 2) - (selectionWindowRef!.current!.offsetWidth / 2);
            setSelectionWindowPosition(centerPos);
        }
    };

    useEffect(() => {
        updateCenterPos();
        window.addEventListener('resize', updateCenterPos);

        return () => {
            window.removeEventListener('resize', updateCenterPos);
        };
    }, []);

    const handleSelectionWindowDown = (e) => {
        setMovingSelectionWindowState(true);
        setFragmentDuration(Math.round(endMarker - startMarker));

        window.ondragstart = () => {
            return false;
        };
    }

    useEffect(() => {
        if (!movingSelectionWindowState) updateCenterPos();
    }, [movingSelectionWindowState]);

    const handleSelectionWindowUp = () => {
        createFramesForSelectionWindow();
        setMovingSelectionWindowState(false);
    };

    const handleSelectionWindowMove = (e) => {
        if (!movingSelectionWindowState) return;

        const maxPos = timelineRef!.current!.offsetWidth - selectionWindowRef!.current!.offsetWidth;
        const newPos = Math.min(Math.max(0, e.pageX - timelineRef!.current!.getBoundingClientRect().left - selectionWindowRef!.current!.offsetWidth / 2), maxPos);

        setSelectionWindowPosition(newPos);

        const totalTimelineWidth = timelineRef.current!.offsetWidth;
        const proportionMoved = (newPos - selectionWindowPosition) / totalTimelineWidth;
        const timeChange = proportionMoved * videoDuration;

        setStartMarker(prevMarker => Math.round(Math.max(0, prevMarker + timeChange)));
        setEndMarker(Math.round(startMarker + fragmentDuration));

        if (endMarker > videoDuration) {
            setStartMarker(videoDuration - fragmentDuration);
            setEndMarker(videoDuration);
        }
        if (startMarker < 0) {
            setStartMarker(0);
            setEndMarker(fragmentDuration);
        }
    };

    const handleConfirmClick = () => {
        dispatch(clipVodRoutine(({
            startTime: convertSecondsToTimeString(startMarker),
            endTime: convertSecondsToTimeString(endMarker),
            link
        })))
    };

    const handleFrameClick = (time: number): void => {
        setStartMarker(time);
    }

    const handleLeftArrowClick = (marker) => {
        if (marker === 'center') {
            setStartMarker(startMarker - 1 );
            setEndMarker(endMarker - 1 )
        } else if (marker === 'start') {
            setStartMarker(startMarker - 1 );
        } else {
            setEndMarker(endMarker - 1 );
        }
    }

    const handleRightArrowClick = (marker) => {
        if (marker === 'center') {
            setStartMarker(startMarker + 1 );
            setEndMarker(endMarker + 1 );
        } else if (marker === 'start') {
            setStartMarker(startMarker + 1 );
        } else {
            setEndMarker(endMarker + 1 );
        }
    }

    return (
        <div className="clipper__underlay">
            <div className="clipper__container">
                <button className="clipper__close-button" onClick={onClipperClose}>
                    <img src={close} alt="close"/>
                </button>
                <div data-vjs-player>
                    <video ref={videoRef} className="video-js" />
                </div>
                <ClipControls
                    onStartMarkerMouseDown={(e) => handleMouseDown('start')(e)}
                    onEndMarkerMouseDown={(e) => handleMouseDown('end')(e)}
                    onConfirmClick={handleConfirmClick}
                    frames={frames}
                    startMarker={startMarker}
                    endMarker={endMarker}
                    activeMarker={activeMarker}
                    movingMarkerForward={movingMarkerForward}
                    timelineRef={timelineRef}
                    selectionWindowRef={selectionWindowRef}
                    convertSecondsToTimeString={convertSecondsToTimeString}
                    onFrameClickHandler={handleFrameClick}
                    movingSelectionWindowState={movingSelectionWindowState}
                    selectionWindowPosition={selectionWindowPosition}
                    onSelectionWindowDown={handleSelectionWindowDown}
                    onSelectionWindowUp={handleSelectionWindowUp}
                    onSelectionWindowMove={handleSelectionWindowMove}
                    markerMovingDistance={markerMovingDistance}
                    onLeftArrowClick={handleLeftArrowClick}
                    onRightArrowClick={handleRightArrowClick}
                />
            </div>
        </div>
    );
};

export default VodClipper;