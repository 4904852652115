import env from '../env'
import client from '../utils/client';

function getTotalTimeForDownload(sizeInKb: number): Promise<number> {
    return new Promise((resolve, reject) => {

        const startDate = new Date().getTime();

        const token = localStorage.getItem('token');
        fetch(env.apiUrl + "/api/Diagnostic/bandwidth/" + sizeInKb + "?_=" + (new Date().getTime()), {headers: { 'Authorization': `Bearer ${token}` }})
            .then(x => {
                if (x.body === null) {
                    console.error('got body null');
                    reject();
                    return;
                }
                const reader = x.body.getReader();
                reader.read().then(function processChunk(y) {
                    if (y.done) {
                        const endDate = new Date().getTime();
                        const totalSeconds = (endDate - startDate);
                        resolve(totalSeconds);
                        return;
                    }
                    return reader.read().then(processChunk);
                });
            });
    });
}

async function getAverageBandwidth() {
    const MB = 1024;
    const startTime = new Date().getTime();
    const metrics = Array();
    const maxTimeForDiagnosis = 1000 * 30;//max diagnosis time is 30 seconds

    for (let i = 1; i <= 10; i++) {
        const mb = await getTotalTimeForDownload(MB * i);
        metrics.push(mb / i);
        if (new Date().getTime() - startTime > maxTimeForDiagnosis) break;
    }
    if (metrics.length === 0) return 0;
    const result = metrics.reduce((a, b) => a + b) / metrics.length;
    //result is how many ms needed for 1MB but we need Mb/s
    return 8000.0 / result;
}


export default async function DoDiagnosis(description: string, fastlyLog: string | null): Promise<number> {
    const startTime = new Date().getTime();
    const bandwidth = await getAverageBandwidth();
    const totalTimeRequired = new Date().getTime() - startTime;
    // @ts-ignore
    const connection = (window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection) as any;

    var nmms1ServerName = '';
    await fetch('https://nmms1.inplayip.tv:80').then(x => x.text()).then(text => nmms1ServerName = text);

    let rtt = 0;
    let downlink = 1000.0 / bandwidth;
    let effectiveType = '';

    if (connection) {
        rtt = connection.rtt;
        downlink = connection.downlink;
        effectiveType = connection.effectiveType;
    }
    if (!rtt) rtt = 0;
    if (!downlink) downlink = 0.0;
    if (!effectiveType) effectiveType = '-';

    await client.post(env.apiUrl + '/api/Diagnostic/report', {
        bandwidth,
        totalTimeRequired,
        rtt,
        effectiveType,
        downlink,
        description,
        nmms1ServerName,
        fastlyLog
    }, {headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }});
    return totalTimeRequired;
}
