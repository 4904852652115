import defaultState from "../../defaultState";
import { getLiveStreams } from "../../actions/live-streams";

const liveStreamReducer = (state = defaultState.liveStreams, action: any) => {
    switch (action.type) {
        case getLiveStreams.TRIGGER: {
            return {
                isLoading: true,
                source: []
            }
        }
        case getLiveStreams.SUCCESS: {
            return {
                isLoading: false,
                source: action.payload
            }
        }
        case getLiveStreams.FAILURE: {
            return {
                isLoading: false,
                source: []
            }
        }
        default: {
            return state;
        }
    }
}

export { liveStreamReducer };