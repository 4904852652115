import {call, put, all, takeLatest} from "redux-saga/effects";
import {
    getSkyBoxListRequest,
    lockSkyBoxRequest,
} from "../../../services/skybox.service";
import {
    getSkyBoxListRoutine,
    lockSkyBoxRoutine,
    updateSkyBoxRoutine,
} from "../../actions/skyboxes";
import {toastr} from "react-redux-toastr";

function* lockSkyBox({ payload }) {
    try {
        const response = yield call(lockSkyBoxRequest, payload.skyBoxId, payload.lockType);
        if (response && response.status === 200) {
            yield put(lockSkyBoxRoutine.success(payload));
        } else {
            toastr.error('Error!', 'Lock action was not performed');
            yield put(lockSkyBoxRoutine.failure());
        }
    } catch(error) {
        yield put(lockSkyBoxRoutine.failure(error.message));
    }
}

function* updateSkyBox({ payload }) {
    try {
        yield put(updateSkyBoxRoutine.success(payload));
    } catch(error) {
        yield put(updateSkyBoxRoutine.failure(error.message));
    }
}

function* getSkyBoxList(action: any) {
    try {
        const boxes = yield call(getSkyBoxListRequest);
        yield put(getSkyBoxListRoutine.success(boxes));
    } catch(error) {
        yield put(getSkyBoxListRoutine.failure(error.message));
    }
}

export default function* skyBoxSaga() {
    yield all([
        takeLatest(lockSkyBoxRoutine, lockSkyBox),
        takeLatest(updateSkyBoxRoutine, updateSkyBox),
        takeLatest(getSkyBoxListRoutine, getSkyBoxList),
    ]);
}
