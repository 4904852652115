import { getHlsConfigRoutine } from "../../actions/hls/index";
import defaultState from "../../defaultState";

const hlsConfigReducer = (state = defaultState.hlsConfig, action: any) => {
    switch (action.type) {
        case getHlsConfigRoutine.SUCCESS:
            return action.payload
        default:
            return state;
    }
}

export default hlsConfigReducer;