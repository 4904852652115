import React from 'react';
import { User } from '../../common/types/user.type';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { Formik, FormikProps } from 'formik';
import { changePass, switchNotificationsReceive } from '../../redux/actions/change-password/index';
import './index.scss';
import * as yup from 'yup';
import { history } from '../../router/history';

export interface ChangePasswordFormProps {
    user: User;
    changePass: any;
    switchNotificationsReceive: any;
}

export interface IFormikProps {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

class ChangePasswordForm extends React.Component<ChangePasswordFormProps> {

    render() {

        const validationSchema = yup.object().shape({
            oldPassword: yup
                .string()
                .label('Old password')
                .required(),
            newPassword: yup
                .string()
                .required()
                .label('New password')
                .min(6, 'Seems a bit short...')
                .matches(/[a-z]/, 'At least one lowercase char')
                .matches(/[A-Z]/, 'At least one uppercase char')
                .max(12, 'Seems too long...'),
            confirmNewPassword: yup
                .string()
                .label('Confirm old password')
                .required()
                .min(6, 'Seems a bit short...')
                .max(12, 'Seems too long...')
                .matches(/[a-z]/, 'At least one lowercase char')
                .matches(/[A-Z]/, 'At least one uppercase char')
                .oneOf([yup.ref('newPassword'), null], 'Password must match'),
        });

        return (
            <div className="form-container">
                <span onClick={() => history.push('/')}>
                    <i className="fas fa-times"></i>
                </span>
                <div className="form-container-internal">
                    <Formik
                        initialValues={
                            {
                                oldPassword: '',
                                newPassword: '',
                                confirmNewPassword: ''
                            }
                        }
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            const model = {
                                oldPassword: values.oldPassword,
                                newPassword: values.newPassword,
                                email: this.props.user.email
                            }
                            this.props.changePass(model);
                            setSubmitting(false);
                        }
                        }
                        render={
                            ({ values, handleSubmit, handleChange, isSubmitting, errors, touched }: FormikProps<IFormikProps>) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <div className="change_label">
                                        Password must be at least 6 characters, no more than 12 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit.
                                    </div>
                                    
                                    <Form.Group controlId="oldPassword">
                                        <Form.Label>Old password</Form.Label>
                                        <div className="flex">
                                            <Form.Control
                                                name="oldPassword"
                                                value={values.oldPassword}
                                                type="password"
                                                placeholder="Input old password"
                                                onChange={handleChange}
                                                isInvalid={!!errors.oldPassword} />
                                                {
                                                    !errors.oldPassword && values.oldPassword
                                                    ?  <i className="fa fa-check" aria-hidden="true"></i>
                                                    :  <i className="fas fa-times"></i>
                                                }
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="newPassword">
                                        <Form.Label>New password</Form.Label>
                                        <div className="flex">
                                            <Form.Control
                                                name="newPassword"
                                                value={values.newPassword}
                                                type="password"
                                                onChange={handleChange}
                                                placeholder="Input new password"
                                                isInvalid={!!errors.newPassword} />
                                                {
                                                    !errors.newPassword && values.newPassword
                                                    ?  <i className="fa fa-check" aria-hidden="true"></i>
                                                    :  <i className="fas fa-times"></i>
                                                }
                                            </div>
                                    </Form.Group>
                                    <Form.Group controlId='confirmNewPassword'>
                                        <Form.Label>Confirm new password</Form.Label>
                                        <div className="flex">
                                            <Form.Control
                                                name="confirmNewPassword"
                                                value={values.confirmNewPassword}
                                                type="password"
                                                onChange={handleChange}
                                                placeholder="Input new password"
                                                isInvalid={!!errors.confirmNewPassword} />
                                                {
                                                    !errors.confirmNewPassword && values.confirmNewPassword
                                                    ?  <i className="fa fa-check" aria-hidden="true"></i>
                                                    :  <i className="fas fa-times"></i>
                                                }
                                        </div>
                                        {
                                            (errors.oldPassword || errors.newPassword || errors.confirmNewPassword) &&
                                            <div className="errors">
                                                Please ensure password matches and adheres to rules
                                            </div>
                                        }
                                        
                                    </Form.Group>
                                    <Button 
                                        className="confirm-button"
                                        type='submit'
                                        disabled={
                                            !(
                                                !errors.confirmNewPassword && values.confirmNewPassword &&
                                                !errors.newPassword && values.newPassword &&
                                                !errors.oldPassword && values.oldPassword
                                            )
                                        }
                                        >
                                        Submit
                                    </Button>
                                </Form >
                            )
                        }
                    />
                </div>
            </div >

        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    changePass,
    switchNotificationsReceive
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
