import React, {useState} from "react";
import {useDispatch} from "react-redux";
import { sendSkyBoxSignal } from '../../redux/actions/sockets';
import StreamSetting from "../types/stream-setting.type";

import "./style.scss";

export interface IRemoteControlProps {
    remoteControlToggle: () => void;
    activeBox: any;
    playerNumber: number;
    streamSettings: StreamSetting[];
    skyBox: any;
}

const RemoteControl: React.FunctionComponent<IRemoteControlProps> = ({
    remoteControlToggle,
    streamSettings,
    skyBox
    }) => {

    const dispatch = useDispatch();

    const [indicatorClass, setIndicatorClass] = useState("upper-block__indicator");
    const [channelNumber, setChannelNumber] = useState("000");
    const [switchDelay, setSwitchDelay] = useState(false);
    const [channelsPadVisible, setChannelsPadVisible] = useState(false);

    const sendSignal = (signal: string) => {
        dispatch(sendSkyBoxSignal({skyBox, signal}));
        flashIndicator();
    }

    const sendNumericSignal = (signal: string) => {
        setChannelsPadVisible(true);
        if (!switchDelay) {
            setChannelNumber(signal);
        }
        if (switchDelay) {
            if (channelNumber.length === 3) {
                setChannelNumber(signal);
            } else {
                setChannelNumber(channelNumber + signal)
            }
        }
        setSwitchDelay(true);
        setTimeout(() => {
            setSwitchDelay(false);
        }, 3000)
        dispatch(sendSkyBoxSignal({skyBox, signal}));
        flashIndicator();
        setTimeout(() => {
            setChannelsPadVisible(false)
        }, 5000)
    }

    const flashIndicator = () => {
        setIndicatorClass("upper-block__indicator active");

        setTimeout(() => {
            setIndicatorClass("upper-block__indicator");
        }, 500)
    }

    const setRemoteSizeClass = () => {
        if (streamSettings.length > 2) {
            return "-small";
        } else {
            return "";
        }
    }

    return(
        <div className={`remote${setRemoteSizeClass()}`}>
            {
                channelsPadVisible &&
                <div className="channel-pad">CH - {channelNumber}</div>
            }
            <div className={`remote${setRemoteSizeClass()}-container`}>

                <div className="upper-block">
                    <img src={process.env.PUBLIC_URL + 'service-icons/close-remote.svg'}
                         alt="close"
                         className="upper-block__close-img"
                         onClick={remoteControlToggle}/>
                    <button className="remote-btn upper-block__tv-btn">
                        TV
                    </button>
                    <div className={indicatorClass}></div>
                    <button className="remote-btn upper-block__sky-btn"
                            name="SKY"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        <img src={process.env.PUBLIC_URL + 'service-icons/sky-logo.svg'} alt="sky" className="upper-block__sky-img"/>
                    </button>
                    <button className="remote-btn upper-block__switch-btn"
                            name="Power"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        <img src={process.env.PUBLIC_URL + 'service-icons/switch.svg'} alt="switch" className="upper-block__switch-img"/>
                    </button>
                </div>

                <div className="menu">
                    <button className="remote-btn menu__box-office-btn">
                        Box Office
                    </button>
                    <button className="remote-btn menu__services-btn"
                            name="Services"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        Services
                    </button>
                    <button className="remote-btn menu__tv-guide-btn"
                            name="TV_Guide"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        TV Guide
                    </button>
                    <button className="remote-btn menu__interactive-btn">
                        Interactive
                    </button>
                    <button className="remote-btn menu__round-btn menu__mute-btn"
                            name="Mute"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        Mute
                    </button>
                    <button className="remote-btn menu__round-btn menu__info-btn">
                        i
                    </button>
                </div>

                <div className="controls">
                    <div className="controls__volume controls__vertical-btn">
                        Vol
                        <button className="remote-btn controls__round-btn controls__vertical-upper-btn"
                                name="Vol +"
                                onClick={(e) => sendSignal(e.currentTarget.name)}>
                            +
                        </button>
                        <button className="remote-btn controls__round-btn controls__vertical-lower-btn"
                                name="Vol -"
                                onClick={(e) => sendSignal(e.currentTarget.name)}>
                            -
                        </button>
                    </div>
                    <div className="controls__central-pad">
                    </div>
                    <button className="remote-btn controls__up-btn controls__corner-btn"
                            name="Up"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        <img src={process.env.PUBLIC_URL + 'service-icons/arrow.svg'} alt="up" className="up-triangle-img"/>
                    </button>
                    <button className="remote-btn controls__left-btn controls__corner-btn"
                            name="Left"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        <img src={process.env.PUBLIC_URL + 'service-icons/arrow.svg'} alt="left" className="left-triangle-img"/>
                    </button>
                    <button className="remote-btn controls__select-btn"
                            name="Select"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        Select
                    </button>
                    <button className="remote-btn controls__right-btn controls__corner-btn"
                            name="Right"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        <img src={process.env.PUBLIC_URL + 'service-icons/arrow.svg'} alt="right" className="right-triangle-img"/>
                    </button>
                    <button className="remote-btn controls__down-btn controls__corner-btn"
                            name="Down"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        <img src={process.env.PUBLIC_URL + 'service-icons/arrow.svg'} alt="down" className="down-triangle-img"/>
                    </button>
                    <div className="controls__channel controls__vertical-btn">
                        Ch
                        <button className="remote-btn controls__round-btn controls__vertical-upper-btn"
                                name="Channel+"
                                onClick={(e) => sendSignal(e.currentTarget.name)}>+</button>
                        <button className="remote-btn controls__round-btn controls__vertical-lower-btn"
                                name="Channel-"
                                onClick={(e) => sendSignal(e.currentTarget.name)}>-</button>
                    </div>
                </div>

                <div className="player-menu">
                    <button className="remote-btn player-menu__round-btn player-menu__text-btn">
                        Text
                    </button>
                    <button className="remote-btn player-menu__back-up-btn"
                            name="Backup"
                            onClick={(e) => sendSignal(e.currentTarget.name)}>
                        Back up
                    </button>
                    <button className="remote-btn player-menu__round-btn player-menu__help-btn">
                        Help
                    </button>
                    <button className="remote-btn player-menu__back-btn">
                        <img src={process.env.PUBLIC_URL + 'service-icons/doubled-arrow.svg'} alt="back" className="back-img"/>
                    </button>
                    <div className="player-menu__big-btn">
                        <button className="remote-btn player-menu__pause-btn">
                            <img src={process.env.PUBLIC_URL + 'service-icons/pause.svg'} alt="pause" className="pause-img"/>
                            <img src={process.env.PUBLIC_URL + 'service-icons/pause.svg'} alt="pause" className="pause-img"/>
                        </button>
                        <button className="remote-btn player-menu__play-btn">
                            <img src={process.env.PUBLIC_URL + 'service-icons/play.svg'} alt="play" className="play-img"/>
                        </button>
                    </div>
                    <button className="remote-btn player-menu__forward-btn">
                        <img src={process.env.PUBLIC_URL + 'service-icons/doubled-arrow.svg'} alt="forward" className="forward-img"/>
                    </button>
                    <button className="remote-btn player-menu__round-btn player-menu__record-btn">
                        <div className="record-circle">R</div>
                    </button>
                    <button className="remote-btn player-menu__round-btn player-menu__stop-btn">
                        <div className="stop-square"></div>
                    </button>
                </div>

                <div className="colored-pad">
                    <button className="colored-btn colored-pad__red-btn"></button>
                    <button className="colored-btn colored-pad__green-btn"></button>
                    <button className="colored-btn colored-pad__yellow-btn"></button>
                    <button className="colored-btn colored-pad__blue-btn"></button>
                </div>
                <div className="numeric-keypad">
                    <button className="remote-btn numeric-keypad__btn"
                            name="1"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">1</div>
                        <div className="alphabet">___</div>
                    </button>
                    <button className="remote-btn numeric-keypad__btn"
                            name="2"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">2</div>
                        <div className="alphabet">ABC</div>
                    </button>
                    <button className="remote-btn numeric-keypad__btn"
                            name="3"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">3</div>
                        <div className="alphabet">DEF</div>
                    </button>
                    <button className="remote-btn numeric-keypad__btn"
                            name="4"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">4</div>
                        <div className="alphabet">GHI</div>
                    </button>
                    <button className="remote-btn numeric-keypad__btn"
                            name="5"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">5</div>
                        <div className="alphabet">JKL</div>
                    </button>
                    <button className="remote-btn numeric-keypad__btn"
                            name="6"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">6</div>
                        <div className="alphabet">MNO</div>
                    </button>
                    <button className="remote-btn numeric-keypad__btn"
                            name="7"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">7</div>
                        <div className="alphabet">PQRS</div>
                    </button>
                    <button className="remote-btn numeric-keypad__btn"
                            name="8"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">8</div>
                        <div className="alphabet">TUV</div>
                    </button>
                    <button className="remote-btn numeric-keypad__btn"
                            name="9"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">9</div>
                        <div className="alphabet">WXYZ</div>
                    </button>
                    <button className="remote-btn numeric-keypad__btn"
                            name="0"
                            onClick={(e) => sendNumericSignal(e.currentTarget.name)}>
                        <div className="number">0</div>
                        <img src={process.env.PUBLIC_URL + 'service-icons/sound.svg'} alt="sound" className="sound-img"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RemoteControl;
