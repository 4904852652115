import defaultState from "../../defaultState";
import {
    addInternalStreamRoutine,
    deleteInternalStreamRoutine, getInternalStreamsRoutine,
    updateInternalStreamRoutine
} from "../../actions/internal-streams";

const internalStreamsReducer = (state = defaultState.internalStreams, action: any) => {
    switch (action.type) {
        case getInternalStreamsRoutine.TRIGGER:
            return {
                ...state,
                source: state.source,
                isLoading: true
            }
        case getInternalStreamsRoutine.SUCCESS:
            return {
                ...state,
                source: action.payload,
                isLoading: false
            }
        case addInternalStreamRoutine.SUCCESS:
            return {
                ...state,
                source: [
                    ...state.source,
                    action.payload
                ]
            }
        case updateInternalStreamRoutine.SUCCESS:
            const index = state.source.findIndex(item => item.id === action.payload.id);
            return {
                ...state,
                source: [
                    ...state.source.slice(0, index),
                    action.payload,
                    ...state.source.slice(index + 1)
                ]
            }
        case deleteInternalStreamRoutine.SUCCESS:
            return {
                ...state,
                source: state.source.filter(x => x.id !== action.payload)
            }
        default:
            return state;
    }
}

export default internalStreamsReducer;
