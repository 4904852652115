import defaultState from "../../defaultState";
import { setJumpToLive } from "../../actions/jumpToLive/actions";

export const isJumpToLiveReducer = (state = defaultState.isJumpToLive, action: any) => {
    switch (action.type) {
        case setJumpToLive.SUCCESS:
            return true;
        case setJumpToLive.FULFILL:
            return false;
        default: {
            return state;
        }
    }
}