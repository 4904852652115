import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import skyLogo from "../../../../assets/skybox-icons/SkyLogo.svg";
import chevron from "../../../../assets/skybox-icons/chevron.png";
import skyUserLogo from "../../../../assets/skybox-icons/skyBoxUserImg.svg";
import unlockIcon from "../../../../assets/skybox-icons/unlock-icon.svg";
import lockIcon from "../../../../assets/skybox-icons/lock-icon.svg";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../common/types/state.type";
import {lockSkyBoxRoutine} from "../../../../redux/actions/skyboxes";
import SessionButton from "../session-button/SessionButton";
import PlayersDropdown from "../../../table/body/children/players-dropdown/players-dropdown";
import SportEvent from "../../../models/sport-event";
import StreamSetting from "../../../types/stream-setting.type";
import {toastr} from "react-redux-toastr";

import './style.scss';
import {Role} from "../../../../router/roles";

interface ISkyBoxProps {
    skyBoxId: string;
    skyBoxNumber: number;
    lockType: number;
    lockedAt: string;
    userName: string;
    userEmail: string;
    userId: number;
    multicastModalToggle: () => void;
    setActiveBox: (skyBox: any) => void;
    skyBox: any;
    output: number;
    streamSettings: StreamSetting[];
    selectStream: (link: string, playerNumber: number, isRealtime: boolean, isRtmp: boolean, event: SportEvent) => void;
    key: number;
}

export interface ISessionTypeData {
    lockName: string;
    lockValue: number;
    labelPosition: number;
    labelName: string;
    icon: string;
}

const SkyBox: FunctionComponent<ISkyBoxProps> = ({
    skyBoxNumber,
    skyBoxId,
    multicastModalToggle,
    userName,
    lockedAt,
    lockType,
    setActiveBox,
    skyBox,
    userEmail,
    streamSettings,
    selectStream,
}) => {

    const dispatch = useDispatch();
    const screens = useSelector((state: State) => state.screens.source);

    const sessionTypeData: ISessionTypeData[] = [
        {
            lockName: "unlock",
            lockValue: 0,
            labelPosition: 21,
            labelName: "Unlocked",
            icon: unlockIcon
        },
        {
            lockName: "end-session",
            lockValue: -1,
            labelPosition: 43,
            labelName: "End of session",
            icon: lockIcon
        },
        {
            lockName: "30-minutes",
            lockValue: 30,
            labelPosition: 65,
            labelName: "30 minutes",
            icon: lockIcon
        },
        {
            lockName: "60-minutes",
            lockValue: 60,
            labelPosition: 87,
            labelName: "60 minutes",
            icon: lockIcon
        },
        {
            lockName: "90-minutes",
            lockValue: 90,
            labelPosition: 111,
            labelName: "90 minutes",
            icon: lockIcon
        },
    ]

    const [progressWidth, setProgressWidth] = useState<string>("");
    const [lockProperties, setLockProperties] = useState(sessionTypeData[0]);

    const scrollBallRef = useRef<HTMLDivElement>(null);
    const currentUser = useSelector((state: State) => state.user);

    const hasAccessRights = currentUser!.role === Role.ClientAdmin || currentUser!.role === Role.Admin || currentUser!.role === Role.SuperAdmin;
    const playersList = currentUser!.restriction.isMaxPlayersEnabled ? [1, 2, 3, 4] : [1, 2];
    const item = SportEvent.createInstance();
    item.wssLink = skyBox.wssStreamLink;
    item.streamLink = skyBox.hlsStreamLink;
    item.webRTCSource = "Us2";

    const setSessionDataObject = (lockType) => {
        switch (lockType) {
            case 0:
                setLockProperties(sessionTypeData[0])
                break;
            case -1:
                setLockProperties(sessionTypeData[1])
                break;
            case 30:
                setLockProperties(sessionTypeData[2])
                break;
            case 60:
                setLockProperties(sessionTypeData[3])
                break;
            case 90:
                setLockProperties(sessionTypeData[4])
                break;
            default:
                setLockProperties(sessionTypeData[5])
        }
    }
    useEffect(() => {
        setSessionDataObject(lockType);
    }, [lockType])

    useEffect(() => {
        updateSessionType(lockProperties.labelPosition, lockType);
    }, [lockType, lockProperties.labelPosition])

    useEffect(() => {
        let intervalId;

        if (lockProperties.lockValue !== 0 && lockProperties.lockValue !== -1 && !skyBox.loading) {
            intervalId = setInterval(() => {
                calculateSessionProgress();
            }, 500)
        } else {
            setProgressWidth("90%");
        }
        return () => clearInterval(intervalId);
    }, [lockProperties.lockValue, skyBox.loading]);

    const changeScrollPosition = (btnPosition: number) => {
        if (scrollBallRef.current) {
            scrollBallRef.current!.style.top = btnPosition - 20.5 + "px";
        }
    }

    const updateSessionType = (btnPosition: number, lockType) => {
        setSessionDataObject(lockType);
        changeScrollPosition(btnPosition);
    }

    const setSessionTypeManually = (btnPosition: number, lockType) => {
        if (userName.length === 0) {
            return;
        }
        if (userEmail !== currentUser!.email && lockProperties.lockValue !== 0 && !hasAccessRights) {
            toastr.error("Sorry!", "You are not allowed to perform this action");
            return;
        }
        dispatch(lockSkyBoxRoutine({skyBoxId, lockType, currentUser}));
    }

    const setSessionProgressClass = (btnSession: string, activeSession: string | null) => {
        return btnSession === activeSession
            ? "box-session-controls__progress-bar box-session-controls__progress-bar-active"
            : "box-session-controls__progress-bar"
    }

    const setSessionBtnIconClass = (btnSession: string, activeSession: string | null) => {
        return btnSession === activeSession
            ? "box-session-controls__btn-icon box-session-controls__btn-icon-active"
            : "box-session-controls__btn-icon"
    }

    const startUsingSkyBox = () => {
        setActiveBox(skyBox);
        if (userEmail === currentUser!.email) {
            return;
        }
        if (!userEmail) {
            dispatch(lockSkyBoxRoutine({skyBoxId, lockType: 0, currentUser}));
        }
        if (lockProperties.lockValue === 0 || hasAccessRights) {
            dispatch(lockSkyBoxRoutine({skyBoxId, lockType: lockType, currentUser}));
        }
    }

    const calculateSessionProgress = () => {
        if (lockProperties.lockValue === 0 || lockProperties.lockValue === -1) {
            setProgressWidth("90%");
            return;
        } else {
            const currentTime = Date.now();
            const lockedAtTime = Date.parse(lockedAt);
            const timeDiff = currentTime - lockedAtTime;
            const endTime = lockedAtTime + lockProperties.lockValue * 60 * 1000;

            if (endTime <= currentTime) {
                if (skyBox.loading) {
                    return;
                } else {
                    if (userEmail === currentUser!.email) {
                        dispatch(lockSkyBoxRoutine({skyBoxId, lockType: 0, currentUser}));
                    }
                }
                return;
            }

            switch (lockType) {
                case 30:
                    setProgressWidth(`${timeDiff/1800000 * 90}%`);
                    return;
                case 60:
                    setProgressWidth(`${timeDiff/3600000 * 90}%`);
                    return;
                case 90:
                    setProgressWidth(`${timeDiff/5400000 * 90}%`);
                    return ;
                default:
                    setProgressWidth("90%");
            }
        }
    }

    const onMulticastModalToggle = () => {
        setActiveBox(skyBox);
        multicastModalToggle();
    }

    const isPlayerSelectable = (playerNumber: number) => {
        return playerNumber > streamSettings.filter(x => !x.isVod).length + 1;
    }

    return (
        <div id="sky-box" className="sky-box">
            {
                skyBox.loading &&
            <div className="loader-overlay">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            }
            {
                skyBoxId === "" &&
                <div className="not-available-overlay">
                  N/A
                </div>
            }
            <div className="sky-box-left-side">
                <div className="box-name-and-screens-panel">
                    <div className="box-title">
                        <div className="box-title__logo"><img src={skyLogo} alt={"logo"}/></div>
                        <p className="box-number">BOX {skyBoxNumber}</p>
                    </div>
                    <div className="box-screens-panels" onClick={onMulticastModalToggle}>
                        {
                            screens.length === 0 ?
                            <div className="multicast-loader-overlay" style={{margin: "0 auto"}}>
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div> :
                            <>
                                <div className="screens-panels-icon">
                                    <img
                                        className={"screens-panels-icon-img"}
                                        src={chevron} alt={"icon"}/>
                                </div>
                                <div className="box-screens-panels__screen-icon"/>
                                <div className="box-screens-panels__screen-icon"/>
                                <div className="box-screens-panels__screen-icon center-screen-icon"/>
                            </>
                        }
                    </div>
                </div>
                <div className="box-user-info">
                    <div className="box-user-info__img-box">
                        <img alt={"logo"} className="box-user-info__user-img" src={skyUserLogo}/>
                        <div className={`box-user-info__status ${userName && "box-user-info__status_active"}`}/>
                    </div>
                    <p className="box-user-info__status-text">{userName ? userName : "Not in Use"}</p>
                </div>
            </div>
            <div className="sky-box-right-side">
                <div className="box-session-controls">
                    {
                        sessionTypeData.map((item, index) => (
                            <SessionButton
                                data={item}
                                progress={progressWidth}
                                setSessionTypeManually={setSessionTypeManually}
                                setSessionProgressClass={setSessionProgressClass}
                                setSessionBtnIconClass={setSessionBtnIconClass}
                                key={index}
                                lockProperties={lockProperties}
                            />
                        )
                    )
                    }
                </div>
                <div className="skybox-custom-scroll">
                    <div ref={scrollBallRef} className="skybox-custom-scroll__ball"/>
                </div>
            </div>
            <PlayersDropdown
                playersList={playersList}
                item={item}
                user={currentUser}
                isPlayerSelectable={isPlayerSelectable}
                selectStream={selectStream}
                isPlaySkyBoxDropDown={true}
                startUsingSkyBox={startUsingSkyBox}
                skyBox={skyBox}
                streamSettings={streamSettings}
            />
        </div>);
};

export default React.memo(SkyBox);
