import React from "react";
import { connect } from "react-redux";
import BlankPlayer from "../../player/blank-player";
import VideoJSPlayer from "../../player/video-js-player";
import SLDPPlayer from "../../player/sldp-ws-player";
import SportEvent from "../models/sport-event";
import EventNotification from "../../notifications/event-notification/event-notification";
import StreamSetting from "../types/stream-setting.type";
import { State } from "../../common/types/state.type";
import { isOpenEvent } from "../services/shared.service";

import './index.scss';
import {ISkyBoxState} from "../../common/types/sky-boxes";

export interface PlayersContainerProps {
    streamSettings: StreamSetting[];
    height: number;
    width: number;
    events: {
        source: SportEvent[] | null;
        isLoading: boolean;
    }
    selectStream: (link: string, playerNumber: number, isRealtime: boolean, isHd: boolean, event?: SportEvent, skyBoxStreamLink?: string) => void;
    onPlayerClose: (playerNumber: number) => void;
    multicastModalToggle: () => void;
    skyBoxWssStreamLink: string;
    isPlaySkyBoxStream: boolean;
    activeSkyBox: ISkyBoxState;
    isFullscreenToggle: (isFull: boolean) => void;
    isFullscreen: boolean;
};

class PlayersContainer extends React.Component<PlayersContainerProps, any> {

    render() {
        const { width, height, streamSettings } = this.props;
        const openEvents = this.getOpenEvents(this.props.events.source!);

        return (
            <div className="players-container">
                <EventNotification />
                {
                    streamSettings.length
                        ? streamSettings.map((s, i) => {
                            const { height: pHeight, width: pWidth, isPart } = this.setPlayerSize(streamSettings.length, height, width);
                            s.height = pHeight;
                            s.width = pWidth;

                            if (!s.source) {
                                return (
                                    <BlankPlayer
                                        playerNumber={i + 1}
                                        key={s.key}
                                        width={s.width}
                                        height={s.height}
                                        isPart={isPart}
                                        openEvents={openEvents}
                                        onEventSelect={this.props.selectStream}
                                        onPlayerClose={this.props.onPlayerClose}
                                        streamSettings={this.props.streamSettings}
                                    />
                                )
                            }

                            return s.isRealtime ? <SLDPPlayer
                                    playerNumber={i + 1}
                                    key={s.key}
                                    isRealtime={s.isRealtime}
                                    width={s.width}
                                    height={s.height}
                                    isPart={isPart}
                                    isHd={s.isHd}
                                    source={s.source}
                                    options={s}
                                    event={s.event}
                                    openEvents={openEvents}
                                    onChangePlayer={this.props.selectStream}
                                    onPlayerClose={this.props.onPlayerClose}
                                    streamSettings={this.props.streamSettings}
                                    multicastModalToggle={this.props.multicastModalToggle}
                                    isPlaySkyBoxStream={this.props.isPlaySkyBoxStream}
                                    activeSkyBox={this.props.activeSkyBox}
                                    isFullscreenToggle={this.props.isFullscreenToggle}
                                />
                                : <VideoJSPlayer
                                    playerNumber={i + 1}
                                    key={s.key}
                                    isRealtime={s.isRealtime}
                                    width={s.width}
                                    height={s.height}
                                    isPart={isPart}
                                    isHd={s.isHd}
                                    source={s.source}
                                    event={s.event}
                                    options={s}
                                    isVod={s.isVod}
                                    openEvents={openEvents}
                                    onChangePlayer={this.props.selectStream}
                                    onPlayerClose={this.props.onPlayerClose}
                                    streamSettings={this.props.streamSettings}
                                    multicastModalToggle={this.props.multicastModalToggle}
                                    isPlaySkyBoxStream={this.props.isPlaySkyBoxStream}
                                    activeSkyBox={this.props.activeSkyBox}
                                    isFullscreenToggle={this.props.isFullscreenToggle}
                                />
                        })
                        : <div style={{ width, height, backgroundColor: 'black', zIndex: 1 }}></div>
                }
            </div>)
    }

    setPlayerSize(streamsCount: number, height: number, width: number): { height: number, width: number, isPart: boolean } {
        switch (streamsCount) {
            case 2:
                return { height, width: width / 2 ,isPart: true }
            case 3:
            case 4:
                return { height: height / 2, width: width / 2, isPart: true }
            default:
                return { height, width, isPart: false }
        }
    }

    getOpenEvents = (events: SportEvent[]): SportEvent[] => {
        return events.filter(isOpenEvent);
    }
}

export const mapStateToProps = (state: State, ownProps: any) => {
    const { events } = state;
    return {
        events
    };
}

export default connect(mapStateToProps, null)(PlayersContainer);
