import { createRoutine } from "redux-saga-routines";

export const loginSubmit = createRoutine('LOGIN_SUBMIT')
export const getCurrentUser = createRoutine('GET_CURRENT_USER');
export const reset = createRoutine('RESET');
export const logout = createRoutine('LOGOUT');
export const setResetInitPassword = createRoutine('SET_INIT_PASSWORD');
export const resetPasswordRoutine = createRoutine('RESET_PASSWORD');
export const fetchApiVersionRoutine = createRoutine('FETCH_API_VERSION');
export const askConfirmationRoutine = createRoutine('ASK_CONFIRMATION');
