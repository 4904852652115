import {combineReducers} from "redux";
import loginReducer from './reducers/login'
import userReducer from "./reducers/user";
import {eventsReducer} from "./reducers/events";
import {reducer as toastrReducer} from 'react-redux-toastr';
import {isJumpToLiveReducer} from "./reducers/jumpToLive";
import {filtersReducer} from "./reducers/filters/filtersReducer";
import {vodReducer} from "./reducers/vod/vod";
import {filterValuesReducer} from "./reducers/filters/filterValuesReducer";
import switchNotificationReducer from "./reducers/notifications";
import {errorHandlerReducer} from "./reducers/errors";
import isConnectedReducer from "./reducers/is-connected";
import {signInTimeReducer} from "./reducers/signInTime";
import {runningBallReducer} from "./reducers/runningball";
import {liveStreamReducer} from "./reducers/live-streams";
import {rtmpReducer} from "./reducers/rtmp";
import {aliasesReducer} from "./reducers/aliases";
import hlsConfigReducer from "./reducers/hls";
import {filteredEventsReducer} from "./reducers/events/filtered-events";
import appReducer from "./reducers/app";
import logoutUserReducer from "./reducers/devices";
import skyBoxesReducer from "./reducers/sky-boxes";
import screensReducer from "./reducers/screens";
import internalStreamsReducer from "./reducers/internal-streams";
import sessionReducer from "./reducers/session";

export default combineReducers(
    {
        app: appReducer,
        login: loginReducer,
        user: userReducer,
        events: eventsReducer,
        toastr: toastrReducer,
        isJumpToLive: isJumpToLiveReducer,
        filters: filtersReducer,
        filterValues: filterValuesReducer,
        filteredEvents: filteredEventsReducer,
        vod: vodReducer,
        isNotifictionsChangeSuccess: switchNotificationReducer,
        error: errorHandlerReducer,
        isConnected: isConnectedReducer,
        signInTime: signInTimeReducer,
        runningBallActions: runningBallReducer,
        liveStreams: liveStreamReducer,
        rtmp: rtmpReducer,
        streamAliases: aliasesReducer,
        hlsConfig: hlsConfigReducer,
        skyBoxes: skyBoxesReducer,
        screens: screensReducer,
        userLogout: logoutUserReducer,
        internalStreams: internalStreamsReducer,
        sessionId: sessionReducer
    });
