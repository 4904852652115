import { State } from "../common/types/state.type";
import { defaultFilters } from "../store/default-filters";

const state: State = {
    login: {
        isSuccess: false,
        askConfirmation: false,
        loading: false
    },
    user: null,
    runningBallActions: [],
    userLogout: {
        userId: 0,
        deviceUid: ''
    },
    events: {
        source: [],
        isLoading: true,
        isTopScrollLoading: false,
        isBottomScrollLoading: false,
        selectedDate: new Date()
    },
    isJumpToLive: false,
    filteredEvents: {
        source: [],
    },
    filters: defaultFilters,
    filterValues: {
        sports: [],
        countries: [],
        services: [],
        default: {
            sports: [],
            countries: [],
            services: []
        }
    },
    vod: '',
    error: '',
    isConnected: false,
    logs: [],
    signInTime: '',
    liveStreams: {
        isLoading: false,
        source: []
    },
    rtmp: [],
    streamAliases: [],
    hlsConfig: null,
    app : {apiVersion: ' ' },
    skyBoxes: {
        source: [],
        isLoading: false
    },
    screens: {
        source: [],
        isLoading: false
    },
    internalStreams: {
        source: [],
        isLoading: false
    },
    sessionId: ''
};

export default state;


