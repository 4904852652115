import client, {handleError} from "../utils/client";
import env from "../env";
import {IScreenListUpdate} from "../common/types/screens";

export const getScreensListRequest = async (): Promise<IScreenListUpdate> => {
    try {
        const response = await client.get(`${env.apiUrl}/api/multicasts/screens`);
        return response.data.data || [];
    }
    catch (error) {
        return handleError(error) || [];
    }
}

export const sendStreamToScreenRequest = async (skyBoxId: string, screenIds: string[], locked: boolean) => {
    try {
        const response = await client.put(`${env.apiUrl}/api/skyboxes/multicast`, {
            skyBoxId,
            screenIds,
            locked
        });
        return response;
    }
    catch (error) {
        console.log(error);
    }
}

export const updateScreenNameRequest = async (screenId: string, name: string) => {
    try {
        return await client.put(`${env.apiUrl}/api/multicasts/screens/${screenId}`, {name});
    }
    catch (error) {
        console.log(error);
    }
}
