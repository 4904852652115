
export const latencies = {
    STREAM_START_LATENCY: 15 * 60,
    RB_LATENCY: 1,
    HLS_LATENCY: 6,
    LATENCY_TEMP: 28
} // seconds

export const eventCodes = {
    START_GAME: [10, 11, 0],
    START_SECOND_HALF: [2, 12, 13],
    STOP_FIRST_HALF: [1],
    END_GAME: [20],
    END_SECOND_HALF: [3],
    START_ET: [14, 15, 4],
    STOP_FIRST_HALF_ET: [5, 7],
    START_SECOND_HALF_ET: [16, 17, 6],
    CONFIRMED_EVENTS: [1065, 2089, 1068, 2092],
    CANCELED_EVENTS: [1030, 2054],
    GOAL_HOME: 1029,
    GOAL_AWAY: 2053,
};

export const tickerStateCodes = {
    firstHalf: 2,
    secondHalf: 8,
    pause: 4,
    firstHalfOvertime: 64,
    pauseOvertime: 128,
    secondHalfOvertime: 256,
    finished: 16,
    regularTimeFinished: 32
}

export const times = {
    _45min: 3000000,
    _90min: 6000000,
    _30min: 1800000,
    _15min: 900000,
    _60seconds: 60000,
    vodLength: 9600000
}