type Environment = {
    apiUrl: string,
    cmsUrl: string,
    hashKey: string,
    downloadManagerUrl: string,
    envType: string
}

const env: Environment = {
    envType: process.env.REACT_APP_ENV_TYPE as string,
    apiUrl: process.env.REACT_APP_API_URL as string,
    cmsUrl: process.env.REACT_APP_CMS_URL as string,
    hashKey: process.env.REACT_APP_HASH_KEY as string,
    downloadManagerUrl: process.env.REACT_APP_DOWNLOAD_MANAGER_URL as string
};

export default env;