import React from 'react';
import { User } from '../../common/types/user.type';
import { history } from '../../router/history';
import { Card, Button } from 'react-bootstrap';
import { State } from '../../common/types/state.type';
import { connect } from 'react-redux';
import './index.scss';
import { removeDeviceRoutine } from '../../redux/actions/devices';
import { getTimeDiffInDays } from '../../utils/time-converter.utils';

export interface IDeviceManagementProps {
    user: User;
    removeDevice: ({deviceUuid: string, userId: number}) => void;
}

const DeviceManagement: React.FC<IDeviceManagementProps> = ({ user, removeDevice }) => {

    const daysToUpdate = !user!.latestDevicesUpdateDate 
        ? 0
        : getTimeDiffInDays(Date.now(), new Date(user.latestDevicesUpdateDate).getTime());
    
    const isDeletingEnabled = daysToUpdate > user!.restriction.devicesUpdateIntervalDays;

    const selectIcon = (type: string) => {
        switch (type) {
            case 'desktop': 
                return (
                    <i className="fas fa-desktop"></i>
                )
            case 'mobile':
                return (
                    <i className="fas fa-mobile-alt"></i>
                )
            case 'tablet':
                return (
                    <i className="fas fa-tablet-alt"></i>
                )
            default: 
                return (
                    <i className="fas fa-desktop"></i>
                )
                
        }
    }

    return  (
        <div className="form-container">
            <span onClick={() => history.push('/')}>
                <i className="fas fa-times"></i>
            </span>
            <div className="devices-header">
                Your devices: 
            </div>
                {
                    !isDeletingEnabled &&
                        <div className="disabled-updating">You can remove device in {user!.restriction.devicesUpdateIntervalDays - daysToUpdate} days</div>
                }
                <div className="devices-list">
                    {
                        user!.devices.map(d => {
                            return (
                                <Card key={d.id} >
                                    <Card.Title>{d.type.toUpperCase()}</Card.Title>
                                    <Card.Text>
                                        <div className="icon-container">
                                            {
                                                selectIcon(d.type)
                                            }
                                        </div>
                                        <div className="info-container">
                                            <div>OS: {d.os}</div>
                                            <Button
                                                disabled={!isDeletingEnabled}
                                                variant="danger" 
                                                onClick={() => removeDevice({ deviceUuid: d.uuid, userId: user.id }) }>
                                                Remove
                                            </Button>
                                        </div>
                                    </Card.Text>
                                </Card>
                            )
                        })
                    }
                </div>
            </div>
    )

}

const mapStateToProps = (state: State) => {
    return {
        user: state.user as User
    }
}

const mapDispatchToProps = {
    removeDevice: removeDeviceRoutine
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceManagement);

