import { setJumpToLive } from './../../actions/jumpToLive/actions';
import { getEventsTableRoutine, getScheduleDueToJumpToLive, getEventsForInfiniteScrollRoutine, getEventsForTopInfiniteScrollRoutine, refreshEventsRoutine } from './../../actions/events/actions';
import { call, put, all, takeLatest } from "redux-saga/effects";
import SportEvent from '../../../schedule/models/sport-event';
import { getEvents as getEventsCall } from '../../../services/api.service';
import { ScheduleFilters, defaultScheduleFilters } from '../../../schedule/models/schedule-filters';

function* getEvents(action: any) {
    const {filters} = action.payload;
    try {
        let events: SportEvent[] = yield call(getEventsCall, filters, new Date().getTimezoneOffset());

        yield put(getEventsTableRoutine.success({ events, date: filters.searchDate }));
    } catch (err) {
        yield put(getEventsTableRoutine.failure(err.message));
    }
}

function* getEventsForInfiniteScroll(action: any) {
    const filters: ScheduleFilters = { ...defaultScheduleFilters, searchDate: action.payload.selectedDate };
    try { 
        let events: SportEvent[] = yield call(getEventsCall, filters,  new Date().getTimezoneOffset());

        yield put(getEventsForInfiniteScrollRoutine.success({ events, date: filters.searchDate }));
    } catch (err) {
        yield put(getEventsForInfiniteScrollRoutine.failure(err.message));
    }
}

function* getEventsForTopInfiniteScroll(action: any) {
    const filters: ScheduleFilters = { ...defaultScheduleFilters, searchDate: action.payload.selectedDate };
    try {
        let events: SportEvent[] = yield call(getEventsCall, filters,  new Date().getTimezoneOffset());

        yield put(getEventsForTopInfiniteScrollRoutine.success({ events, date: filters.searchDate }));
    } catch (err) {
        yield put(getEventsForTopInfiniteScrollRoutine.failure(err.message));
    }
}

function* refreshTable(action: any) {
    const filters: ScheduleFilters = { ...defaultScheduleFilters, searchDate: action.payload.date };
    try {
        let events: SportEvent[] = yield call(getEventsCall, filters,  new Date().getTimezoneOffset());

        yield put(refreshEventsRoutine.success({ events, date: filters.searchDate }));
    } catch (err) {
        yield put(refreshEventsRoutine.failure(err.message));
    }
}


function* getEventsAndSetJumpToLive(action: any) {
    const filters: ScheduleFilters = { ...defaultScheduleFilters, searchDate: action.payload.date, searchWord: '' };
    try {
        let events = yield call(getEventsCall, filters, new Date().getTimezoneOffset());

        yield put(getEventsTableRoutine.success({ events, date: filters.searchDate }));
        yield put(setJumpToLive.success())
    } catch (err) {
        yield put(getEventsTableRoutine.failure(err.message))
    }
}

export default function* eventsSaga() {
    yield all([
        takeLatest(getEventsTableRoutine, getEvents),
        takeLatest(getScheduleDueToJumpToLive, getEventsAndSetJumpToLive),
        takeLatest(getEventsForInfiniteScrollRoutine, getEventsForInfiniteScroll),
        takeLatest(getEventsForTopInfiniteScrollRoutine, getEventsForTopInfiniteScroll),
        takeLatest(refreshEventsRoutine, refreshTable)
    ]);
}
