import React from 'react';
import { history } from '../../router/history';
import { User } from '../../common/types/user.type';
import { connect } from 'react-redux';
import './styles.scss';
import { switchNotificationsReceive } from '../../redux/actions/change-password/index';
import { State } from '../../common/types/state.type';

export interface IProfileInformationProps {
    user: User;
    switchNotificationsReceive: (item: { field: string, id: number, restriction: boolean }) => void;
}

const ProfileInformation: React.FC<IProfileInformationProps> = ({ user, switchNotificationsReceive }) => {

    return user && (
        <div className="form-container">
            <span onClick={() => history.push('/')}>
                <i className="fas fa-times"></i>
            </span>
            <div className="form-container-internal">
                <i className="fas fa-user"></i>
                <div className='options-container'>
                    <p><b>Email:</b> {user.email}</p>
                    <p><b>Username:</b> {user.username}</p>
                    <p><b>Role:</b> {user.role}</p>
                    <div className='notifications-receive'>
                        <p><b>Receive notifications:</b></p>
                        <label className="switch">
                            <input
                                type="checkbox"
                                defaultChecked={user.isNotificationsEnabled}
                                onChange={() => {
                                    user.isNotificationsEnabled = !user.isNotificationsEnabled;
                                    switchNotificationsReceive(
                                        {
                                            field: 'isNotificationsEnabled',
                                            restriction: user.isNotificationsEnabled,
                                            id: user.id || 0
                                        });
                                }} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>

            </div>
        </div >
    )
}

const mapStateToProps = (state: State) => {
    return {
        user: state.user as User
    }
}

const mapDispatchToProps = {
    switchNotificationsReceive
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInformation);