import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { State } from '../common/types/state.type';
import { connect } from 'react-redux';
import { getCurrentUser } from '../redux/actions/auth/actions';
import { Spinner } from 'react-bootstrap';
import { history } from './history';

const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
    const renderPrivateRoute = () => {
        return (
            <Route {...rest} render={(props) => {
                const currentUser = rest.user;
                const token = localStorage.getItem('token');
                if (!currentUser && token) {
                    return renderSpinner();
                }
                else if (!currentUser && !token) {
                    if  (history.location.pathname !== '/login') {
                        let initPath = history.location.pathname;
                        return history.push(`/login?redirectUrl=${initPath}`);
                    }
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                }

                if (roles && roles.indexOf(currentUser.role) === -1) {
                    return <Redirect to={{ pathname: '/' }} />
                }

                return <Component {...props} />
            }} />
        );
    }

    return renderPrivateRoute()
}

export default connect(mapStateToProps)(PrivateRoute);

export function mapStateToProps(state: State, ownProps: any) {
    return {
        user: state.user
    };
}

export const renderSpinner = () => {
    const style = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#171b21' }
    return <div style={style}>
        <Spinner style={{ height: '5rem', width: '5rem' }} animation="border" variant="light" />
    </div>
}
