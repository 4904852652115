import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../common/types/state.type";
import {confirmAnnouncementRoutine} from "../../redux/actions/announcements/actions";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";

export interface IAnnouncementsProps {
    toggleAnnouncementsModal: () => void;
    latest: boolean;
}

const Announcements = ({ toggleAnnouncementsModal, latest }) => {
    const announcements = useSelector((state: State) => state.user!.userAnnouncements);
    const [notes, setNotes] = useState(announcements);

    const dispatch = useDispatch();

    useEffect(() => {
        if (latest) {
            const actualNotes = announcements.filter(x => !x.viewed);
            setNotes(actualNotes);
        } else {
            setNotes(announcements);
        }
    }, [announcements]);

    const handleConfirm = (id) => {
        dispatch(confirmAnnouncementRoutine({id}));
    }

    return (
            latest ? (
            <div className="announcements-overlay">
                <div className="announcements">
                    <div className="announcements__logo">
                        <i className="fas fa-info-circle announcements__icon"></i>
                    </div>
                    <h2 className="announcements__header">
                        Latest announcements:
                        <span>{` ${notes.length}`}</span>
                    </h2>
                    <ul className="announcements__list">
                        {
                            notes.map(item => (
                                <li className="announcements__item">
                                    <h2 className="announcements__item-time">{moment(item.releaseFrom).format("DD/MM/YYYY")}</h2>
                                    <h1 className="announcements__item-title">{item.title}</h1>
                                    <p className="announcements__item-body" dangerouslySetInnerHTML={{__html: item.body}}></p>
                                    <Button className="announcements__item-ok-button" onClick={() => handleConfirm(item.id)}>OK</Button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
        :
        (
            <div className="announcements-overlay">
                <OutsideClickHandler onOutsideClick={toggleAnnouncementsModal}>
                    <div className="announcements">
                        <div className="announcements__logo">
                            <i className="fas fa-info-circle announcements__icon"></i>
                        </div>
                        <h2 className="announcements__header">
                            Archived announcements:
                            <span>{` ${notes.length}`}</span>
                        </h2>
                        <ul className="announcements__list">
                            {
                                notes.map(item => (
                                    <li className="announcements__item">
                                        <h2 className="announcements__item-time">{moment(item.releaseFrom).format("DD/MM/YYYY")}</h2>
                                        <h1 className="announcements__item-title">{item.title}</h1>
                                        <p className="announcements__item-body" dangerouslySetInnerHTML={{__html: item.body}}></p>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </OutsideClickHandler>
            </div>
        )
    )
}

export default Announcements;