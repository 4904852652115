import {call, put, all, takeLatest} from "redux-saga/effects";
import {
    getScreensListRoutine, sendInternalStreamToScreenRoutine,
    sendStreamToScreenRoutine, updateScreenNameRoutine,
} from "../../actions/screens";
import {
    getScreensListRequest,
    sendStreamToScreenRequest,
    updateScreenNameRequest
} from "../../../services/screens.service";
import {toastr} from "react-redux-toastr";
import {sendInternalStreamToScreenRequest} from "../../../services/internalStreams.service";

function* getScreensList() {
    try {
        const result = yield call(getScreensListRequest);
        yield put(getScreensListRoutine.success(result));
    } catch(error) {
        yield put(getScreensListRoutine.failure(error.message));
    }
}

function* sendStreamToScreen({ payload }) {
    try {
        const result = yield call(sendStreamToScreenRequest, payload.skyBoxId, payload.screenIds, payload.locked);
        if (result && result.status !== 200) {
            toastr.error('Error!', 'Problems occurred and sending failed!');
            yield put(sendStreamToScreenRoutine.failure());
        }
    } catch(error) {
        yield put(sendStreamToScreenRoutine.failure(error.message));
    }
}

function* updateScreenName({ payload }) {
    try {
        const result = yield call(updateScreenNameRequest, payload.screenId, payload.name);
        if (result && result.status === 200) {
            yield put(updateScreenNameRoutine.success(payload));
        }
    } catch(error) {
        yield put(updateScreenNameRoutine.failure(error.message));
    }
}

function* sendInternalStreamToScreen({ payload }) {
    try {
        const result = yield call(sendInternalStreamToScreenRequest, payload);
        if (result && result.status !== 200) {
            toastr.error('Error!', 'Problems occurred and sending failed!');
            yield put(sendInternalStreamToScreenRoutine.failure());
        }
    } catch(error) {
        yield put(sendInternalStreamToScreenRoutine.failure(error.message));
    }
}

export default function* screenSaga() {
    yield all([
        takeLatest(getScreensListRoutine, getScreensList),
        takeLatest(sendStreamToScreenRoutine, sendStreamToScreen),
        takeLatest(updateScreenNameRoutine, updateScreenName),
        takeLatest(sendInternalStreamToScreenRoutine, sendInternalStreamToScreen),
    ]);
}