import React, {Fragment, useEffect, useRef, useState} from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { User } from '../../../../../common/types/user.type';
import SportEvent from '../../../../models/sport-event';
import './index.scss';
import {ISkyBoxState} from "../../../../../common/types/sky-boxes";
import PaywallPopup from "../paywall-popup/paywall-popup";

export interface PlayersDropdownProps {
    playersList: number[];
    item: SportEvent;
    user: User | null;
    isPlayerSelectable: (playerNumber: number) => boolean;
    selectStream: (link: string, playerNumber: number, isRealtime: boolean, isRtmp: boolean, event: SportEvent) => void;
    isPlaySkyBoxDropDown?: boolean;
    skyBox?: ISkyBoxState;
    startUsingSkyBox?: () => void;
    streamSettings: any[];
}

const PlayersDropdown: React.FC<PlayersDropdownProps> = ({
    playersList,
    item,
    isPlayerSelectable,
    user,
    selectStream,
    isPlaySkyBoxDropDown,
    skyBox,
    startUsingSkyBox,
    streamSettings,
}) => {

    let popupTimeoutRef: any = useRef(null);
    const [paywallPopupVisibility, setPaywallPopupVisibility] = useState("hidden");
    const [paywallPopupPosition, setPaywallPopupPosition] = useState(0);

    useEffect(() => {
        return () => {
            clearTimeout(popupTimeoutRef.current);
        };
    }, []);


    const getHdLiveIcon = (playerNumber) => {
        if (streamSettings && streamSettings!.length !== 0
            && streamSettings[playerNumber - 1]
            && streamSettings[playerNumber - 1].source !== ""
            && streamSettings[playerNumber - 1].isHd
            && streamSettings[playerNumber - 1].isRealtime) {
            return './inplay-icons/hd_sel.svg';
        } else {
            return './inplay-icons/hd.svg';
        }
    }

    const getSdLiveIcon = (playerNumber) => {
        if (streamSettings
            && streamSettings!.length !== 0
            && streamSettings[playerNumber - 1]
            && streamSettings[playerNumber - 1].source !== ""
            && !streamSettings[playerNumber - 1].isHd
            && streamSettings[playerNumber - 1].isRealtime) {
            return './inplay-icons/sd_sel.svg';
        } else {
            return './inplay-icons/sd.svg';
        }
    }

    const getHdReviewIcon = (playerNumber) => {
        if (streamSettings
            && streamSettings!.length !== 0
            && streamSettings[playerNumber - 1]
            && streamSettings[playerNumber - 1].source !== ""
            && streamSettings[playerNumber - 1].isHd
            && !streamSettings[playerNumber - 1].isRealtime) {
            return './inplay-icons/hd_rev_sel.svg';
        } else {
            return './inplay-icons/hd_rev.svg';
        }
    }

    const getSdReviewIcon = (playerNumber) => {
        if (streamSettings
            && streamSettings!.length !== 0
            && streamSettings[playerNumber - 1]
            && streamSettings[playerNumber - 1].source !== ""
            && !streamSettings[playerNumber - 1].isHd
            && !streamSettings[playerNumber - 1].isRealtime) {
            return './inplay-icons/sd_rev_sel.svg';
        } else {
            return './inplay-icons/sd_rev.svg';
        }
    }

    const handlePaywallHover = (e, enable) => {
        if (!enable) {
            return;
        } else {
            setPaywallPopupPosition(e.clientY);
            setPaywallPopupVisibility("visible");
            popupTimeoutRef.current = setTimeout(() => {
                setPaywallPopupVisibility("hidden");
                clearTimeout(popupTimeoutRef.current);
            }, 5000)
        }
    }

    const renderPaywallPopup = () => {
        return <PaywallPopup
            paywallPopupVisibility={paywallPopupVisibility}
            popupPositionClass={`right ${paywallPopupPosition < 330 ? "mirrored" : ""}`}
            popupRef={null}
        />
    }

    const setWidth = () => {
        if (!user!.restriction.isHdPlayerEnabled
            && !user!.restriction.isSdPlayerEnabled
            && !user!.restriction.isHdPlayerPaywallEnabled
            && !user!.restriction.isSdPlayerPaywallEnabled
            || !user!.restriction.isHdHlsEnabled
            && !user!.restriction.isSdHlsEnabled
            && !user!.restriction.isHdHlsPaywallEnabled
            && !user!.restriction.isSdHlsPaywallEnabled) {
            return "short";
        } else {
            return "";
        }
    }

    return (
        <Dropdown>
            {
                !isPlaySkyBoxDropDown ?
                <Dropdown.Toggle id="dropdown" className='play-btn'>
                    <span className='dot' style={{ backgroundColor: '#FF3466' }}></span>
                    <span className='live-vod-btn'>LIVE</span>
                </Dropdown.Toggle> :
                <Dropdown.Toggle id="dropdown" className='box-dropdown-btn'>
                    <div className={skyBox!.userName ? "box-play-btn-active box-play-btn" : "box-play-btn"} onClick={startUsingSkyBox}>
                        <div className="box-play-btn__icon"/>
                    </div>
                </Dropdown.Toggle>
            }
            {
                !item.isRestricted &&
                <Dropdown.Menu id={isPlaySkyBoxDropDown ? "skyBoxDropdown" : "scheduleDropdown"}>
                <Fragment>
                    <Dropdown.Item style={{borderBottom: "1px solid #717171"}}>
                        <div className='player-item'>
                            <div className='place'/>
                            <div className={`caption-container ${setWidth()}`}>
                                {(user!.restriction.isHdPlayerEnabled
                                    || user!.restriction.isSdPlayerEnabled
                                    || user!.restriction.isHdPlayerPaywallEnabled
                                    || user!.restriction.isSdPlayerPaywallEnabled) && (
                                    <span className='icon-caption'>Live</span>
                                )}
                                {(user!.restriction.isHdHlsEnabled
                                    || user!.restriction.isSdHlsEnabled
                                    || user!.restriction.isHdHlsPaywallEnabled
                                    || user!.restriction.isSdHlsPaywallEnabled) && (
                                    <span className='icon-caption'>{isPlaySkyBoxDropDown ? "HD Review" : "Review"}</span>
                                )}
                            </div>
                        </div>
                    </Dropdown.Item>
                </Fragment>
                {
                    playersList.map(i => {
                        return <Fragment key={i}>
                            <Dropdown.Item as='div' disabled={isPlayerSelectable(i)}>
                                <div className='player-item'>
                                    <div className='place' style={{opacity: isPlayerSelectable(i) ? 0.4 : 1}}>
                                        Screen {i}
                                    </div>
                                    <div className={`players ${setWidth()}`}>
                                        <div className="quality-section player-section">
                                            {
                                                (user!.restriction.isHdPlayerEnabled
                                                || user!.restriction.isHdPlayerPaywallEnabled)
                                                && (
                                                <div className='btn-icon-container'
                                                     style={{opacity: isPlayerSelectable(i) ? 0.4 : 1}}
                                                     onMouseOver={(e) => handlePaywallHover(e, user!.restriction.isHdPlayerPaywallEnabled)}
                                                >
                                                    <Button
                                                        disabled={user!.restriction.isHdPlayerPaywallEnabled}
                                                        title="HD Live"
                                                        className={user!.restriction.isHdPlayerPaywallEnabled ? 'dropdown-btn paywall' : 'dropdown-btn'}
                                                        onClick={() => selectStream(item.webRTCSource, i, true, true, item)}
                                                    >
                                                        <img alt={'HD Live'}
                                                             src={getHdLiveIcon(i)}/>
                                                    </Button>
                                                </div>
                                            )
                                            }
                                            {
                                                (user!.restriction.isSdPlayerEnabled
                                                || user!.restriction.isSdPlayerPaywallEnabled)
                                                && !isPlaySkyBoxDropDown
                                                && (
                                                <div className='btn-icon-container'
                                                     style={{opacity: isPlayerSelectable(i) ? 0.4 : 1}}
                                                     onMouseOver={(e) => handlePaywallHover(e, user!.restriction.isSdPlayerPaywallEnabled)}
                                                >
                                                    <Button
                                                        disabled={user!.restriction.isSdPlayerPaywallEnabled}
                                                        title='SD Live'
                                                        className={user!.restriction.isSdPlayerPaywallEnabled ? 'dropdown-btn paywall' : 'dropdown-btn'}
                                                        onClick={() => selectStream(item.webRTCSource, i, true, false, item)}
                                                    >
                                                        <img alt={'SD Live'}
                                                             src={getSdLiveIcon(i)}/>
                                                    </Button>
                                                </div>
                                            )
                                            }
                                        </div>
                                        <div className="review-section player-section">
                                            {
                                                (user!.restriction.isHdHlsEnabled
                                                || user!.restriction.isHdHlsPaywallEnabled)
                                                && (
                                                <div className='btn-icon-container'
                                                     style={{opacity: isPlayerSelectable(i) ? 0.4 : 1}}
                                                     onMouseOver={(e) => handlePaywallHover(e, user!.restriction.isHdHlsPaywallEnabled)}
                                                >
                                                    <Button
                                                        disabled={user!.restriction.isHdHlsPaywallEnabled}
                                                        title='HD Review'
                                                        className={user!.restriction.isHdHlsPaywallEnabled ? 'dropdown-btn paywall' : 'dropdown-btn'}
                                                        onClick={() => selectStream(item.streamLink, i, false, true, item)}
                                                    >
                                                        <img alt={'HD Review'}
                                                             src={getHdReviewIcon(i)}/>
                                                    </Button>
                                                </div>
                                            )
                                            }
                                            {
                                                (user!.restriction.isSdHlsEnabled
                                                || user!.restriction.isSdHlsPaywallEnabled)
                                                && !isPlaySkyBoxDropDown && (
                                                <div className='btn-icon-container'
                                                     style={{opacity: isPlayerSelectable(i) ? 0.4 : 1}}
                                                     onMouseOver={(e) => handlePaywallHover(e, user!.restriction.isSdHlsPaywallEnabled)}
                                                >
                                                    <Button
                                                        disabled={user!.restriction.isSdHlsPaywallEnabled}
                                                        title='SD Review'
                                                        className={user!.restriction.isSdHlsPaywallEnabled ? 'dropdown-btn paywall' : 'dropdown-btn'}
                                                        onClick={() => selectStream(item.streamLink, i, false, false, item)}
                                                    >
                                                        <img alt={'SD Review'}
                                                             src={getSdReviewIcon(i)}/>
                                                    </Button>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Dropdown.Item>
                        </Fragment>
                    })
                }
                {
                    renderPaywallPopup()
                }
            </Dropdown.Menu>}
        </Dropdown>
    )
}

export default PlayersDropdown;
