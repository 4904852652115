import { getHlsConfigRoutine } from './../../actions/hls/index';
import { call, put, all, takeLatest } from "redux-saga/effects";
import { getHlsConfig } from '../../../services/api.service';

function* getHlsOptions(action: any) {
    try {
        const options = yield call(getHlsConfig, action.payload);

        yield put(getHlsConfigRoutine.success(options));
    } catch (err) {
        yield put(getHlsConfigRoutine.failure(err.message));
    }
}

export default function* hlsSaga() {
    yield all([
        takeLatest(getHlsConfigRoutine, getHlsOptions)
    ]);
}